import MetaInfo from "../Meta/Meta-Info";
import ObesityImg from "../../Images/Obesity/banner1.jpg";
import { useContext, useEffect } from "react";
import { ContextProvider } from "../../App";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import metabolicReversalImg from "../../Images/Obesity/metaboloic-reversal.jpg";
import AIHelpsImg from "../../Images/Obesity/AI helps-3.png";
import NutritionImg from "../../Images/Obesity/nutrition.jpg";
import ExcerciseImg from "../../Images/Obesity/workout.jpg";
import RealtimeImg from "../../Images/Obesity/real-time (2).jpg";
import { EnquiryAndAssociatedSection } from "../utils/constant";
import Banner from "../utils/Banner";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const Obesity = () => {
  const { shimmerEffect } = useContext(ContextProvider);
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);
  const { t } = useTranslation();

  const {
    obTitle,
    obDesc,
    metabTitle,
    metabDesc,
    aiTitle,
    aiDesc,
    personalTitle,
    personalDesc,
    realtimeTitle,
    realtimeDesc,
    intelligentTitle,
    intelligentDesc,
    joinTitle,
    joinDesc,
    obessignupMsg,
  } = t("obesity");
  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section className="bg-mediumBlue min-h-screen flex flex-col gap-5 text-white">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"AI-Powered Obesity Management | ND Health Programs"}
        pageDescription={
          "Join our AI-powered obesity management program. Improve patient health with innovative, personalized care solutions. Learn more today!"
        }
        pageKeywords={
          "Obesity Management Program,artificial intelligence in obesity management,obesity digital health, ai weight loss program"
        }
        ogUrl={"https://ndhealth.ai/programs/obesity-management-program"}
        ogTitle={"AI-Powered Obesity Management | ND Health Programs"}
        ogDescription={
          "Join our AI-powered obesity management program. Improve patient health with innovative, personalized care solutions. Learn more today!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/banner1.7e0c6431e1640e2b1327.jpg"
        }
        canonicalUrl={"https://ndhealth.ai/programs/obesity-management-program"}
        TwTitle={"AI-Powered Obesity Management | ND Health Programs"}
        TwDesc={
          "Join our AI-powered obesity management program. Improve patient health with innovative, personalized care solutions. Learn more today!"
        }
        TwImg={
          "https://ndhealth.ai/static/media/banner1.7e0c6431e1640e2b1327.jpg"
        }
      />

      {/****************   Banner Section    *********************/}

      <Banner title={obTitle} description={obDesc} ImageUrl={ObesityImg} />

      {/****************   Metabolic Reversal    *********************/}

      <div className="flex lg:flex-row flex-col justify-around items-center gap-8 md:w-3/4 relative mx-auto ">
        <img
          data-aos="fade-right"
          src={metabolicReversalImg}
          alt="metabolicReversalImg"
          className="rounded-2xl lg:w-1/2 w-3/4"
        />
        <div data-aos="fade-left" className="text-white px-2">
          <h2 className="lg:text-3xl sm:text-xl font-semibold">{metabTitle}</h2>
          <p className="lg:text-base sm:text-sm text-xs text-justify">
            {metabDesc}
          </p>
        </div>
      </div>

      {/*********************     How AI Can Help     ************************/}

      <div
        data-aos="fade-up"
        className="bg-white text-black sm:m-5 m-2 p-2 sm:p-5 rounded-lg flex flex-col gap-10"
      >
        <div className="flex xs:flex-row flex-col xs:gap-0 gap-3 justify-around items-center">
          <div className="flex gap-5 flex-col justify-around xs:w-1/2 w-full">
            <h3 className="lg:text-3xl sm:text-xl font-semibold text-mediumBlue text-center xs:text-start">
              {aiTitle}
            </h3>
            <p className="lg:text-base sm:text-sm text-xs text-justify">
              {aiDesc}
            </p>
          </div>
          <div className=" bg-mediumBlue rounded-t-full lg:w-72 w-52">
            <img src={AIHelpsImg} alt="AIHelpsImg" className="w-full" />
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="grid lg:grid-cols-2 grid-cols-1 justify-center gap-5">
            {/*******  Personalized Nutrition ******************/}

            <div
              data-aos="fade-right"
              className="flex flex-col xs:flex-row border border-lightGray p-2 rounded gap-1"
            >
              <div className="lg:w-1/2 sm:w-80 w-full">
                <h3 className="bg-lightBlue py-2 rounded-r-full text-center font-semibold">
                  {personalTitle}
                </h3>
                <p className="lg:text-base sm:text-sm text-xs p-3 text-justify">
                  {personalDesc}
                </p>
              </div>
              <img
                src={NutritionImg}
                alt="NutritionImg"
                className="w-60 h-64 object-cover rounded-lg relative mx-auto"
              />
            </div>

            {/*******  Real-Time Monitoring and Support ******************/}

            <div
              data-aos="fade-left"
              className="flex flex-col xs:flex-row border border-lightGray p-2 rounded gap-1"
            >
              <div className="lg:w-1/2 sm:w-80 w-full">
                <h3 className="bg-lightBlue py-2 rounded-r-full text-center font-semibold">
                  {realtimeTitle}
                </h3>
                <p className="lg:text-base sm:text-sm text-xs p-3 text-justify">
                  {realtimeDesc}
                </p>
              </div>
              <img
                src={RealtimeImg}
                alt="RealtimeImg"
                className="w-60 h-64 object-cover rounded-lg relative mx-auto"
              />
            </div>
          </div>
          {/*******  Intelligent Exercise Guidance ******************/}

          <div
            data-aos="fade-up"
            className="flex flex-col xs:flex-row justify-around border border-lightGray p-2 rounded gap-1"
          >
            <div className="lg:w-1/2 sm:w-80 w-full">
              <h3 className="bg-lightBlue py-2 rounded-r-full text-center font-semibold">
                {intelligentTitle}
              </h3>
              <p className="lg:text-base sm:text-sm text-xs p-3 text-justify">
                {intelligentDesc}
              </p>
            </div>
            <img
              src={ExcerciseImg}
              alt="ExcerciseImg"
              className="lg:w-96 xxs:w-60 xxs:h-66 mx-auto object-cover rounded-lg relative"
            />
          </div>
        </div>

        {/****************   Join Our Program Today    *************************/}

        <div data-aos="fade-up" className="flex flex-col gap-5 md:px-10 px-5">
          <h3 className="lg:text-3xl sm:text-xl font-semibold text-mediumBlue text-center">
            {joinTitle}
          </h3>
          <p className="lg:text-base sm:text-sm text-xs text-justify">
            {joinDesc}
          </p>
          <p className="text-mediumBlue font-semibold text-center">
            {obessignupMsg}
          </p>

          {/********    EnquiryAndAssociatedSection    ********/}

          <EnquiryAndAssociatedSection />
        </div>
      </div>
    </section>
  );
};

export default Obesity;
