import MetaInfo from "../Meta/Meta-Info";
import { useContext } from "react";
import { ContextProvider } from "../../App";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import StallHeader from "./StallHeader";
import StallHeroSection from "./StallHeroSection";
import StallNdHealth from "./StallNdHealth";
import StallServices from "./StallServices";
import StallAboutUs from "./StallAboutUs";

const StallPage = () => {
  const { shimmerEffect } = useContext(ContextProvider);

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section>
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"Innovative AI Healthcare Solutions for Better Patient Care"}
        pageDescription={
          "Explore cutting-edge AI healthcare solutions to improve patient care and efficiency. Join us in revolutionizing healthcare today"
        }
        pageKeywords={
          "AI healthcare innovation, Healthcare automation,  Machine learning in healthcare, Healthcare data analytics with AI"
        }
        canonicalUrl={"https://ndhealth.ai/stallpage"}
      />
      <div className="flex flex-col gap-5 z-10 bg-gray-100">
        <StallHeader />
        <StallHeroSection />
        <StallNdHealth />
        <StallServices />
        <StallAboutUs />
      </div>
    </section>
  );
};

export default StallPage;
