import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TfiWorld } from "react-icons/tfi";

const Languages = [
  {
    code: "en",
    lang: "English",
  },
  {
    code: "hi",
    lang: "हिंदी",
  },
  {
    code: "ar",
    lang: "عربي",
  },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  useEffect(() => {
    // window.location.reload();
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
    document.body.dir = i18n.dir();
  }, [selectedLanguage, i18n]);

  const changeLanguage = (e) => {
    window.location.reload();
    const selectedLanguage = e.target.value;
    setSelectedLanguage(selectedLanguage);
  };
  return (
    <div className="flex items-center lg:gap-2 gap-1 rounded-lg">
      {/* <div className="text-white lg:block hidden">
        <TfiWorld size={22} />
      </div>{" "} */}
      <select
        onChange={changeLanguage}
        value={selectedLanguage}
        className="border-none focus:outline-none bg-transparent rounded-lg w-full sm:p-1"
      >
        {Languages.map((langs) => {
          const { code, lang } = langs;
          return (
            <option key={code} value={code}>
              {lang}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default LanguageSelector;
