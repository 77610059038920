import React from "react";
import { Link } from "react-router-dom";
import MetaInfo from "../Meta/Meta-Info";

const Terms = () => {
  return (
    <section className="border-[1rem] text-mediumGray sm:border-[3rem] border-lightBlue p-4 sm:p-8 flex flex-col gap-10 md:text-base text-sm">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"ND Health AI Terms and Conditions for Service Use"}
        pageDescription={
          "Review ND Health AI's terms and conditions for service use. Understand your rights and responsibilities as a user of our platform."
        }
        canonicalUrl={"https://ndhealth.ai/terms-and-conditions"}
        pageKeywords={"ND Health AI terms and conditions"}
      />

      <h1 className="md:text-5xl text-2xl text-black font-medium text-center sm:py-5 font-poppins">
        Terms & Conditions
      </h1>
      <div className="flex flex-col gap-4">
        <p>
          These terms and conditions (“ND Health Terms”) shall be applicable to
          all diabetes or any other metabolic related medical and diagnostic
          services and/or other ancillary services and/or any other services
          provided on the ND Health.ai website, and mobile application
          (collectively referred to as “Platform”), operated by M/s SS Legal and
          Management Services , Hyderabad and/or facilitated through its partner
          company/s (“ND Health” or “Us” or “Our” or “We”) and and availed by
          users (“You” or “Your” or “User”) of the Platform.
        </p>
        <p>
          This document is an electronic record in terms of the Information
          Technology Act, 2000 and rules thereunder as applicable and the
          provisions pertaining to electronic records in various statutes as
          amended by the Information Technology Act, 2000. This electronic
          record is generated by a computer system and does not require any
          physical or digital signatures. Please read the ND Health Terms and ND
          Health Privacy Policy (collectively referred to as “Agreement”)
          carefully before availing Medical Services or accessing or using the
          Platform. Your use of the Platform and the Medical Services shall
          signify Your irrevocable acceptance of the Agreement.
        </p>
      </div>

      {/*********** Information on the Platform ***********************/}

      <div className="flex flex-col gap-5">
        <h3 className="md:text-2xl text-xl text-black">
          Information on the Platform
        </h3>
        <p>
          Please note that any and all information, material, or details
          available on the Platform (except such information sought from a
          Registered Medical Practitioner) is not a substitute for medical care
          or advice and none of this information should be used for the purpose
          of self-diagnosis or treatment. Please seek and rely on medical advice
          sought from a Registered Medical Practitioner.
        </p>
        <p>
          The details of the registered medical practitioners of ND Health
          and/or third-party registered medical practitioners (collectively
          “Registered Medical Practitioners”), available on the Platform, are
          not a recommendation or endorsement by ND Health regarding the
          qualifications or the services provided by such practitioners, and
          appointments booked by You to avail the Medical Services have been
          made by You voluntarily, at Your option.
        </p>
      </div>

      {/*********** Relationship between You and ND Health ***********************/}

      <div>
        <h3 className="md:text-2xl text-xl text-black pb-5">
          Relationship between You and ND Health
        </h3>
        <p>
          No doctor-patient relationship is created between You and ND Health or
          You and any Registered Medical Practitioner engaged or appointed by
          Us, merely by booking an appointment or purchasing a diabetes related
          health package with ND Health as part of the Medical Services or any
          other services. Such relationship is established only when information
          pertaining to Your health and other medical records (“Medical
          Records”) are disclosed by You to the Registered Medical Practitioners
          engaged or appointed by ND Health, during a consultation session.
        </p>
      </div>

      {/**********************   Consent    ***********************/}

      <div>
        <h3 className="md:text-2xl text-xl text-black pb-5">Consent</h3>
        <p>
          You hereby provide Your consent to ND Health to share (where required)
          the Medical Records and/or obtain/store Medical Records which include
          the details recorded by the Registered Medical Practitioners in the
          course of the consultations and reports generated by ND Health, its
          Registered Medical Practitioners engaged by Us, and upload such
          medical and sensitive information on the Platform. Additionally, You
          acknowledge that nurses, paramedical staff, diagnostic service
          providers, other persons employed or engaged by ND Health for
          providing You services may also have access to the Medical Records and
          accordingly provide Your consent to share the Medical Records with
          such persons. You hereby provide Your consent to share/notify Your
          Medical Records (as monitored by ND Health) with You through SMS/other
          messaging services including but not limited to, WhatsApp messaging
          services. All Medical Records shall be stored, used, and disclosed
          only in the manner stipulated under the ND Health Privacy Policy. You
          can choose to withdraw Your consent in the manner provided under the
          ND Health Privacy Policy.
        </p>
      </div>

      {/***************   Secondary users    ************************/}

      <div>
        <h3 className="md:text-2xl text-xl text-black pb-5">Secondary users</h3>
        <p>
          The Platform allows You to create a sub-account for family members
          (“Secondary User”) in case of persons who do not have the capacity to
          contract under law, in Your capacity as the parent or legal guardian
          also. The information and details regarding the Secondary User
          including their personal and sensitive information (such as name, age,
          date of birth, contact number, email address) and appointments on
          their behalf for Medical Services offered by ND Health can be
          maintained by You on the account provided that You have been duly
          authorized by such Secondary User in this regard. For avoidance of
          doubt, You hereby unequivocally and unconditionally confirm that (i)
          the personal information added by You on the account in relation to
          each Secondary User has been added pursuant to the consent obtained by
          You from such Secondary User, or in case of persons who do not have
          the capacity to contract under law, in Your capacity as the parent or
          legal guardian of such Secondary User, and (ii) the Secondary User has
          consented to You receiving the reports in relation to such Secondary
          User, and to ND Health storing, possessing, sharing, using, and
          accessing such information, reports, and Medical Records as per the
          terms hereof.
        </p>
      </div>

      {/***************     Services       ************************/}

      <div>
        <h3 className="md:text-2xl text-xl pb-5 text-black">Services</h3>

        <div className="flex flex-col gap-4">
          <p>The following Services are offered by ND Health to You:</p>
          <p>
            Medical Services: The term "Medical Services" shall mean and include
            the services offered by ND Health to You which include:
          </p>
          <p>
            Health Packages: Diabetes package and Pre-diabetes subscription
            pack. These health packages may include devices such as CGM Sensor
            and/or Glucometer (collectively “Devices”) for checking diabetes,
            glucose levels, consultations from the Registered Medical
            Practitioner, consultations from coaches for using the Devices and
            monitoring the readings, and/or other related diagnostic tests. As
            part of onboarding on our diabetes and pre-diabetes health packages,
            We will require certain mandatory tests to be undertaken. We shall
            facilitate such tests to be undertaken through our third party
            partners / clinics.
          </p>
          <p>
            You will be required to install the CGM Sensor on your patch as may
            be instructed by the Registered Medical Practitioners or the coach
            (as the case may be) for monitoring the readings required for
            consultation by the Registered Medical Practitioners. These readings
            will be directly sent to the manufacturer of the CGM Sensor /
            collected through ND Health’s authorized third party partner, and
            then shared with ND Health for providing effective consultation to
            You. You may also be required to take readings from the Glucometer
            as may be instructed by the Registered Medical Practitioner and
            record the same on the Platform to help Medical Practitioner provide
            better advisory
          </p>
        </div>
      </div>

      {/***************     Other-Services       ************************/}

      <div className="flex flex-col gap-5">
        <h3 className="md:text-2xl text-xl text-black">Other Services:</h3>
        <p>
          E Store Products offered for Sale are neither an endorsement of the
          quality or the manufacturer Due care and diligence is advised in the
          use of the product and the evaluation of its suitability to the
          potential buyers.
        </p>
      </div>

      {/* Medical Services and Other Services on the Platform may also have the following components (as applicable): */}

      <div className="flex flex-col gap-5">
        <h3 className="md:text-2xl text-xl text-black">
          Medical Services and Other Services on the Platform may also have the
          following components (as applicable):
        </h3>
        <p>
          Appointments: ND Health shall allow You to book an appointment with
          the Registered Medical Practitioners or coaches (as the case may be)
          engaged with ND Health, either directly or indirectly, through the
          Platform. You may also cancel or reschedule Your appointments as per
          Clause 6 below. Appointments booked on the Platform for physical
          consultations or tele-consultation with ND Health’s Registered Medical
          Practitioners are subject to the availability of such respective
          medical practitioners. The details of the Registered Medical
          Practitioners available on the Platform are not a guarantee of their
          services. We reserve the right to cancel or reschedule any appointment
          at any time.
        </p>
        <p>
          We shall use best efforts to intimate You in advance of such
          rescheduling or cancellation. However, We shall not be responsible in
          any manner for any inconvenience or loss caused to You as a result of
          such rescheduling or cancellation.
        </p>
        <p>
          Tele-consultation: All tele-consultation services are provided only by
          Registered Medical Practitioners based on the Medical Records provided
          by You, and any errors in the Medical Records or lack of information
          may lead to inaccurate or incomplete medical advice. The Medical
          Records shared by You with such practitioners shall be subject to the
          ND Health Privacy Policy or the policies of the respective third-party
          clinics or hospitals or third-party registered medical practitioners
          engaged by ND Health. Please note that ND Health and/or the Registered
          Medical Practitioners do not record, save or download a copy of the
          consultation. However, ND Health and the Registered Medical
          Practitioners, employees, agents, and consultants shall not be
          responsible for any loss, cost, expenses, inconvenience, or suits
          caused, suffered, or instituted against You as a result of such
          tele-consultation services, in any manner whatsoever. It is
          recommended that medical advice be sought through physical
          consultation with the concerned medical practitioner. If You choose to
          proceed to take medical consultation through tele-consultation, then
          You do so at Your own risk.
        </p>
        <p>
          You may use the tele-consultation services even if You do not reside
          in India; however, it is recommended that the medical advice provided
          to You by the Registered Medical Practitioners engaged by Us, be
          validated by practitioners in the country of Your residence. Please
          note that ND Health is not available to You if You reside outside
          India. There may also be instances when tele-consultations should be
          followed-up with physical consultations and/or diagnostic tests.
          Accordingly, You are advised to consider these factors while opting
          for the tele-consultation.
        </p>
        <p>
          Diagnostic services: ND Health shall allow You to avail diagnostic
          services by booking appointments on the Platform and shall on a best
          effort basis ensure that the reports in respect of such diagnostic
          services are provided to you within the time period specified at the
          time of booking the appointment. The reports shall be made available
          to You on the Platform. Please note that We will not handover the
          report to any third person, including family members, without Your
          consent. However, we may disclose information or provide reports to
          family members if required under applicable laws. These diagnostic
          services are facilitated by third-party partners through the Platform.
          You hereby provide Your consent to Us to share Your personal data to
          the extent required for providing such diagnostic services.
        </p>
        <p>
          Record management and health monitoring: We shall provide You with the
          necessary infrastructure including but not limited to Devices (which
          may or may not be provided) and access to Registered Medical
          Practitioners for managing your diabetes related lifecycle or for
          monitoring your glucose levels and general metabolic health (as the
          case may be) on the Platform.
        </p>
        <p></p>
      </div>

      {/********** Device offerings through third-party partners  *************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Device offerings through third-party partners
        </h3>
        <p>
          Please note that appointments for physical consultations and
          tele-consultations may not be advisable in cases of emergency or
          critical conditions since We cannot guarantee appointments. We shall
          not be responsible for any inconvenience or loss caused to You as a
          result of delay in medical evaluation or treatment.
        </p>
        <p>
          Please further note that devices that may be provided as part of
          Medical Services are provided by third-party sellers licensed to sell
          said devices and your usage of the said devices is governed by its own
          set of terms and conditions. Please read the terms and conditions for
          such devices prior to usage. Your usage of the same may be construed
          as your consent to such terms and conditions.
        </p>
      </div>

      {/************* Cancellation/rescheduling of appointments  *****************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Cancellation/rescheduling of appointments
        </h3>
        <p>
          You may cancel the appointments for the Medical Services prior to the
          appointment. The cancellation shall be governed as per the timelines
          mentioned on the Platform. You shall not be entitled to any refunds if
          appointments are cancelled after the period as specified in the
          timeline provided on the Platform.
        </p>
        <p>
          You may reschedule the appointments for the Medical Services prior to
          the appointment, subject to availability of a time slot/registered
          medical practitioner for appointment. Timelines mentioned on the
          Platform shall govern such rescheduling of appointments.
        </p>
      </div>

      {/******************* Fees/Refunds/Payment Services ********************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black pb-5">
          Fees/Refunds/Payment Servicess
        </h3>
        <ul className="flex flex-col gap-4">
          <li>
            i. All payments in respect of the Medical Services and/or other
            Services shall be collected in advance on the Platform. Additional
            taxes or third-party charges may apply. In case of any cancellation
            either by You (subject to the terms specified above) or ND Health or
            third-party clinics/hospitals/partners, the amounts paid in respect
            of such Services shall be refunded within five (7) business days
            from the date of such request of refund. Timelines for refund of the
            designated amount to the source account shall be governed as per the
            relevant bank’s policy.
          </li>
          <li>
            ii. All payments in respect of the Medical Services and/or other
            Services shall be made through the Platform. To the extent permitted
            by applicable law and subject to the ND Health’s Privacy Policy, you
            acknowledge and agree that ND Health may use certain third-party
            vendors and service providers, including payment gateways, to
            process payments and manage payment card information.
          </li>
          <li>
            iii. In order to make payments online, You undertake to use valid
            payment card or bank details or third party wallets or details
            required for any other mode of payment (“Payment Details”) with the
            authority to use the same, to have sufficient funds or credit
            available to complete the payment on the Platform in good standing.
            By providing the Payment Details, You represent, warrant, and
            covenant that: (1) You are legally authorized to provide such
            Payment Details; (2) You are legally authorized to perform payments
            using such Payment Details; and (3) such action does not violate the
            terms and conditions applicable to Your use of such Payment Details
            or applicable law. You agree that You are responsible for any fees
            charged by Your mobile carrier in connection with Your use of the
            payment services through Your mobile. ND Health shall use the
            Payment Details as described in the ND Health’s Privacy Policy. You
            may add, delete, and edit the Payment Details You have provided from
            time to time through the Platform
          </li>
          <li>
            iv. There shall be two (2) invoices generated through the Platform,
            one from ND Health for consultation provided by the Registered
            Medical Practitioner or coaches and the other from third-party
            partners who shall be invoicing for the Devices as part of the
            packages under different Services being offered on the Platform. The
            payment receipt and transaction summary shall be made available on
            the Platform and also sent to Your registered email address.
            Platform shall collect the payment on behalf of the respective
            third-party partner, and eventually settle the same.
          </li>
          <li>
            v. Except to the extent otherwise required by applicable law, ND
            Health is not liable for any payments authorized through the
            Platform using Your Payment Details. Particularly, ND Health is not
            liable for any payments that do not complete because: (1) Your
            payment card or bank account or third party wallet does not contain
            sufficient funds to complete the transaction or the transaction
            exceeds the credit limit in respect of the Payment Details provided;
            (2) You have not provided ND Health with correct Payment Details;
            (3) Your payment card has expired; or (4) circumstances beyond ND
            Health’s control (such as, but not limited to, power outages,
            interruptions of cellular service, or any other interferences from
            an outside force) prevent the execution of the transaction.
          </li>
          <li>
            Subject to compliance with the ND Health Terms, you are granted a
            non-exclusive, revocable, limited privilege to access and use this
            Platform and its Services. You agree to use the Services, Platform,
            and the materials provided therein only for permitted purposes and
            in accordance with applicable laws and guidelines. You agree not to
            engage in activities that may adversely affect the use of the
            Platform by ND Health or other Users, and to access the Platform
            only through the interface provided by ND Health. Any material
            added, created, uploaded, or posted to the Platform is your sole
            responsibility, and ND Health reserves the right to review and
            delete any inconsistent content. You further agree not to engage in
            a list of prohibited activities outlined in the ND Health Terms,
            including defamatory behavior, unauthorized access, and violation of
            laws or regulations. Intellectual Property Rights
          </li>
          <li>
            The trademarks, logos and service marks displayed on the Platform
            (“Marks”) are the property of ND Health and / or other respective
            third parties, as the case may be. You are not permitted to use the
            Marks without the prior consent of ND Health, or the third party
            that may own the Marks.
          </li>
          <li>
            The Platform and other Content provided by ND Health are offered on
            an "as is" basis without any warranties, whether express, implied,
            statutory, or otherwise, including warranties of title,
            non-infringement, merchantability, or fitness for a particular
            purpose. ND Health does not guarantee that the Platform or its
            Services will meet your requirements, be uninterrupted, timely,
            secure, or error-free, or that the results obtained from using the
            Platform will be effective, accurate, or reliable. No advice or
            information obtained from ND Health or through the Platform shall
            create any warranty not expressly stated in the ND Health Terms. ND
            Health will not be liable for any loss incurred due to unauthorized
            use of your account or account information. While ND Health
            endeavors to ensure the accuracy of information on the Platform, it
            does not warrant the quality, accuracy, or completeness of any data
            or information. ND Health shall not be responsible for delays or
            inability to use the Platform, provision or failure to provide
            functionalities, or any damages arising from the use of the
            Platform, whether based on contract, tort, negligence, or otherwise.
            You acknowledge that downloading material or data through the
            Platform is done at your own risk, and ND Health shall not be held
            responsible for any damage to your computer systems or loss of data
            resulting from such downloads
          </li>
        </ul>
      </div>

      {/************ Indemnification & Limitation of liability ***********/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Indemnification & Limitation of liability
        </h3>
        <p>
          ND Health shall provide all Services and shall ensure that all
          Services are provided, professionally and in compliance with
          applicable law. If despite such care and due diligence the reports
          generated by ND Health or any partner of ND Health or any part thereof
          is found to be incorrect or incomplete, the total liability of ND
          Health shall be limited to carrying out a retest of the sample
          provided or carrying out another test using such method which can
          provide reasonably error free results, free of cost. The selection of
          the alternative test shall be made by ND Health at its sole
          discretion. All medico legal liability attributable to Registered
          Medical Practitioner engaged by Us shall be the sole responsibility of
          such Registered Medical Practitioner. You hereby acknowledge that
          under no circumstances shall ND Health or its employees, consultants,
          agents or officers be liable for any direct, indirect, special,
          punitive, or consequential damages that result in any way from Your
          use of or inability to use the Services or to access the Medical
          Records or Your use of any ancillary Services provided on the
          Platform. You further agree and confirm that ND Health shall not be
          responsible, in any manner whatsoever, for any delay in performance or
          failure to meet its obligations under this ND Health Terms which may
          be:
        </p>
        <p>caused, directly or indirectly, due to:</p>
        <ul className="list-decimal flex flex-col gap-3 pl-4">
          <li>Your failure to cooperate;</li>
          <li>Your unavailability and/or unresponsiveness;</li>
          <li>Unavailability of Registered Medical Practitioners;</li>
          <li>Cancellation of appointments;</li>
          <li>
            Your failure to provide accurate and complete Medical Records; or
          </li>
          <li>
            Your failure to provide or facilitate the submission of, Medical
            Records or samples in a proper and timely manner.
          </li>
        </ul>
        <p>
          any event beyond ND Health's reasonable control including, but not
          limited to, due to any force majeure event
        </p>
      </div>

      {/************* Violation of the ND Health Terms *************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Violation of the ND Health Terms
        </h3>
        <p>
          You agree that any violation by You of these ND Health Terms will
          constitute an unlawful and unfair business practice, and will cause
          irreparable harm to Us and/or Our third party partners, as the case
          may be, for which monetary damages would be inadequate, and You
          consent to ND Health obtaining any injunctive or equitable relief that
          they deem necessary or appropriate in such circumstances. These
          remedies are in addition to any other remedies that the ND Health
          and/or its third party partners may have at law or in equity. If ND
          Health and/or third party partner takes any legal action against You
          as a result of Your violation of these ND Health Terms, they will be
          entitled to recover from You, and You agree to pay, all reasonable
          attorneys’ fees and costs of such action, in addition to any other
          relief that may be granted.
        </p>
      </div>

      {/***************   Suspension and Termination    *********************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Suspension and Termination
        </h3>
        <p>
          The ND Health Terms will continue to apply until terminated by either
          You or ND Health as set forth below:
        </p>
        <p>
          If You object to the ND Health Terms or are dissatisfied with the
          Platform, your only recourse is to <br />
          <br /> (i) close Your Account on the Platform; and/or
          <br /> (ii) stop accessing the Platform.
        </p>
        <p>
          ND Health may delist You or block Your future access to the Platform
          or suspend or terminate your Account if it believes, in its sole and
          absolute discretion that you have infringed, breached, violated,
          abused, or unethically manipulated or exploited any term of these ND
          Health Terms or anyway otherwise acted unethically. Notwithstanding
          anything in this clause, these ND Health Terms will survive
          indefinitely unless and until ND Health chooses to terminate them.
        </p>
        <p>
          If You or ND Health terminate Your use of the Platform, ND Health may
          delete any content or other materials relating to Your use of the
          Platform without any liability to You or any third party for doing so.
          However, your transactions details may be preserved by ND Health for
          purposes of tax or regulatory compliance.
        </p>
        <p>
          You shall be liable to pay for any Services that you have already
          ordered till the time of termination by either party whatsoever
        </p>
      </div>

      {/*****************    Report Abuse and Grievance Redressal     *************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Report Abuse and Grievance Redressal{" "}
        </h3>
        <p>
          In the event You come across any abuse or violation of these ND Health
          Terms or if You become aware of any objectionable content on the
          Platform or if You wish to report a grievance regarding Platform,
          please report the same to the following e-mail id:{" "}
          <Link to={"mailto:enquiries@ndhealth.ai"}>
            <span className="text-black hover:underline">
              enquiries@ndhealth.ai
            </span>
          </Link>
        </p>
      </div>

      {/*****************    Communications     *************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">Communications </h3>
        <p>
          You hereby expressly agree to receive communications by way of
          SMS/WhatsApp and/or e-mails from ND Health, and other third parties
          relating to the Platform and Services provided through the Platform.
          You can unsubscribe/ opt-out from receiving communications through
          SMS/WhatsApp and/or e-mail anytime by e-mailing to:{" "}
          <Link to={"mailto:enquiries@ndhealth.ai"}>
            <span className="text-black hover:underline">
              enquiries@ndhealth.ai
            </span>
          </Link>
        </p>
      </div>

      {/*****************    General Provisions      *************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">General Provisions</h3>
        <p>
          Notice: All notices from ND Health will be served by email to Your
          registered email address or by general notification on the Platform.
          Any notice provided to ND Health pursuant to the ND Health Terms
          should be sent to hello@ND Health.com with subject line
        </p>
      </div>

      {/***************   - Attention: TERMS OF USE.     ****************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Attention: TERMS OF USE.
        </h3>
        <p>
          <span className="text-black">Assignment:</span> You cannot assign or
          otherwise transfer the ND Health Terms, or any rights granted
          hereunder to any third party. ND Health’s rights under the ND Health
          Terms are freely transferable by ND Health to any third party without
          the requirement of seeking Your consent.
        </p>
        <p>
          <span className="text-black">Severability:</span> If, for any reason,
          a court of competent jurisdiction finds any provision of the ND Health
          Terms, or any portion thereof, to be unenforceable, that provision
          shall be enforced to the maximum extent permissible so as to give
          effect to the intent of the parties as reflected by that provision,
          and the remainder of the ND Health Terms shall continue in full force
          and effect.
        </p>
        <p>
          <span className="text-black">Waiver:</span> Any failure by ND Health
          to enforce or exercise any provision of the ND Health Terms, or any
          related right, shall not constitute a waiver by ND Health of that
          provision or right.
        </p>
      </div>

      {/***************   Governing Law and Jurisdiction    **************/}

      <div className="flex flex-col gap-4">
        <h3 className="md:text-2xl text-xl text-black">
          Governing Law and Jurisdiction
        </h3>
        <p>
          These ND Health Terms shall be governed by the laws of India. In the
          event of any dispute between the Parties in relation to these Terms,
          the courts in Bangalore shall have exclusive jurisdiction.
        </p>
      </div>
    </section>
  );
};

export default Terms;
