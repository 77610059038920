import PcodIMG from "../../Images/PCOD/banner2.1.jpg";
import overviewImg from "../../Images/PCOD/Overview.jpg";
import problemImg from "../../Images/PCOD/problem.jpg";
import symptomsImg from "../../Images/PCOD/Symptoms.jpg";
import DiagnosisImg from "../../Images/PCOD/diagnosis.jpg";
import fetilityPNG from "../../Images/PCOD/Keyfacts/1.png";
import worldPNG from "../../Images/PCOD/Keyfacts/2.png";
import syndromePNG from "../../Images/PCOD/Keyfacts/3.png";
import healthPNG from "../../Images/PCOD/Keyfacts/4.png";
import PCOSPNG from "../../Images/PCOD/Keyfacts/5.png";
import { EnquiryAndAssociatedSection, scrollToTop } from "../utils/constant";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { useContext, useEffect } from "react";
import { ContextProvider } from "../../App";
import MetaInfo from "../Meta/Meta-Info";
import Banner from "../utils/Banner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const PCOD = () => {
  const { shimmerEffect, loader } = useContext(ContextProvider);

  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { knownBtn } = t("common");
  const { pcodTitle, pcodDesc, Comprehensive, KeyFacts } = t("Pcod");

  const {
    compHeader,
    compSubHeader,
    pcodSectionTitle1,
    pcodSectionDesc1,
    pcodSectionTitle2,
    pcodSectionDesc2,
    pcodSectionTitle3,
    pcodSectionDesc3,
    pcodSectionTitle4,
    pcodSectionDesc4,
  } = Comprehensive;

  const {
    pcodSection3Main,
    pcodSection3Sub1,
    pcodSection3Sub2,
    pcodSection3Sub3,
    pcodSection3Sub4,
    pcodSection3Sub5,
  } = pcodSectionDesc3;

  const {
    pcodSection4Main,
    pcodSection4Sub1,
    pcodSection4Sub2,
    pcodSection4Sub3,
  } = pcodSectionDesc4;

  const {
    keyFactsTitle,
    keyFactsPara1,
    keyFactsPara2,
    keyFactsPara3,
    keyFactsPara4,
    keyFactsPara5,
  } = KeyFacts;

  return loader ? (
    <Shimmer />
  ) : (
    <section>
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"AI-Enhanced PCOD Management | ND Health Solutions"}
        pageDescription={
          "Discover our AI-enhanced PCOD management program. Improve patient outcomes with advanced, personalized care solutions. Learn more today!"
        }
        pageKeywords={
          "PCOD Management Program,PCOS treatment plan,PCOS treatment options"
        }
        ogTitle={"AI-Enhanced PCOD Management | ND Health Solutions"}
        ogUrl={"https://ndhealth.ai/programs/pcod-management-program"}
        ogDescription={
          "Discover our AI-enhanced PCOD management program. Improve patient outcomes with advanced, personalized care solutions. Learn more today!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/banner2.1.744fe4310529f6350f77.jpg"
        }
        canonicalUrl={"https://ndhealth.ai/programs/pcod-management-program"}
        TwTitle={"AI-Enhanced PCOD Management | ND Health Solutions"}
        TwDesc={
          "Discover our AI-enhanced PCOD management program. Improve patient outcomes with advanced, personalized care solutions. Learn more today!"
        }
        TwImg={
          "https://ndhealth.ai/static/media/banner2.1.744fe4310529f6350f77.jpg"
        }
      />
      <div className="flex flex-col gap-10 min-h-screen">
        {/**************       Hero section      *************/}

        <Banner title={pcodTitle} description={pcodDesc} ImageUrl={PcodIMG} />

        <div className="flex flex-col gap-5 bg-mediumBlue text-white">
          <div className="text-center">
            <h2 className="md:text-3xl sm:text-2xl text-base font-semibold pt-5">
              {compHeader}
            </h2>
            <p className="md:text-xl xs:text-base text-sm md:py-8 py-4 ">
              {compSubHeader}
            </p>
          </div>

          <div className="flex flex-col gap-5">
            {/************ Overview ********************/}
            <div
              data-aos="fade-right"
              className="flex flex-col md:flex-row justify-center lg:gap-16 md:gap-5"
            >
              <img
                src={overviewImg}
                className="lg:w-[28rem] md:w-96 md:h-[30rem] w-full h-96 object-cover relative z-10 rounded"
                alt="overviewImg"
              />
              <div className="relative z-0 md:w-96 md:p-0 p-4 text-center md:text-start">
                <h2 className="md:text-2xl text-lg font-semibold ">
                  {pcodSectionTitle1}
                </h2>
                <p className="sm:text-base text-sm text-justify py-5">
                  {pcodSectionDesc1}
                </p>
                <Link to={"/contactus"}>
                  <button
                    onClick={scrollToTop}
                    className="styleButton px-6 py-2"
                  >
                    {knownBtn}
                  </button>
                </Link>
              </div>
            </div>

            {/************ Scope of the problem ********************/}

            <div
              data-aos="fade-left"
              className="flex md:flex-row-reverse flex-col justify-center lg:gap-16 md:gap-5 "
            >
              <img
                src={problemImg}
                className="lg:w-[28rem] md:w-96 md:h-[35rem] w-full h-[30rem] object-cover relative z-10 rounded md:bottom-14 lg:left-10 md:left-5"
                alt="problemImg"
              />
              <div className="relative xxs:p-2 z-0 md:w-96 md:p-0 p-4 text-center md:text-start">
                <h2 className="md:text-2xl text-lg font-semibold md:pt-10">
                  {pcodSectionTitle2}
                </h2>
                <p className=" sm:text-base text-sm text-justify py-5">
                  {pcodSectionDesc2}
                </p>
                <Link to={"/contactus"}>
                  <button
                    onClick={scrollToTop}
                    className="styleButton px-6 py-2"
                  >
                    {knownBtn}
                  </button>
                </Link>
              </div>
            </div>

            {/*************** Symptoms ***********************/}

            <div
              data-aos="fade-right"
              className="flex flex-col md:flex-row justify-center lg:gap-16 md:gap-5 relative"
            >
              <img
                src={symptomsImg}
                className="lg:w-[28rem] md:w-96 md:h-[30rem] w-full h-96 object-cover relative z-10 rounded"
                alt="symptomsImg"
              />
              <div className="flex flex-col gap-5 items-center xxs:p-2 z-0 md:w-96 md:p-0 p-4 md:items-start">
                <h2 className="md:text-2xl text-lg font-semibold">
                  {pcodSectionTitle3}
                </h2>
                <div className="sm:text-base text-sm  text-justify">
                  <p className=" ">{pcodSection3Main}</p>
                  <ul>
                    <li>{pcodSection3Sub1}</li>
                    <li>{pcodSection3Sub2}</li>
                    <li>{pcodSection3Sub3}</li>
                    <li>{pcodSection3Sub4}</li>
                    <li>{pcodSection3Sub5}</li>
                  </ul>
                </div>
                <Link to={"/contactus"}>
                  <button
                    onClick={scrollToTop}
                    className="styleButton px-6 py-2"
                  >
                    {knownBtn}
                  </button>
                </Link>
              </div>
            </div>

            {/*************** Diagnosis ***********************/}

            <div
              data-aos="fade-left"
              className="flex md:flex-row-reverse flex-col justify-center lg:gap-16 md:gap-5 "
            >
              <img
                src={DiagnosisImg}
                className="lg:w-[28rem] md:w-96 md:h-[35rem] w-full h-[30rem] object-cover relative z-10 rounded md:bottom-14 lg:left-10 md:left-5"
                alt="DiagnosisImg"
              />
              <div className="flex flex-col items-center gap-5 xxs:p-2 z-0 md:w-96 md:p-0 p-4 md:items-start">
                <h2 className="md:text-2xl text-lg font-semibold md:pt-10">
                  {pcodSectionTitle4}
                </h2>
                <div className="sm:text-base text-sm  text-justify">
                  <p className="">{pcodSection4Main}</p>
                  <ul>
                    <li>{pcodSection4Sub1}</li>
                    <li>{pcodSection4Sub2}</li>
                    <li> {pcodSection4Sub3}</li>
                  </ul>
                </div>
                <Link to={"/contactus"}>
                  <button
                    onClick={scrollToTop}
                    className="styleButton px-6 py-2"
                  >
                    {knownBtn}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/****************** Key Facts ******************/}

        <div className="text-center ">
          <h2 className="md:text-4xl sm:text-2xl text-xl font-semibold bg-mediumBlue inline-block p-3 rounded text-white">
            {keyFactsTitle}
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 py-8 gap-y-5 text-justify">
            <div data-aos="zoom-in" className="flex flex-col items-center">
              <img src={fetilityPNG} className="w-28" alt="fetilityPNG" />
              <p className="xs:w-72 px-4 xs:p-0 sm:text-base text-sm">
                {keyFactsPara1}
              </p>
            </div>

            <div data-aos="zoom-in" className="flex flex-col items-center">
              <img src={worldPNG} className="w-28" alt="worldPNG" />
              <p className="xs:w-72 px-4 xs:p-0 sm:text-base text-sm">
                {keyFactsPara2}
              </p>
            </div>

            <div data-aos="zoom-in" className="flex flex-col items-center">
              <img src={syndromePNG} className="w-28" alt="syndromePNG" />
              <p className="xs:w-72 px-4 xs:p-0 sm:text-base text-sm">
                {keyFactsPara3}
              </p>
            </div>

            <div data-aos="zoom-in" className="flex flex-col items-center">
              <img src={healthPNG} className="w-28" alt="healthPNG" />
              <p className="xs:w-72 xxs:px-4 p-0">{keyFactsPara4}</p>
            </div>

            <div data-aos="zoom-in" className="flex flex-col items-center">
              <img src={PCOSPNG} className="w-28" alt="PCOSPNG" />
              <p className="xs:w-72 px-4 xs:p-0 sm:text-base text-sm">
                {keyFactsPara5}
              </p>
            </div>
          </div>
        </div>

        {/********    EnquiryAndAssociatedSection    ********/}

        <EnquiryAndAssociatedSection />
      </div>
    </section>
  );
};

export default PCOD;
