import MetaInfo from "../Meta/Meta-Info";
import AntiAgeImg from "../../Images/Anti-Aging/a.jpg";
import AgeReversalImg from "../../Images/Anti-Aging/age reversal.jpg";
import { EnquiryAndAssociatedSection } from "../utils/constant";
import Banner from "../utils/Banner";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { ContextProvider } from "../../App";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import Aos from "aos";
import "aos/dist/aos.css";

const AntiAging = () => {
  const { loader } = useContext(ContextProvider);
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { t } = useTranslation();

  const {
    antiTitle,
    antiDesc,
    antiMetabTitle,
    antiMetabDesc,
    approach,
    appBenefits,
    appNdHealth,
    appEndingPara,
  } = t("antiAging");

  const { appraochMainTitle, OurApproach } = approach;
  const { appBenefitsMainTitle, BenefitsOfAgeReversal } = appBenefits;
  const { appNdHealthMainTittle, ChooseNdHealth } = appNdHealth;

  return loader ? (
    <Shimmer />
  ) : (
    <section className="min-h-screen bg-lightWhite ">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"AI-Driven Anti-Aging Program | ND Health Solutions"}
        pageDescription={
          "Explore our AI-driven anti-aging program. Enhance patient care with advanced, personalized solutions. Learn more and rejuvenate today!"
        }
        pageKeywords={"Anti Aging Program,Ai anti aging program,"}
        ogUrl={"https://ndhealth.ai/programs/anti-aging-program"}
        ogTitle={"AI-Driven Anti-Aging Program | ND Health Solutions"}
        ogDescription={
          "Explore our AI-driven anti-aging program. Enhance patient care with advanced, personalized solutions. Learn more and rejuvenate today!"
        }
        ogImage={"https://ndhealth.ai/static/media/a.4e4bb8640f2084d6791d.jpg"}
        canonicalUrl={"https://ndhealth.ai/programs/anti-aging-program"}
        TwTitle={"AI-Driven Anti-Aging Program | ND Health Solutions"}
        TwDesc={
          "Explore our AI-driven anti-aging program. Enhance patient care with advanced, personalized solutions. Learn more and rejuvenate today!"
        }
        TwImg={"https://ndhealth.ai/static/media/a.4e4bb8640f2084d6791d.jpg"}
      />

      <div className="flex flex-col gap-10">
        {/******    Hero Section    **********/}

        <Banner
          title={antiTitle}
          description={antiDesc}
          ImageUrl={AntiAgeImg}
        />

        {/*************       What is Metabolic Age Reversal?       **************/}

        <div className="flex xs:flex-row flex-col gap-5 xs:gap-0 justify-around items-center py-2">
          {" "}
          <img
            data-aos="fade-right"
            src={AgeReversalImg}
            alt="AgeReversalImg"
            className="lg:w-[22rem] xs:w-60 h-80 sm:h-fit object-cover rounded"
          />
          <div
            data-aos="fade-left"
            className="xs:w-1/2 px-4 xs:px-0 flex flex-col xs:items-start items-center"
          >
            <h2 className="font-medium lg:text-4xl sm:text-2xl xs:text-lg text-xl text-center">
              {antiMetabTitle}
            </h2>
            <p className="xs:py-10 sm:py-5 py-4 sm:text-base text-sm">
              {antiMetabDesc}
            </p>
          </div>
        </div>

        {/************      Our Approach        ******************/}
        <div className="bg-mediumBlue py-10 text-white">
          <h2 className="font-semibold sm:text-4xl xs:text-lg text-xl text-center mb-5">
            {appraochMainTitle}
          </h2>
          <div className="grid xl:grid-cols-4 md:grid-cols-3 xs:grid-cols-2 grid-cols-1 gap-y-6 place-items-center py-5">
            {OurApproach &&
              OurApproach.map((approach) => {
                const { id, title, description, imageUrl } = approach;
                return (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    key={id}
                    className="relative lg:w-72 lg:h-72 xs:w-56 xs:h-56 w-full h-64 group overflow-hidden"
                  >
                    <img
                      src={imageUrl}
                      alt={imageUrl}
                      className="rounded-lg transition-transform duration-700 transform hover:scale-110 h-full w-full object-cover"
                    />
                    <div className="absolute w-full bottom-0 bg-black bg-opacity-50 opacity-0 transition duration-700 group-hover:opacity-100 text-center p-3 rounded-b-lg">
                      <h3 className="font-semibold pb-3">{title}</h3>
                      <p className="text-sm ">{description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/************      Benefits of Metabolic Age Reversal   ******************/}

        <div>
          <h2 className="font-medium sm:text-4xl xs:text-lg text-xl text-center mb-5">
            {appBenefitsMainTitle}
          </h2>
          <div className="grid xl:grid-cols-4 md:grid-cols-3 xs:grid-cols-2 grid-cols-1 gap-y-6 py-5 ">
            {BenefitsOfAgeReversal &&
              BenefitsOfAgeReversal.map((benefit) => {
                const { id, title, description, imageUrl } = benefit;
                return (
                  <div
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                    key={id}
                    className="flex flex-col gap-5 items-center justify-center sm:p-5 p-3 lg:w-72 xs:w-60 w-full  border border-lightGray hover:shadow-xl transform duration-300 rounded-xl mx-auto"
                  >
                    <img
                      src={imageUrl}
                      alt={imageUrl}
                      className="lg:w-28 lg:h-28 w-20 transform duration-500 hover:scale-110"
                    />
                    <div>
                      <h2 className="font-semibold lg:text-base text-sm pb-4 text-center">
                        {title}
                      </h2>
                      <p className="lg:text-base text-sm">{description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/***********       Why Choose ND Health?   *****************/}

        <div>
          <h2 className="font-semibold sm:text-4xl xs:text-lg text-xl text-center mb-5">
            {appNdHealthMainTittle}
          </h2>

          <div className="grid xl:grid-cols-4 md:grid-cols-3 xs:grid-cols-2 grid-cols-1 gap-y-6 place-items-center py-5">
            {ChooseNdHealth &&
              ChooseNdHealth.map((health) => {
                const { id, title, description, imageUrl } = health;
                return (
                  <div
                    data-aos="fade-left"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                    key={id}
                    className="relative lg:w-72 lg:h-72 xs:w-56 xs:h-56 w-full h-64 group overflow-hidden text-white"
                  >
                    <img
                      src={imageUrl}
                      alt={imageUrl}
                      className="rounded-lg transition-transform duration-700 h-full w-full object-cover"
                    />
                    <div className="absolute flex flex-col hover:justify-center w-full bottom-0 bg-black bg-opacity-50 group-hover:h-full text-center p-3 rounded-b-lg hover:rounded-t-lg">
                      <h3 className="font-semibold pb-3">{title}</h3>
                      <p className="text-sm ">{description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div>
          <p className="py-5 text-center lg:text-xl xs:text-base text-sm font-semibold">
            {appEndingPara}
          </p>
          {/********    EnquiryAndAssociatedSection    ********/}

          <EnquiryAndAssociatedSection />
        </div>
      </div>
    </section>
  );
};

export default AntiAging; //163
