import React, { useContext, useEffect } from "react";
import MetaInfo from "../Meta/Meta-Info";
import { ContextProvider } from "../../App";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import PsoriasisImg from "../../Images/Psoriasis/Psoriasis.jpg";
import AIPsoriasisImg from "../../Images/Psoriasis/AI.jpg";
import BeyondImg from "../../Images/Psoriasis/beyond.png";
import ProactiveImg from "../../Images/Psoriasis/proactive.1.png";
import DataDrivenImg from "../../Images/Psoriasis/Data-driven.jpg";
import UnderstandImg from "../../Images/Psoriasis/understand.png";
import SmileFaceImg from "../../Images/Psoriasis/Smile.png";
import { EnquiryAndAssociatedSection } from "../utils/constant";
import Banner from "../utils/Banner";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const Psoriasis = () => {
  const { shimmerEffect } = useContext(ContextProvider);
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { t } = useTranslation();

  const {
    psoTitle,
    psoDesc,
    psoIntroTitle,
    psoIntroDesc,
    psoChoose,
    psoEmbrace,
  } = t("psoriasis");

  const { psoIntroSubHead1, psoIntroSubHead2 } = psoIntroTitle;

  const {
    psoChooseTitle,
    psoChooseSubHead1,
    psoChooseSubPara1,
    psoChooseSubHead2,
    psoChooseSubPara2,
    psoChooseSubHead3,
    psoChooseSubPara3,
    psoChooseSubHead4,
    psoChooseSubPara4,
    psoChooseSubHead5,
    psoChooseSubPara5,
  } = psoChoose;

  const { psoChooseTitle1, psoChooseTitle2 } = psoChooseTitle;

  const { psoEmbraceTitle, psoEmbraceDesc } = psoEmbrace;

  const { psoEmbraceTitle1, psoEmbraceTitle2, psoEmbraceTitle3 } =
    psoEmbraceTitle;

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section className="min-h-screen flex flex-col gap-10 bg-mediumBlue text-white">
      <MetaInfo
        pageTitle={"AI-Based Psoriasis Treatment | ND Health Programs"}
        pageDescription="Transform psoriasis care with our AI-powered treatment program. Enhance patient outcomes with cutting-edge solutions. Learn more and enroll today!"
        pageKeywords={"Psoriasis Treatment Program,management of psoriasis "}
        ogUrl={"https://ndhealth.ai/programs/psoriasis-treatment-program"}
        ogTitle={"AI-Based Psoriasis Treatment | ND Health Programs"}
        ogDescription={
          "Transform psoriasis care with our AI-powered treatment program. Enhance patient outcomes with cutting-edge solutions. Learn more and enroll today!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/Psoriasis.d76cfbf972d14e731c6f.jpg"
        }
        canonicalUrl={
          "https://ndhealth.ai/programs/psoriasis-treatment-program"
        }
        TwTitle={"AI-Based Psoriasis Treatment | ND Health Programs"}
        TwDesc={
          "Transform psoriasis care with our AI-powered treatment program. Enhance patient outcomes with cutting-edge solutions. Learn more and enroll today!"
        }
        TwImg={
          "https://ndhealth.ai/static/media/Psoriasis.d76cfbf972d14e731c6f.jpg"
        }
      />

      {/***************     Hero Section     ******************/}

      <Banner title={psoTitle} description={psoDesc} ImageUrl={PsoriasisImg} />

      {/***************     Introducing Twin Technology     ******************/}

      <div data-aos="fade-up" className="flex flex-col items-center">
        <h2 className="font-semibold sm:text-4xl xs:text-2xl text-xl text-center">
          {psoIntroSubHead1}{" "}
          <span className="font-bold text-gradient ">{psoIntroSubHead2} </span>
        </h2>

        <div className="flex sm:flex-row flex-col justify-center items-center sm:px-5 px-0 sm:py-10 sm:gap-10 w-full">
          <img
            src={AIPsoriasisImg}
            alt="AIPsoriasisImg"
            className="md:w-96 sm:w-72 w-full max-h-64 py-5 sm:py-0 object-cover rounded"
          />
          <p className="md:w-[40%] px-[4%] xs:px-0 sm:text-base text-sm">
            {psoIntroDesc}
          </p>
        </div>
      </div>

      {/***********    Why Choose ND Health? **********************/}

      <div className="flex flex-col gap-20">
        <h2 className="font-semibold sm:text-4xl py-5 xs:text-2xl text-xl text-center">
          {psoChooseTitle1}{" "}
          <span className="font-bold text-gradient ">{psoChooseTitle2} </span>
        </h2>

        <div className="flex flex-col gap-10 sm:gap-20">
          {/*******   Beyond Generic Solutions    ********/}

          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-center"
            className="flex sm:flex-row flex-col justify-center gap-5 sm:gap-10 items-center"
          >
            <div className="md:w-[40%] sm:w-72 flex flex-col gap-5 sm:gap-10 mx-5 xs:mx-20 sm:mx-0">
              <div className="flex md:gap-5 gap-2 items-center relative">
                <h3 className="font-medium xl:text-4xl md:text-2xl text-xl bg-lightBlue xl:px-4 xl:py-2 px-3 py-1 text-black rounded-2xl">
                  1
                </h3>
                <h2 className="font-medium xl:text-3xl md:text-2xl text-xl ">
                  {psoChooseSubHead1}
                </h2>
              </div>
              <p className="md:text-xl sm:text-base">{psoChooseSubPara1}</p>
            </div>
            <img
              src={BeyondImg}
              alt="BeyondImg"
              className="sm:w-96 w-full xl:h-[26rem] md:h-80 h-72 object-cover border rounded"
            />
          </div>

          {/******* 	Proactive Management    **********/}

          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-center"
            className="flex flex-col sm:flex-row-reverse justify-center gap-5 sm:gap-10 items-center"
          >
            <div className="md:w-[40%] sm:w-72 flex flex-col gap-5 sm:gap-10 mx-5 xs:mx-20 sm:mx-0">
              <div className="flex md:gap-5 gap-2 items-center relative">
                <h3 className="font-medium xl:text-4xl md:text-2xl text-xl bg-lightBlue xl:px-4 xl:py-2 px-3 py-1 text-black rounded-2xl">
                  2
                </h3>
                <h2 className="font-medium xl:text-3xl md:text-2xl text-xl ">
                  {psoChooseSubHead2}
                </h2>
              </div>
              <p className="md:text-xl sm:text-base">{psoChooseSubPara2}</p>
            </div>
            <img
              src={ProactiveImg}
              alt="ProactiveImg"
              className="sm:w-96 w-full xl:h-[26rem] md:h-80 h-72 object-cover border rounded"
            />
          </div>

          {/******* 	Data-Driven Insights    **********/}

          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-center"
            className="flex sm:flex-row flex-col justify-center gap-5 sm:gap-10 items-center"
          >
            <div className="md:w-[40%] sm:w-72 flex flex-col gap-5 sm:gap-10 mx-5 xs:mx-20 sm:mx-0">
              <div className="flex md:gap-5 gap-2 items-center relative">
                <h3 className="font-medium xl:text-4xl md:text-2xl text-xl bg-lightBlue xl:px-4 xl:py-2 px-3 py-1 text-black rounded-2xl">
                  3
                </h3>
                <h2 className="font-medium xl:text-3xl md:text-2xl text-xl ">
                  {psoChooseSubHead3}
                </h2>
              </div>
              <p className="md:text-xl sm:text-base">{psoChooseSubPara3}</p>
            </div>
            <img
              src={DataDrivenImg}
              alt="DataDrivenImg"
              className="sm:w-96 w-full xl:h-[26rem] md:h-80 h-72 object-cover border rounded"
            />
          </div>

          {/******* 		Empowerment Through Knowledge    **********/}

          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-center"
            className="flex flex-col sm:flex-row-reverse justify-center gap-5 sm:gap-10 items-center"
          >
            <div className="md:w-[40%] sm:w-72 flex flex-col gap-5 sm:gap-10 mx-5 xs:mx-20 sm:mx-0">
              <div className="flex md:gap-5 gap-2 items-center relative">
                <h3 className="font-medium xl:text-4xl md:text-2xl text-xl bg-lightBlue xl:px-4 xl:py-2 px-3 py-1 text-black rounded-2xl">
                  4
                </h3>
                <h2 className="font-medium xl:text-3xl md:text-2xl text-xl ">
                  {psoChooseSubHead4}
                </h2>
              </div>
              <p className="md:text-xl sm:text-base">{psoChooseSubPara4}</p>
            </div>
            <img
              src={UnderstandImg}
              alt="UnderstandImg"
              className="sm:w-96 w-full xl:h-[26rem] md:h-80 h-72 object-cover border rounded"
            />
          </div>

          {/******* 		Live a More Comfortable Life    **********/}

          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-center"
            className="flex sm:flex-row flex-col justify-center gap-5 sm:gap-10 items-center"
          >
            <div className="md:w-[40%] sm:w-72 flex flex-col gap-5 sm:gap-10 mx-5 xs:mx-20 sm:mx-0">
              <div className="flex md:gap-5 gap-2 items-center relative">
                <h3 className="font-medium xl:text-4xl md:text-2xl text-xl bg-lightBlue xl:px-4 xl:py-2 px-3 py-1 text-black rounded-2xl">
                  5
                </h3>
                <h2 className="font-medium xl:text-3xl md:text-2xl text-xl ">
                  {psoChooseSubHead5}
                </h2>
              </div>
              <p className="md:text-xl sm:text-base">{psoChooseSubPara5}</p>
            </div>
            <img
              src={SmileFaceImg}
              alt="SmileFaceImg"
              className="sm:w-96 w-full xl:h-[26rem] md:h-80 h-72 object-cover border rounded"
            />
          </div>
        </div>
      </div>

      {/***********     Embrace the Future of Psoriasis Treatment     *********/}

      <div
        data-aos="fade-up"
        data-aos-anchor-placement="center-center"
        className="flex flex-col items-center justify-center gap-5"
      >
        <h2 className="font-semibold sm:text-4xl py-5 xs:text-2xl text-xl text-center">
          {psoEmbraceTitle1}{" "}
          <span className="font-bold text-gradient "> {psoEmbraceTitle2} </span>
          {psoEmbraceTitle3}
        </h2>
        <p className="xs:w-3/4 xs:px-0 px-[4%] lg:text-xl xs:text-base text-sm">
          {psoEmbraceDesc}
        </p>
        {/********    EnquiryAndAssociatedSection    ********/}

        <EnquiryAndAssociatedSection />
      </div>
    </section>
  );
};

export default Psoriasis;
