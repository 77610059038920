import conceptimg from "../../Images/What we do/concept.jpg";
import realtimeimg from "../../Images/What we do/realtime.jpg";
import efficencyimg from "../../Images/What we do/efficiency.jpg";
import seriousimg from "../../Images/What we do/serious.jpg";
import digitalImg from "../../Images/twin.jpg";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { ContextProvider } from "../../App";
import MetaInfo from "../Meta/Meta-Info";
import { scrollToTop } from "../utils/constant";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const WhatWeDo = () => {
  const { shimmerEffect } = useContext(ContextProvider);

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { t } = useTranslation();

  const { whatweDoTitle, whatweDoPrgs, digitalTwin } = t("WhatweDo");
  const {
    linkDiabetes,
    linkDyslipidemia,
    linkPCOD,
    linkObesity,
    linkPsoriasis,
    linkAntiAging,
    linkNAFLD,
  } = whatweDoPrgs;

  const {
    digitalTwinTitle,
    digitalTwinHeader1,
    digitalTwinPara1,
    digitalTwinHeader2,
    digitalTwinPara2,
    digitalTwinHeader3,
    digitalTwinPara3,
    digitalTwinHeader4,
    digitalTwinPara4,
  } = digitalTwin;

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section className="min-h-screen">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"Transforming Healthcare with AI Real-Time Data | ND Health"}
        pageDescription={
          "ND Health enhances healthcare programs with AI-driven real-time data analytics. Discover AI's transformative impact on healthcare. Learn more today!"
        }
        pageKeywords={
          "What We Do ND Health, Smart healthcare systems, Health data analytics,"
        }
        ogTitle={"Transforming Healthcare with AI Real-Time Data | ND Health"}
        ogUrl={"https://ndhealth.ai/whatwedo"}
        ogDescription={
          "ND Health enhances healthcare programs with AI-driven real-time data analytics. Discover AI's transformative impact on healthcare. Learn more today!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/efficiency.b88f7493029998ff2320.jpg"
        }
        canonicalUrl={"https://ndhealth.ai/whatwedo"}
      />
      <img src={digitalImg} className="w-full object-cover" alt="digitalImg" />
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-10 items-center text-center">
          <h1 className="lg:text-4xl sm:text-xl mt-5">{whatweDoTitle}</h1>
          <ul className="flex flex-wrap items-center justify-center font-medium  ">
            <li className="underline hover:no-underline" onClick={scrollToTop}>
              <Link to="/programs/diabetes-reversal-program">
                {" "}
                {linkDiabetes}
              </Link>
            </li>
            <li className="underline hover:no-underline" onClick={scrollToTop}>
              <Link to="/programs/dyslipidemia-treatment-program">
                {" "}
                | {linkDyslipidemia}{" "}
              </Link>
            </li>
            <li className="underline hover:no-underline" onClick={scrollToTop}>
              <Link to="/programs/pcod-management-program"> | {linkPCOD} </Link>
            </li>
            <li className="underline hover:no-underline" onClick={scrollToTop}>
              <Link to="/programs/obesity-management-program">
                {" "}
                | {linkObesity}{" "}
              </Link>
            </li>
            <li className="underline hover:no-underline" onClick={scrollToTop}>
              <Link to="/programs/psoriasis-treatment-program">
                {" "}
                | {linkPsoriasis}{" "}
              </Link>
            </li>
            <li className="underline hover:no-underline" onClick={scrollToTop}>
              <Link to="/programs/anti-aging-program"> | {linkAntiAging} </Link>
            </li>
            <li className="underline hover:no-underline" onClick={scrollToTop}>
              <Link to="/programs/nafld-program"> | {linkNAFLD} </Link>
            </li>
          </ul>
          <h2 className="lg:text-2xl bg-darkBlue text-white font-semibold xxs:p-5 p-2 rounded">
            {digitalTwinTitle}
          </h2>
        </div>

        {/* New Section */}

        <div className="flex flex-col gap-20 py-10 px-3">
          {/************ A New Era In Healthcare ****************/}

          <div className="flex sm:flex-row flex-col xl:gap-20 md:gap-10 sm:gap-5 mx-auto ">
            <img
              data-aos="fade-right"
              src={conceptimg}
              className="lg:w-[30rem] sm:w-80 sm:h-80 object-cover rounded"
              alt="conceptimg"
            />
            <div data-aos="fade-left" className="flex flex-col">
              <h3 className="lg:text-3xl xs:text-xl font-semibold py-2 lg:pb-10">
                {digitalTwinHeader1}
              </h3>
              <p className="lg:w-[30rem] sm:w-80 text-mediumGray text-sm lg:text-base">
                {digitalTwinPara1}
              </p>
            </div>
          </div>

          {/************ Real Time Data ****************/}

          <div className="flex sm:flex-row-reverse flex-col xl:gap-20 md:gap-10 sm:gap-5 mx-auto">
            <img
              data-aos="fade-right"
              src={realtimeimg}
              className="lg:w-[30rem] sm:w-80 sm:h-80 object-cover rounded"
              alt="realtimeimg"
            />
            <div data-aos="fade-left">
              <h3 className="lg:text-3xl xs:text-xl font-semibold py-2 lg:pb-10">
                {digitalTwinHeader2}
              </h3>
              <p className="lg:w-[30rem] sm:w-80 text-mediumGray text-sm lg:text-base">
                {digitalTwinPara2}
              </p>
            </div>
          </div>

          {/************ Efficency ****************/}

          <div className="flex sm:flex-row flex-col xl:gap-20 md:gap-10 sm:gap-5 mx-auto">
            <img
              data-aos="fade-right"
              src={efficencyimg}
              className="lg:w-[30rem] sm:w-80 sm:h-80 object-cover rounded"
              alt="efficencyimg"
            />
            <div data-aos="fade-left">
              <h3 className="lg:text-3xl xs:text-xl font-semibold py-2 lg:pb-10">
                {digitalTwinHeader3}
              </h3>
              <p className="lg:w-[30rem] sm:w-80 text-mediumGray text-sm lg:text-base">
                {digitalTwinPara3}
              </p>
            </div>
          </div>

          {/************ Serious Medical Situations ****************/}

          <div className="flex sm:flex-row-reverse flex-col xl:gap-20 md:gap-10 sm:gap-5 mx-auto">
            <img
              data-aos="fade-right"
              src={seriousimg}
              className="lg:w-[30rem] sm:w-80 sm:h-80 object-cover rounded"
              alt="seriousimg"
            />
            <div data-aos="fade-left">
              <h3 className="lg:text-3xl xs:text-xl font-semibold py-2 lg:pb-10">
                {digitalTwinHeader4}
              </h3>
              <p className="lg:w-[30rem] sm:w-80 text-mediumGray text-sm lg:text-base">
                {digitalTwinPara4}
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default WhatWeDo;
