import Home from "./Home";

const Body = () => {
  return (
    <section className="flex flex-col gap-14 min-h-screen">
      <Home />
    </section>
  );
};

export default Body;
