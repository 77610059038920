import dyslipidemiaImg from "../../Images/Dyslipedimia/banner.jpg";
import DyslipedimiaBgImg from "../../Images/Dyslipedimia/2149894448.jpg";
import freeDrugIMG from "../../Images/Dyslipedimia/non drugs.jpg";
import { EnquiryAndAssociatedSection } from "../utils/constant";
import { useContext, useEffect } from "react";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { ContextProvider } from "../../App";
import MetaInfo from "../Meta/Meta-Info";
import Banner from "../utils/Banner";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const Dyslipedimia = () => {
  const { shimmerEffect } = useContext(ContextProvider);

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { t } = useTranslation();

  const {
    dysTitle,
    dysDesc,
    dysHeader1,
    dysPara1,
    dysHeader2,
    dysPara2,
    dysHeader3,
    dysPara3,
    dysHeader4,
    dysPara4,
    Causes,
    Treatment,
    non_statinDrugs,
    dysProgram,
  } = t("DyslipedimiaProgram");

  const { causeHeader, causeSubHeader, DyslipedimiaCauses } = Causes;

  const { treatHeader, treatPara1, treatPara2, treatPara3 } = Treatment;

  const { drugHeader, drug1, drug2, drug3 } = non_statinDrugs;

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section className="relative bg-white">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"AI-Based Dyslipidemia Treatment | ND Health Programs"}
        pageDescription={
          "Explore our AI-based dyslipidemia treatment program. Enhance patient care with advanced solutions. Learn more about our innovative approach!"
        }
        pageKeywords={
          "Dyslipidemia Treatment Program, ai dyslipidemia management, ai dyslipidemia program"
        }
        ogUrl={"https://ndhealth.ai/programs/dyslipidemia-treatment-program"}
        ogTitle={"AI-Based Dyslipidemia Treatment | ND Health Programs"}
        ogDescription={
          "Explore our AI-based dyslipidemia treatment program. Enhance patient care with advanced solutions. Learn more about our innovative approach!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/banner.942465559cc8b1a023f1.jpg"
        }
        canonicalUrl={
          "https://ndhealth.ai/programs/dyslipidemia-treatment-program"
        }
        TwTitle={"AI-Based Dyslipidemia Treatment | ND Health Programs"}
        TwDesc={
          "Explore our AI-based dyslipidemia treatment program. Enhance patient care with advanced solutions. Learn more about our innovative approach!"
        }
        TwImg={
          "https://ndhealth.ai/static/media/banner.942465559cc8b1a023f1.jpg"
        }
      />
      <div className="relative flex flex-col gap-10 min-h-screen ">
        {/**************        Hero Section         ***************/}

        <Banner
          title={dysTitle}
          description={dysDesc}
          ImageUrl={dyslipidemiaImg}
        />

        <div
          className="flex flex-col lg:gap-10 gap-5 px-2 xxs:text-sm text-xs sm:text-base items-center bg-cover py-10 bg-no-repeat text-white"
          style={{
            background: `url(${DyslipedimiaBgImg})`,
            backgroundPosition: "center",
          }}
        >
          <div className="flex lg:gap-16 flex-col md:flex-row gap-5 ">
            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold ">{dysHeader1}</h3>
              <p className="">{dysPara1}</p>
            </div>

            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold ">{dysHeader2}</h3>
              <p className="">{dysPara2}</p>
            </div>
          </div>

          <div className="flex lg:gap-16 flex-col md:flex-row gap-5">
            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold ">{dysHeader3}</h3>
              <p className="">{dysPara3}</p>
            </div>

            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold ">{dysHeader4}</h3>

              <p className="">{dysPara4}</p>
            </div>
          </div>
        </div>

        {/************************* What are the causes  **************************/}

        <div className="bg-darkBlue text-white md:p-5 p-2">
          <h2 className="md:text-4xl text-xl font-medium text-center bg-white w-fit mx-auto text-darkBlue p-2 rounded">
            {causeHeader}
          </h2>
          <div>
            <h2 className="py-10 text-center md:text-xl">{causeSubHeader}</h2>
            <div className="flex justify-around items-center flex-wrap">
              {DyslipedimiaCauses &&
                DyslipedimiaCauses.map((cause) => {
                  const { id, title, ImageUrl } = cause;

                  return (
                    <div
                      data-aos="fade-up"
                      key={id}
                      className="xl:w-72 md:w-56 w-64 text-center flex flex-col items-center"
                    >
                      <img
                        src={ImageUrl}
                        className="lg:w-44 lg:h-44 w-36 h-36 rounded-full object-contain border-2 border-Orange"
                        alt={ImageUrl}
                      />
                      <p className="py-8 ">{title}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/************** Treatment options *******************************/}

        <div className="flex flex-col">
          <h2 className="md:text-4xl text-xl text-center font-medium">
            {treatHeader}
          </h2>
          <div
            data-aos="fade-up"
            className="flex xl:justify-around lg:flex-row flex-col gap-8 items-center text-mediumGray py-10 p-2"
          >
            <p className="md:w-96 md:text-base text-sm">{treatPara1}</p>
            <p className="md:w-96 md:text-base text-sm">{treatPara2}</p>
            <p className="md:w-96 md:text-base text-sm">{treatPara3}</p>
          </div>

          {/************ These non-statin drugs include  *************/}

          <div className="flex flex-col items-center gap-5 bg-lightWhite ">
            <h2 className="text-xl font-semibold my-5 p-2 bg-darkBlue  text-white rounded">
              {drugHeader}
            </h2>
            <div className="flex flex-col sm:flex-row gap-10 items-center py-5">
              <img
                data-aos="fade-right"
                src={freeDrugIMG}
                className="w-72 rounded"
                alt="freeDrugIMG"
              />
              <ul
                data-aos="fade-left"
                className="flex flex-col gap-5 list-disc"
              >
                <li>{drug1}</li>
                <li>{drug2}</li>
                <li>{drug3}</li>
              </ul>
            </div>
            <p className="font-medium px-4">{dysProgram}</p>

            {/********    EnquiryAndAssociatedSection    ********/}

            <EnquiryAndAssociatedSection />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dyslipedimia; // 205
