import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import DiabetesPng from "../Images/Carousel/diabetes.jpg";
import DyslipedimiaPng from "../Images/Carousel/dyslipidemia.jpg";
import ObesityPng from "../Images/Carousel/cheerful-girl-lost-weight-rejoicing-diet-worked.jpg";
import PCODPng from "../Images/Carousel/PCOD.jpg";
import PsoriasisImg from "../Images/Carousel/Psoriasis.jpg";
import AntiAgingImg from "../Images/Carousel/logan-weaver-lgnwvr-TtmCQskf6xs-unsplash.jpg";
import NAFLDImg from "../Images/Carousel/nafld.jpg";
import diabetesPng from "../Images/Logos/Diabetes-results/diabetesPng.png";
import medicationPng from "../Images/Logos/Diabetes-results/medicationPng.png";
import insulinPng from "../Images/Logos/Diabetes-results/insulinPng.png";
import sugarLevelPng from "../Images/Logos/Diabetes-results/sugarLevelPng.png";
import weightPng from "../Images/Logos/Diabetes-results/weightPng.png";
import smokingPng from "../Images/Dyslipedimia/01.png";
import obesityPng from "../Images/Dyslipedimia/02.png";
import excessiveFoodPng from "../Images/Dyslipedimia/03.png";
import alocoholPng from "../Images/Dyslipedimia/04.png";
import NutritonImg from "../Images/Anti-Aging/Approach/nutrition.jpg";
import FitnessImg from "../Images/Anti-Aging/Approach/fitness.jpg";
import SleepImg from "../Images/Anti-Aging/Approach/sleeping-beauty.jpg";
import StressImg from "../Images/Anti-Aging/Approach/strees.jpg";
import BoostedImg from "../Images/Anti-Aging/Benefits/boost.png";
import WeightManagementImg from "../Images/Anti-Aging/Benefits/weight.png";
import YogaImg from "../Images/Anti-Aging/Benefits/metal clarity.png";
import RadiantImg from "../Images/Anti-Aging/Benefits/radiant.png";
import EvidenceImg from "../Images/Anti-Aging/why choose/Evidence.jpg";
import ExpertImg from "../Images/Anti-Aging/why choose/consult.jpg";
import TransformedImg from "../Images/Anti-Aging/why choose/transformation.jpg";
import ObesityImg from "../Images/NAFLD/obesity.jpg";
import InsulinImg from "../Images/NAFLD/Insulin.jpg";
import MetabolicImg from "../Images/NAFLD/metabolic.jpg";
import GeneticsImg from "../Images/NAFLD/genetics.jpg";
import HealthyDietImg from "../Images/NAFLD/healthyDiet.jpg";
import ExcerciseImg from "../Images/NAFLD/excercise.jpg";
import MedicationImg from "../Images/NAFLD/Medication.jpg";
import AvoidAlcoholImg from "../Images/NAFLD/NoAlcohol.jpg";

import HolisticImg from "../Images/Anti-Aging/why choose/holistic.jpg";

// import common_en from "../locale/common/en.json";
// import common_hi from "../locale/common/hi.json";
// import common_tel from "../locale/common/tel.json";

// const resources = {
//   common: {
//     en: common_en,
//     hi: common_hi,
//     tel: common_tel,
//   },
// };

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    returnObjects: true,

    resources: {
      en: {
        translation: {
          common: {
            enquiryBtn: "Enquire Now",
            associates: "In Association With",
            contactBtn: "Contact Us",
            submitBtn: "Submit",
            knownBtn: "Known More",
            viewBtn: "View More",
            homeBtn: " Go to Homepage ",
            thankYou: "Thank You!",
            getBack: "for contacting us,We will get back to you soon...",
          },
          headerAndFooter: {
            home: "Home",
            programs: "Programs",
            diabetes: "Diabetes",
            dyslipedimia: "Dyslipedimia",
            pcod: "PCOD",
            obesity: "Obesity",
            psoriasis: "Psoriasis",
            antiaging: "Anti-Aging",
            nafld: "NAFLD",
            shop: "Shop",
            whatwedo: "What we do",
            blog: "Blog",
            contactus: "Contact Us",
            ndhealthDesc:
              "Explore ND Health.AI’s programs for Diabetes, Dyslipidemia, & PCOD. Personalized care for your health journey. Start transforming today",
            quickLinks: "Quick Links",
            ourPrograms: "Our Programs",
            otherLinks: "Other Links",
            privacy: "Privacy",
            terms: "Terms",
            shipping: "Shipping",
            cancellation: "Cancellation",
            association: "In Association With",
            followUs: "Follow Us",
          },

          home: {
            future: "the future",
            convergence: "@ Convergence of Data Science & Healthcare",
            whatwedoBtn: "What we do",
            transformYour: {
              transform:
                "Transform Your Well-being with Cutting-Edge Whole Body Digital",
              technology: "Technology!",
              Programs: [
                {
                  id: 1,
                  title: "Diabetes",
                  desc: "Dealing with diabetes symptoms? Are fluctuating blood sugar levels, fatigue, or unexplained weight changes impacting your daily life? At NDHealth.ai, our team specializes in diabetes management. Don't let diabetes control you – schedule your appointment now and embark on your journey towards a healthier, happier you.",
                  path: "/programs/diabetes-reversal-program",
                  ImageUrl: DiabetesPng,
                },
                {
                  id: 2,
                  title: "Dyslipidemia",
                  desc: "Are you grappling with the challenges of dyslipidemia? Do elevated cholesterol levels, triglycerides, or other lipid abnormalities affect your health? NDHealth.ai offers specialized care for dyslipidemia management. Don't let dyslipidemia hinder your well-being – schedule your appointment now and embark on the journey towards better cardiovascular health.",
                  path: "/programs/dyslipidemia-treatment-program",
                  ImageUrl: DyslipedimiaPng,
                },
                {
                  id: 3,
                  title: "PCOD",
                  desc: "Struggling with PCOD symptoms? Are irregular periods, acne, weight gain, or fertility issues affecting your well-being? At NDHealth.ai, our specialists are dedicated to PCOD management. Don't let PCOD limit you – book your appointment now and take the first step towards a healthier, happier life.",
                  path: "/programs/pcod-management-program",
                  ImageUrl: PCODPng,
                },
                {
                  id: 4,
                  title: "Obesity",
                  desc: "Struggling with obesity? Is excess weight impacting your quality of life? At NDHealth.ai, our specialists are dedicated to obesity management. Don't let obesity limit you – book your appointment now and take the first step towards a healthier, happier life.",
                  path: "/programs/obesity-management-program",
                  ImageUrl: ObesityPng,
                },
                {
                  id: 5,
                  title: "Psoriasis",
                  desc: "Dealing with Psoriasis symptoms? Is the discomfort of red, inflamed skin patches affecting your daily life? NDHealth.ai specializes in Psoriasis management. Don't let Psoriasis hinder you – schedule your appointment now and embark on the journey to a healthier, happier you.",
                  path: "/programs/psoriasis-treatment-program",
                  ImageUrl: PsoriasisImg,
                },
                {
                  id: 6,
                  title: "Anti-Aging",
                  desc: "Concerned about signs of aging? Are wrinkles, fine lines, or loss of vitality impacting your confidence? NDHealth.ai offers specialized anti-aging solutions. Our team of experts is dedicated to helping you defy the effects of aging. Don't let age define you – schedule your appointment now and embark on your journey towards a more youthful and vibrant you.",
                  path: "/programs/anti-aging-program",
                  ImageUrl: AntiAgingImg,
                },
                {
                  id: 7,
                  title: "NAFLD",
                  desc: "Struggling with NAFLD symptoms? Are liver issues, fatigue, weight gain, or abdominal discomfort affecting your well-being? At NDHealth.ai, our specialists are dedicated to NAFLD management. Don't let NAFLD limit you – book your appointment now and take the first step towards a healthier, happier life.",
                  path: "/programs/nafld-program",
                  ImageUrl: NAFLDImg,
                },
              ],

              bookNowBtn: "Book now",
            },
            dysfunction: {
              title:
                "Dysfunctional Metabolism - The Core Culprit Behind Chronic Metabolic Disease!",
              miniTitle1:
                "The underlying issue behind chronic metabolic diseases",
              miniDesc1:
                "Stems from a dysfunctional metabolic process, triggered by external factors such as nutrition, sleep, activity, and stress, which disrupt essential chemical processes vital for life, forming the foundation for AI-powered health solutions to intervene and mitigate the development of chronic metabolic conditions.",
              miniTitle2:
                "Addressing the Root Cause - Key to Overcoming Challenges",
              miniDesc2:
                "To reverse chronic metabolic diseases, The Whole-Body Digital NDHealth offers targeted guidance, including online health consultations, to enhance external factors like nutrition, sleep, activity, and stress, restoring optimal body function and reversing negative effects on organs and metabolic disorders.",
              miniTitle3: "Unlocking the Power of Recognizing Individuality",
              miniDesc3:
                "Each individual's metabolism is crucial for achieving the best health solutions. Medical research highlights the variability in how metabolisms function, posing challenges in tailoring treatments effectively. The Whole-Body Digital NDHealth offers precise guidance customized to each member's distinct metabolism, ensuring personalized support for improved health outcomes",
              knownBtn: "Know More",
            },

            benefits: {
              benefTitle:
                "Benefits of choosing AI-powered health solutions like NDHealth",
              para1: "Reduce costly medications including insulin",

              para2: "Increase energy, improve mood, and decrease symptoms",

              para3: "your metabolism to help normalize your blood sugar",

              para4: "Access Real-Time Data for remote patient monitoring",

              para5:
                "Proven Results backed by cutting-edge AI healthcare technology",
            },
          },

          diabetes: {
            title: "Diabetes Reversal Program",

            mainDesc:
              "Are you ready to take control of your diabetes and transform your life? NDHealth introduces a groundbreaking Diabetes Reversal Program, harnessing the power of AI technology. Our innovative approach is designed to help you reverse diabetes and reclaim your health like never before.",
            contactBtn: "Contact Us",

            Transform: {
              subHeading1: "Transforming Health with NDHealth",
              subPara1:
                "The Whole-Body Digital incorporates AI healthcare solutions to create a virtual representation of your metabolism, offering personalized advice on nutrition, sleep, exercise, and breathing via a user-friendly app interface.",
              subHeading2: "NdHealth's Integrated Program",
              subPara2:
                "NdHealth's comprehensive program integrates innovative healthcare services, combining the capabilities of the Whole-Body Digital NdHealth with a devoted team of caregivers who oversee your sensor data, provide personalized recommendations, and assist you on your health journey",
              subHeading3: "Revolutionary Diabetes Management with NdHealth AI",
              subPara3:
                "Experience a revolutionary program that offers a transformative solution for individuals managing diabetes. NdHealth, an innovative artificial intelligence-based system, creates a personalized body double to analyze and comprehend the intricacies of your body, providing the best health solutions in one comprehensive approach.",
              subHeading4: "Find Top Doctors Nearby",
              subPara4:
                "Find the best doctors near you through a user-friendly application that ensures a balanced and tailored approach to reverse diabetes. Embrace the power of modern technology, largely untapped by many, and witness the potential to achieve diabetes reversal within a remarkable four-month timeframe.",
            },

            Results: {
              header: "Results you can see and feel",
              subHeader: "Helping people to reverse diabetes",
              DiabetesResults: [
                {
                  id: 1,
                  imgUrl: diabetesPng,
                  heading: "1.9%",
                  subHeading: "Average HbA1c drop",
                },
                {
                  id: 2,
                  imgUrl: medicationPng,
                  heading: "90%",
                  subHeading: "Have reduced or eliminated medication",
                },
                {
                  id: 3,
                  imgUrl: insulinPng,
                  heading: "92%",
                  subHeading: "Have reduced or eliminated insulin",
                },
                {
                  id: 4,
                  imgUrl: sugarLevelPng,
                  heading: "90%",
                  subHeading: "Have seen reduction in sugar levels",
                },
                {
                  id: 5,
                  imgUrl: weightPng,
                  heading: "3+ Kg",
                  subHeading: "Average weight-loss sustained within months",
                },
              ],
            },

            Plan: {
              header: "What's in the plan",
              subHeader1: "Continuous Glucose Monitor",
              planSubPara1:
                "Technology that collects real-time blood sugar data for analysis.",
              subHeader2: "On-demand doctor supervision",
              planSubPara2: "No more once-in-a-blue-moon appointments!",
              subHeader3: "Dedicated Diabetes Expert",
              planSubPara3:
                "Daily support and answers to questions are just a WhatsApp message away!",
              subHeader4: "Comprehensive diagnostic testing",
              planSubPara4:
                "A 70+ parameter blood and urine check-up at onboarding and diabetes screening tests every quarter.",
              subHeader5: "Personalised plans",
              planSubPara5:
                "Personalised and easy to follow meal, stress and sleep plans.",
            },

            enquiry: "Enquire Now",
            association: "In Association With",
          },

          DyslipedimiaProgram: {
            dysTitle: "Dyslipedimia",
            dysDesc:
              "Concerned about dyslipidemia? You're not alone. This common condition affects millions worldwide. But with NDHealth, powered by AI, discover a personalized approach to managing dyslipidemia. Our innovative program integrates cutting-edge technology to provide tailored guidance and support for improved health outcomes.",

            dysHeader1: "NdHealth's Digital Solution",
            dysPara1:
              "Concerned about dyslipidemia? You're not alone. This common condition affects millions worldwide. But with NDHealth, powered by AI, discover a personalized approach to managing dyslipidemia. Our innovative program integrates cutting-edge technology to provide tailored guidance and support for improved health outcomes.",
            dysHeader2: "Revolutionary Dyslipidemia Treatment",
            dysPara2:
              "Experience the best dyslipidemia treatment with a revolutionary program designed to address it with unprecedented precision. By leveraging state-of-the-art technology, NdHealth offers a holistic approach that eliminates the need for invasive treatments and medications. Through an artificial intelligence-driven system, a personalized body model is constructed to analyze the complexities of your physiology comprehensively.",
            dysHeader3:
              "Unlock Your Health Potential with Our User-Friendly Dyslipidemia",
            dysPara3:
              "Accessible via a user-friendly application, this program ensures a balanced and individualized approach to managing dyslipidemia. Embrace the potential of modern technology, often underutilized by many, and witness",
            dysHeader4: "Causes and Management",
            dysPara4:
              "Dyslipidemia is a chronic condition that cannot be cured. However, certain symptoms can be improved through lifestyle modifications, medications, and targeted interventions. While the exact cause of dyslipidemia remains unknown, individuals with a family history or those diagnosed with type 2 diabetes are at a higher risk.",

            Causes: {
              causeHeader: "What are the causes and who’s at risk?",
              causeSubHeader:
                "Several behaviours can lead to dyslipidemia. They include:",
              DyslipedimiaCauses: [
                {
                  id: 1,
                  title: "Cigarette Smoking.",
                  ImageUrl: smokingPng,
                },
                {
                  id: 2,
                  title: "Obesity and a sedentary lifestyle",
                  ImageUrl: obesityPng,
                },
                {
                  id: 3,
                  title:
                    "Consumption of foods high in saturated fat and trans fat",
                  ImageUrl: excessiveFoodPng,
                },
                {
                  id: 4,
                  title:
                    "Excessive alcohol consumption may also contribute to higher triglyceride levels.",
                  ImageUrl: alocoholPng,
                },
              ],
            },
            Treatment: {
              treatHeader: "Treatment options",
              treatPara1:
                "The most commonly used medication to treat dyslipidemia is a statin. Statins help reduce LDL levels by interfering with cholesterol production in the liver. Here’s more about how statins work.",
              treatPara2:
                "There are several types of statin. They all work a little differently, with some being stronger than others.",
              treatPara3:
                "Your doctor may also prescribe other cholesterol medications. They may be taken in addition to a statin or in place of a statin. There are many pros and cons to consider when choosing between cholesterol-controlling drugs.",
            },

            non_statinDrugs: {
              drugHeader: "These non-statin drugs include",
              drug1: "ezetimibe (Zetia)",
              drug2: "fibrates, like fenofibrate (Feno glide)",
              drug3: "PCSK9 inhibitors",
            },

            dysProgram:
              "Experience relief from dyslipidemia with our highly recommended NdHealth REVERSAL PROGRAM.",
          },

          Pcod: {
            pcodTitle: "PCOD Reversal Program",
            pcodDesc:
              "Struggling with PCOD? Connect with us for personalized support. Our team offers guidance and solutions to manage PCOD effectively.",
            Comprehensive: {
              compHeader: "A Comprehensive Guide to PCOD with NDHealth",
              compSubHeader: "Empowering Women's Health",
              pcodSectionTitle1: "Overview",
              pcodSectionDesc1:
                "Polycystic ovary syndrome (PCOS) is a common hormonal condition that affects women of reproductive age. It usually starts during adolescence, but symptoms may fluctuate over time. PCOS can cause hormonal imbalances, irregular periods, excess androgen levels and cysts in the ovaries. Irregular periods, usually with a lack of ovulation, can make it difficult to become pregnant. PCOS is a leading cause of infertility. ",
              pcodSectionTitle2: "Scope of the problem",
              pcodSectionDesc2:
                "PCOS a significant public health problem and is one of the common hormonal disturbances affecting women of reproductive age. The condition affects an estimated 8–13% of women of reproductive age, and up to 70% of cases are undiagnosed. The prevalence of PCOS is higher among some ethnicities and these groups often experience more complications, in particular related to metabolic problems.",
              pcodSectionTitle3: "Symptoms",
              pcodSectionDesc3: {
                pcodSection3Main:
                  "Symptoms of polycystic ovary syndrome can differ from person to person. Symptoms may change over time and often occur without a clear trigger. Possible symptoms include:",
                pcodSection3Sub1:
                  "• heavy, long, intermittent, unpredictable or absent periods",
                pcodSection3Sub2: "• infertility",
                pcodSection3Sub3: "• acne or oily skin",
                pcodSection3Sub4: "• excessive hair on the face or body",
                pcodSection3Sub5: "• weight gain, especially around the belly.",
              },
              pcodSectionTitle4: "Diagnosis",
              pcodSectionDesc4: {
                pcodSection4Main:
                  "Polycystic ovary syndrome is diagnosed by the presence of at least two out of the following:",
                pcodSection4Sub1:
                  "1. signs or symptoms of high androgens (unwanted facial or  bodily hair, loss of hair from the head, acne or an    elevated blood level of testosterone) – after other causes for this have been excluded;",
                pcodSection4Sub2:
                  " 2. irregular or absent menstrual periods – after other causes for this have been excluded; and",
                pcodSection4Sub3:
                  " 3. polycystic ovaries on an ultrasound scan.",
              },
            },

            KeyFacts: {
              keyFactsTitle: "Key facts",
              keyFactsPara1:
                "PCOS is the commonest cause of anovulation and a leading contributor to infertility among women.",
              keyFactsPara2:
                "Despite its prevalence, up to 70% of women with PCOS remain undiagnosed globally, highlighting the need for increased awareness and screening.",
              keyFactsPara3:
                "PCOS affects an estimated 8-13% of women of reproductive age, making it a significant health concern ",
              keyFactsPara4:
                "Beyond fertility issues, PCOS is associated with a range of long-term health problems that can impact physical and emotional well-being, underscoring the importance of early diagnosis and management. ",
              keyFactsPara5:
                "While PCOS tends to run in families, its manifestations and impacts can vary across different ethnic groups, suggesting the influence of genetic and environmental factors.",
            },
          },

          obesity: {
            obTitle: "Welcome to Our Weight Loss Program",
            obDesc:
              "Are you tired of struggling with weight loss? Do you feel like you've tried every diet and exercise regimen with minimal success? It's time to consider a revolutionary approach: metabolic reversal powered by artificial intelligence (AI). ",
            metabTitle: "What is Metabolic Reversal?",
            metabDesc:
              "Metabolic reversal is not just about shedding pounds; it's about addressing the root cause of weight gain and metabolic dysfunction. By targeting factors such as insulin resistance, hormonal imbalances, and inflammation, metabolic reversal aims to restore your body's natural ability to burn fat efficiently.",
            aiTitle: "How AI Can Help",
            aiDesc:
              "Our weight loss program harnesses the power of AI to personalize your journey to metabolic reversal. Using advanced algorithms, we analyze your unique genetic makeup, lifestyle habits, medical history, and dietary preferences to tailor a plan that works specifically for you. ",
            personalTitle: "Personalized Nutrition ",
            personalDesc:
              "Say goodbye to one-size-fits-all meal plans. Our AI-powered platform designs custom nutrition plans based on your individual needs and goals. Whether you're looking to control blood sugar levels, reduce inflammation, or optimize hormone balance, we'll create a sustainable eating plan that nourishes your body and promotes metabolic health.",
            realtimeTitle: "Real-Time Monitoring and Support",
            realtimeDesc:
              "With our AI-powered platform, you'll receive real-time feedback and support every step of the way. Track your progress, monitor key metrics such as body composition and blood markers, and receive personalized insights to keep you motivated and on track toward your weight loss goals.",
            intelligentTitle: "Intelligent Exercise Guidance ",
            intelligentDesc:
              "Exercise is a crucial component of metabolic reversal, but not all workouts are created equal. Our AI system curates personalized exercise routines that take into account your fitness level, preferences, and any underlying health conditions. Whether you prefer high-intensity interval training, strength training, or yoga, we'll guide you through workouts that maximize fat-burning and metabolic efficiency.",
            joinTitle: "Join Our Program Today ",
            joinDesc:
              "Don't let frustration and disappointment hold you back any longer. Embrace the power of metabolic reversal with our AI-driven weight loss program. Say goodbye to crash diets and endless hours at the gym—experience sustainable weight loss and improved metabolic health like never before. ",
            obessignupMsg:
              "Sign up now and take the first step towards a healthier, happier you! ",
          },

          psoriasis: {
            psoTitle: "Clearer Skin with Psoriasis Treatment ",
            psoDesc:
              "Psoriasis can take a toll on your well-being. Here at ND Health, we leverage the power of Artificial Intelligence (AI) to offer a revolutionary approach to psoriasis treatment in India.",
            psoIntroTitle: {
              psoIntroSubHead1: "Introducing ",
              psoIntroSubHead2: "NdHealth Technology",
            },
            psoIntroDesc:
              "Imagine a virtual health companion solely dedicated to understanding your unique situation. That's ND Health. This cutting-edge platform analyzes your medical history, and real-time data (from wearables and exams), and utilizes AI algorithms to create a digital twin of your health.",

            psoChoose: {
              psoChooseTitle: {
                psoChooseTitle1: "Why Choose ",
                psoChooseTitle2: "ND Health?",
              },
              psoChooseSubHead1: "Beyond Generic Solutions ",

              psoChooseSubPara1:
                "Ditch the one-size-fits-all approach. ND Health personalizes your psoriasis treatment plan based on your specific needs. ",
              psoChooseSubHead2: "Proactive Management ",

              psoChooseSubPara2:
                "Stay ahead of flares with continuous monitoring and personalized recommendations from your ND Health ",
              psoChooseSubHead3: "Data-Driven Insights",

              psoChooseSubPara3:
                "Understand your Psoriasis with AI-powered analysis. Make informed decisions about your treatment path",
              psoChooseSubHead4: "Empowerment Through Knowledge",

              psoChooseSubPara4:
                "Gain a deeper understanding of your condition and how to manage it effectively.",
              psoChooseSubHead5: "Live a More Comfortable Life ",

              psoChooseSubPara5:
                "Our goal is to help you achieve lasting relief and improve your overall well-being. ",
            },

            psoEmbrace: {
              psoEmbraceTitle: {
                psoEmbraceTitle1: "Embrace the",
                psoEmbraceTitle2: "Future ",
                psoEmbraceTitle3: "of Psoriasis Treatment",
              },
              psoEmbraceDesc:
                "Don't settle for just managing symptoms. At ND Health Ai, we believe in finding the root cause and developing a personalized treatment plan. Contact ND Health today and unlock the power of AI for clearer skin and a healthier you!",
            },
          },

          antiAging: {
            antiTitle: "Transforming Lives with Metabolic Age Reversal",
            antiDesc:
              "At ND Health, we're dedicated to helping you defy age and revitalize your health through the power of metabolic age reversal. Say goodbye to feeling tired and hello to a vibrant, energetic you!",
            antiMetabTitle: "What is Metabolic Age Reversal?",
            antiMetabDesc:
              "Metabolic age reversal isn't about turning back time; it's about optimizing your body's metabolism to feel and look your best. It's a scientifically-proven journey towards a healthier, more youthful you.",

            approach: {
              appraochMainTitle: "Our Approach",
              OurApproach: [
                {
                  id: 1,
                  title: "Personalized Nutrition Plans",
                  description:
                    "No cookie-cutter diets here. We create tailored nutrition plans to fuel your body and ignite your metabolism",
                  imageUrl: NutritonImg,
                },
                {
                  id: 2,
                  title: "Custom Fitness Programs",
                  description:
                    "Say hello to workouts that boost metabolism and sculpt your body. Our trainers design routines just for you.",
                  imageUrl: FitnessImg,
                },
                {
                  id: 3,
                  title: "Sleep Optimization",
                  description:
                    "Quality sleep is key to a healthy metabolism. Discover strategies to improve your rest for maximum rejuvenation.",
                  imageUrl: SleepImg,
                },
                {
                  id: 4,
                  title: "Stress Management",
                  description:
                    "Stress can wreak havoc on your metabolism. Learn techniques to keep stress in check and your metabolism thriving.",
                  imageUrl: StressImg,
                },
              ],
            },

            appBenefits: {
              appBenefitsMainTitle: "Benefits of Metabolic Age Reversal",
              BenefitsOfAgeReversal: [
                {
                  id: 1,
                  title: "Boosted Energy Levels",
                  description:
                    "Wake up feeling refreshed and ready to conquer your day.",
                  imageUrl: BoostedImg,
                },
                {
                  id: 2,
                  title: "Effective Weight Management",
                  description:
                    "Shed unwanted pounds as your metabolism becomes a fat-burning machine.",
                  imageUrl: WeightManagementImg,
                },
                {
                  id: 3,
                  title: "Enhanced Mental Clarity",
                  description:
                    "A healthy metabolism means a healthier mind. Improve focus and cognitive function.",
                  imageUrl: YogaImg,
                },
                {
                  id: 4,
                  title: "Radiant Vitality",
                  description:
                    "Glow with vitality inside and out, thanks to a metabolism that supports overall well-being.      ",
                  imageUrl: RadiantImg,
                },
              ],
            },

            appNdHealth: {
              appNdHealthMainTittle: "Why Choose ND Health? ",
              ChooseNdHealth: [
                {
                  id: 1,
                  title: "Evidence-Based Approach",
                  description:
                    "Our methods are rooted in the latest scientific research on metabolic health and longevity.",
                  imageUrl: EvidenceImg,
                },
                {
                  id: 2,
                  title: "Expert Guidance",
                  description:
                    "Work with our team of experienced health professionals who are committed to your success.",
                  imageUrl: ExpertImg,
                },
                {
                  id: 3,
                  title: "Real Transformations",
                  description:
                    "  Hear from our community of clients who have reclaimed their health through metabolic age reversal.",
                  imageUrl: TransformedImg,
                },

                {
                  id: 4,
                  title: "Holistic Solutions",
                  description:
                    "Embrace a holistic approach that nurtures your body's natural ability to heal and thrive.",
                  imageUrl: HolisticImg,
                },
              ],
            },
            appEndingPara:
              "Feel free to adjust any details or add specifics that align with ND Health's unique offerings and brand voice! ",
          },

          Nafld: {
            nafldMainTittle: "Non-alcoholic Fatty Liver Disease Treatment",
            nafldDesc:
              "Worried about Non-alcoholic Fatty Liver Disease (NAFLD)? You're not alone. Millions in India face this silent health concern. But there's good news! ND Health, powered by cutting-edge Artificial Intelligence (AI), offers a revolutionary approach to personalized NAFLD treatment. ",
            nafldSubhead: "What is NAFLD ?",
            nafldSubPara:
              "NAFLD is a prevalent liver condition characterized by an accumulation of fat in the liver cells. Unlike alcoholic liver disease, NAFLD occurs in individuals who consume little to no alcohol. It's often associated with obesity, insulin resistance, and metabolic syndrome. ",
            nafldCauses: {
              nafldCausesTittle: "Causes of NAFLD ",
              CauseOfNafld: [
                {
                  id: 1,
                  title: "Obesity",
                  description:
                    "Excess body weight, especially abdominal obesity, increases the risk of developing NAFLD.",
                  imageUrl: ObesityImg,
                },
                {
                  id: 2,
                  title: "Insulin Resistance",
                  description:
                    "Insulin resistance, often associated with type 2 diabetes, interferes with how the body processes sugar and fat, leading to fat accumulation in the liver.",
                  imageUrl: InsulinImg,
                },
                {
                  id: 3,
                  title: "Metabolic Syndrome",
                  description:
                    "NAFLD is closely linked to metabolic syndrome, a cluster of conditions including high blood pressure, high blood sugar, excess body fat around the waist, and abnormal cholesterol or triglyceride levels.",
                  imageUrl: MetabolicImg,
                },
                {
                  id: 4,
                  title: "Genetics",
                  description:
                    "Certain genetic factors can predispose individuals to NAFLD, although lifestyle factors play a significant role.",
                  imageUrl: GeneticsImg,
                },
              ],
            },

            nafldSypmtoms: {
              nafldSypmtomsTitle: "Symptoms of NAFLD",
              nafldSypmtomsPara:
                "NAFLD often progresses silently, with many individuals experiencing no noticeable symptoms in the early stages. However, as the condition advances, symptoms may include:",
              nafldSypmtomsSubPara: {
                nafldSypmtomsSubPara1: "Fatigue",
                nafldSypmtomsSubPara2: "Weakness",
                nafldSypmtomsSubPara3:
                  "Jaundice (yellowing of the skin and eyes)",
                nafldSypmtomsSubPara4: "Abdominal discomfort or pain",
                nafldSypmtomsSubPara5: "Swelling in the abdomen or legs",
                nafldSypmtomsSubPara6: "Enlarged liver",
              },
            },

            nadldLifestyle: {
              nadldLifestyleTittle: "Lifestyle-Oriented Treatment For NAFLD",
              lifeStyleOfNafld: [
                // {
                //   id: 1,
                //   title: "Weight Loss",
                //   description:
                //     "Achieving a healthy weight through diet and exercise is key in managing NAFLD.",
                //   imageUrl: WeightLossImg,
                // },
                {
                  id: 2,
                  title: "Healthy Diet",
                  description:
                    "Adopting a balanced diet rich in fruits, vegetables, whole grains, and lean proteins can improve liver health.",
                  imageUrl: HealthyDietImg,
                },
                {
                  id: 3,
                  title: "Regular Exercise",
                  description:
                    "Engaging in regular physical activity helps reduce liver fat and improves insulin sensitivity.",
                  imageUrl: ExcerciseImg,
                },
                {
                  id: 4,
                  title: "Medications",
                  description:
                    " In some cases, doctors may prescribe medications to manage underlying conditions such as diabetes or high cholesterol.",
                  imageUrl: MedicationImg,
                },
                {
                  id: 5,
                  title: "Avoidance of Alcohol",
                  description:
                    "Even though NAFLD is not caused by alcohol consumption, limiting or abstaining from alcohol is important for overall liver health.",
                  imageUrl: AvoidAlcoholImg,
                },
              ],
            },
            nafldEndTittle: "Take Charge of Your Liver Health ",
            nafldEndPara:
              "Don't wait! Take charge of your liver health today. Schedule a consultation with ND Health. and embark on your personalized path to conquering NAFLD. Together, we can optimize your health and achieve lasting well-being.",
          },

          shop: {
            coming: "We're Coming Soon",
            tuned: "Stay tuned ",
            homeBtn: " Go to Homepage ",
          },

          WhatweDo: {
            whatweDoTitle:
              "Elevating Healthcare through Digitalization and Data Moduling",
            whatweDoPrgs: {
              linkDiabetes: "Diabetes",
              linkDyslipidemia: "Dyslipidemia",
              linkPCOD: "PCOD",
              linkObesity: "Obesity",
              linkPsoriasis: "Psoriasis",
              linkAntiAging: "Anti-Aging",
              linkNAFLD: "NAFLD",
            },
            digitalTwin: {
              digitalTwinTitle: "WHAT IS DIGITALIZATION",
              digitalTwinHeader1: "A New Era In Healthcare ",
              digitalTwinPara1:
                "The concept of NdHealth, a virtual representation crafted from vast data points and AI algorithms, envisioned as the future of healthcare, holds the promise of ground breaking advancements in patient care. By harnessing patient twinning, our vision is to expedite individuals' return to normalcy and, ideally, prevent illnesses proactively. While it may sound ambitious, the transformative potential of this technology lies in its data-driven approach.",
              digitalTwinHeader2: "Real Time Data ",
              digitalTwinPara2:
                "The NdHealth is a composite model derived from a person's comprehensive medical history and digital health records, rigorously updated with real-time data from medical examinations and wearable sensors. This amalgamation of information undergoes continuous analysis by AI algorithms, culminating in simulations predicting the individual's future health trajectory. These insights equip healthcare providers and patients with actionable information for informed decision-making. ",
              digitalTwinHeader3: "Efficency",
              digitalTwinPara3:
                "However, the efficacy of NdHealth hinges on its integration within a network of digitalization, be it physicians or medical devices. This interconnected ecosystem ensures tailored and automated configurations to optimize patient care, minimizing discrepancies and maximizing efficiency. The advantages for patients are profound - continuous monitoring of health data, comprehensible presentation of insights derived from evaluations, and empowerment to proactively manage health through lifestyle adjustments or timely interventions. ",
              digitalTwinHeader4: "Serious Medical Situations",
              digitalTwinPara4:
                "In critical situations, such as emergencies, NdHealth acts as a vigilant ally, enabling swift responses by alerting relevant parties, transmitting vital data, and facilitating immediate medical support. Furthermore, for caregivers and healthcare professionals, digital model provides a holistic view of a patient's health status, facilitating precise diagnoses, treatment planning, and remote interventions supported by advanced technologies under expert supervision ",
            },
          },

          contactus: {
            contactTitle: {
              contactTitleStart: "Get in touch",
              contacTitleMiddle:
                "With us to discover the amazing Possible Ways to ",
              contacTitleLast: "Recreate yourself",
            },

            contactForm: {
              contactFormTitle: "Contact us",
              username: {
                usernameLabel: "Full Name",
                usernamePlaceholder: "Enter your name*",
                usernameError1: "Username field cannot be blank",
                usernameError2: "Username within 4 to 15 Characters",
              },
              phoneNo: {
                phoneNoLabel: "Phone No",
                phoneNoPlaceholder: "Enter your mobile*",
                phoneNoError1: "Phone number is required",
                phoneNoError2: "Phone number must be 10 digits",
                phoneNoError3: "Invalid mobile number",
              },
              email: {
                emailLabel: "Email",
                emailPlaceholder: "Enter your email*",
                emailError1: "Email is required",
                emailError2: "inValid Email",
              },
              city: {
                cityLabel: "City",
                cityPlaceholder: "Enter your city*",
                cityError1: "city field cannot be blank",
                cityError2: "city must be morethan 2 Characters",
              },
              category: {
                categoryLabel: "Select Category",
                categoryPlaceholder: "Your health concern",
                categoryError: "required",
                categoryPrgs: {
                  linkDiabetes: "Diabetes",
                  linkDyslipidemia: "Dyslipidemia",
                  linkPCOD: "PCOD",
                  linkObesity: "Obesity",
                  linkPsoriasis: "Psoriasis",
                  linkAntiAging: "Anti-Aging",
                  linkNAFLD: "NAFLD",
                },
              },
              checkbox: {
                checkboxLabel:
                  "I agree to be contacted by ND Health via Call, SMS, WhatsApp & Email",
                checkboxError: "required",
              },
            },
          },
        },
      },

      hi: {
        translation: {
          common: {
            enquiryBtn: "अभी पूछताछ करें",
            associates: "के सहयोग से",
            contactBtn: "संपर्क करें",
            submitBtn: "जमा करना",
            knownBtn: "अधिक ज्ञात",
            viewBtn: "और देखें",
            homeBtn: "मुखपृष्ठ प्र जाएं",
            thankYou: "धन्यवाद!",
            getBack:
              "हमसे संपर्क करने के लिए धन्यवाद, हम जल्द ही आपसे संपर्क करेंगे...",
          },
          headerAndFooter: {
            home: "मुखपृष्ठ",
            programs: "कार्यक्रमों",
            diabetes: "मधुमेह",
            dyslipedimia: "डिस्लिपिडेमिया",
            pcod: "PCOD",
            obesity: "मोटापा",
            psoriasis: "सोरायसिस",
            antiaging: "बुढ़ापा विरोधी",
            nafld: "NAFLD",
            shop: "दुकान",
            whatwedo: "हम क्या करते हैं",
            blog: "ब्लॉग",
            contactus: "संपर्क करें",
            ndhealthDesc:
              "मधुमेह, डिस्लिपिडेमिया और पीसीओएस के लिए AND हेल्थ.एआई के कार्यक्रमों का अन्वेषण करें। आपकी स्वास्थ्य यात्रा के लिए वैयक्तिकृत देखभाल। आज ही बदलना शुरू करें",
            quickLinks: "त्वरित सम्पक",
            ourPrograms: "हमारे कार्यक्रम",
            otherLinks: "अन्य लिंक",
            privacy: "गोपनीयता",
            terms: "निबंधन",
            shipping: "शिपिंग",
            cancellation: "रद्द करना",
            association: "के सहयोग से",
            followUs: "हमारे पर का पालन करें",
          },
          home: {
            future: "भविष्य",

            convergence: "@ डेटा साइंस और हेल्थकेयर का अभिसरण",
            whatwedoBtn: "हम क्या करते हैं",
            transformYour: {
              transform: "अत्याधुनिक संपूर्ण शारीरिक डिजिटल",
              twin: "Twin Health",
              technology: "प्रौद्योगिकी के साथ अपना कल्याण बदलें!",
              Programs: [
                {
                  id: 1,
                  title: "मधुमेह",
                  desc: "मधुमेह के लक्षणों से निपटना? क्या रक्त शर्करा के स्तर में उतार-चढ़ाव, थकान, या अस्पष्टीकृत वजन परिवर्तन आपके दैनिक जीवन को प्रभावित कर रहे हैं? NDHealth.ai पर, हमारी टीम मधुमेह प्रबंधन में माहिर है। मधुमेह को अपने ऊपर हावी न होने दें - अभी अपनी नियुक्ति निर्धारित करें और एक स्वस्थ, खुशहाल जीवन की ओर अपनी यात्रा शुरू करें।",
                  path: "/programs/diabetes-reversal-program",
                  ImageUrl: DiabetesPng,
                },
                {
                  id: 2,
                  title: "डिसलिपिडेमिया",
                  desc: "क्या आप डिस्लिपिडेमिया की चुनौतियों से जूझ रहे हैं? क्या बढ़ा हुआ कोलेस्ट्रॉल स्तर, ट्राइग्लिसराइड्स, या अन्य लिपिड असामान्यताएं आपके स्वास्थ्य को प्रभावित करती हैं? NDHealth.ai डिस्लिपिडेमिया प्रबंधन के लिए विशेष देखभाल प्रदान करता है। डिस्लिपिडेमिया को अपनी भलाई में बाधा न बनने दें - अभी अपनी नियुक्ति निर्धारित करें और बेहतर हृदय स्वास्थ्य की दिशा में यात्रा शुरू करें।",
                  path: "/programs/dyslipidemia-treatment-program",
                  ImageUrl: DyslipedimiaPng,
                },
                {
                  id: 3,
                  title: "PCOD",
                  desc: "PCOD के लक्षणों से जूझ रहे हैं? क्या अनियमित मासिक धर्म, मुँहासे, वजन बढ़ना या प्रजनन संबंधी समस्याएं आपकी सेहत को प्रभावित कर रही हैं? NDHealth.ai पर, हमारे विशेषज्ञ PCOD प्रबंधन के लिए समर्पित हैं। PCOD को खुद पर सीमित न होने दें - अभी अपनी अपॉइंटमेंट बुक करें और स्वस्थ, खुशहाल जीवन की ओर पहला कदम उठाएं।",
                  path: "/programs/pcod-management-program",
                  ImageUrl: PCODPng,
                },
                {
                  id: 4,
                  title: "मोटापा",
                  desc: "मोटापे से जूझ रहे हैं? क्या अधिक वजन आपके जीवन की गुणवत्ता को प्रभावित कर रहा है? NDHealth.ai पर, हमारे विशेषज्ञ मोटापा प्रबंधन के लिए समर्पित हैं। मोटापे को अपने ऊपर हावी न होने दें - अभी अपना अपॉइंटमेंट बुक करें और स्वस्थ, खुशहाल जीवन की ओर पहला कदम उठाएं।",
                  path: "/programs/obesity-management-program",
                  ImageUrl: ObesityPng,
                },
                {
                  id: 5,
                  title: "सोरायसिस",
                  desc: "सोरायसिस के लक्षणों से निपटना? क्या लाल, सूजी हुई त्वचा के धब्बों की परेशानी आपके दैनिक जीवन को प्रभावित कर रही है? NDHealth.ai सोरायसिस प्रबंधन में माहिर है। सोरायसिस को अपने आप में बाधा न बनने दें - अभी अपनी नियुक्ति निर्धारित करें और एक स्वस्थ, खुशहाल जीवन की यात्रा पर निकल पड़ें।",
                  path: "/programs/psoriasis-treatment-program",
                  ImageUrl: PsoriasisImg,
                },
                {
                  id: 6,
                  title: "बुढ़ापा विरोधी",
                  desc: "बढ़ती उम्र के लक्षणों के बारे में चिंतित हैं? क्या झुर्रियाँ, महीन रेखाएँ या जीवन शक्ति की हानि आपके आत्मविश्वास को प्रभावित कर रही है? NDHealth.ai विशेष एंटी-एजिंग समाधान प्रदान करता है। हमारे विशेषज्ञों की टीम आपको उम्र बढ़ने के प्रभावों से बचने में मदद करने के लिए समर्पित है। उम्र को खुद को परिभाषित न करने दें - अभी अपनी नियुक्ति निर्धारित करें और अधिक युवा और जीवंत बनने की दिशा में अपनी यात्रा शुरू करें।",
                  path: "/programs/anti-aging-program",
                  ImageUrl: AntiAgingImg,
                },
                {
                  id: 7,
                  title: "NAFLD",
                  desc: "NAFLD लक्षणों से जूझ रहे हैं? क्या लीवर संबंधी समस्याएं, थकान, वजन बढ़ना या पेट की परेशानी आपकी सेहत को प्रभावित कर रही है? NDHealth.ai पर, हमारे विशेषज्ञ NAFLD प्रबंधन के लिए समर्पित हैं। NAFLD को अपने आप को सीमित न करने दें - अभी अपनी नियुक्ति बुक करें और स्वस्थ, खुशहाल जीवन की ओर पहला कदम उठाएं",
                  path: "/programs/nafld-program",
                  ImageUrl: NAFLDImg,
                },
              ],

              bookNowBtn: "अभी बुक करें",
            },
            dysfunction: {
              title:
                "अक्रियाशील चयापचय - क्रोनिक चयापचय रोग के पीछे मुख्य अपराधी!",
              miniTitle1:
                "पुरानी चयापचय संबंधी बीमारियों के पीछे अंतर्निहित मुद्दा:",
              miniDesc1:
                "पोषण, नींद, गतिविधि और तनाव जैसे बाहरी कारकों से उत्पन्न एक निष्क्रिय चयापचय प्रक्रिया से उत्पन्न होता है, जो जीवन के लिए महत्वपूर्ण आवश्यक रासायनिक प्रक्रियाओं को बाधित करता है, जिससे हस्तक्षेप करने के लिए एआई-संचालित स्वास्थ्य समाधानों की नींव बनती है। और पुरानी चयापचय स्थितियों के विकास को कम करें।",
              miniTitle2:
                "मूल कारण को संबोधित करना - चुनौतियों पर काबू पाने की कुंजी",
              miniDesc2:
                "पुरानी चयापचय संबंधी बीमारियों को दूर करने के लिए, होल-बॉडी डिजिटल NDHealth पोषण, नींद, गतिविधि और तनाव जैसे बाहरी कारकों को बढ़ाने, इष्टतम शारीरिक कार्य को बहाल करने और उलटने के लिए ऑनलाइन स्वास्थ्य परामर्श सहित लक्षित मार्गदर्शन प्रदान करता है। अंगों और चयापचय संबंधी विकारों पर नकारात्मक प्रभाव।",
              miniTitle3: "व्यक्तित्व को पहचानने की शक्ति को अनलॉक करना",
              miniDesc3:
                "सर्वोत्तम स्वास्थ्य समाधान प्राप्त करने के लिए प्रत्येक व्यक्ति का चयापचय महत्वपूर्ण है। चिकित्सा अनुसंधान इस बात पर प्रकाश डालता है कि चयापचय कैसे कार्य करता है, जिससे उपचार को प्रभावी ढंग से तैयार करने में चुनौतियाँ आती हैं। होल-बॉडी डिजिटल NDHealth प्रत्येक सदस्य के विशिष्ट चयापचय के लिए अनुकूलित सटीक मार्गदर्शन प्रदान करता है, जिससे बेहतर स्वास्थ्य परिणामों के लिए व्यक्तिगत समर्थन सुनिश्चित होता है।",
              knownBtn: "अधिक जानते हैं",
            },
            benefits: {
              benefTitle:
                "NDHealth जैसे एआई-संचालित स्वास्थ्य समाधान चुनने के लाभ",
              para1: "इंसुलिन सहित महंगी दवाएं कम करें",

              para2: "ऊर्जा बढ़ाएँ, मूड में सुधार करें और लक्षणों में कमी लाएँ",

              para3:
                "आपके रक्त शर्करा को सामान्य करने में मदद करने के लिए आपका यापचय",

              para4: "दूरस्थ रोगी निगरानी के लिए वास्तविक समय डेटा तक पहुंचें",

              para5:
                "अत्याधुनिक एआई हेल्थकेयर तकनीक द्वारा समर्थित सिद्ध परिणाम",
            },
          },
          diabetes: {
            title: "मधुमेह उत्क्रमण कार्यक्रम",
            mainDesc:
              "क्या आप अपने मधुमेह पर नियंत्रण पाने और अपना जीवन बदलने के लिए तैयार हैं? NDHealth ने AI प्रौद्योगिकी की शक्ति का उपयोग करते हुए एक अभूतपूर्व मधुमेह रिवर्सल कार्यक्रम पेश किया है। हमारा नवोन्वेषी दृष्टिकोण आपको मधुमेह को उलटने और अपने स्वास्थ्य को पहले की तरह पुनः प्राप्त करने में मदद करने के लिए डिज़ाइन किया गया है।",
            contactBtn: "संपर्क करें",

            Transform: {
              subHeading1: "NDHealth के साथ स्वास्थ्य में बदलाव",
              subPara1:
                "होल-बॉडी डिजिटल आपके चयापचय का एक आभासी प्रतिनिधित्व बनाने के लिए एआई हेल्थकेयर समाधानों को शामिल करता है, जो उपयोगकर्ता के अनुकूल ऐप इंटरफेस के माध्यम से पोषण, नींद, व्यायाम और सांस लेने पर व्यक्तिगत सलाह प्रदान करता है।            ",
              subHeading2: "NdHealth का एकीकृत कार्यक्रम ",
              subPara2:
                "NdHealth का व्यापक कार्यक्रम नवीन स्वास्थ्य सेवाओं को एकीकृत करता है, देखभाल करने वालों की एक समर्पित टीम के साथ होल-बॉडी डिजिटल NdHealth की क्षमताओं को जोड़ता है जो आपके सेंसर डेटा की देखरेख करते हैं, व्यक्तिगत सिफारिशें प्रदान करते हैं, और आपकी स्वास्थ्य यात्रा में आपकी सहायता करते हैं।            ",
              subHeading3:
                "NdHealth AI के साथ क्रांतिकारी मधुमेह प्रबंधन            ",
              subPara3:
                "एक क्रांतिकारी कार्यक्रम का अनुभव करें जो मधुमेह से पीड़ित व्यक्तियों के लिए एक परिवर्तनकारी समाधान प्रदान करता है। NdHealth, एक अभिनव कृत्रिम बुद्धिमत्ता-आधारित प्रणाली, आपके शरीर की जटिलताओं का विश्लेषण और समझने के लिए एक व्यक्तिगत बॉडी डबल बनाती है, जो एक व्यापक दृष्टिकोण में सर्वोत्तम स्वास्थ्य समाधान प्रदान करती है।",
              subHeading4: "आस-पास के शीर्ष डॉक्टरों को खोजें",
              subPara4:
                "एक उपयोगकर्ता-अनुकूल एप्लिकेशन के माध्यम से अपने आस-पास के सर्वश्रेष्ठ डॉक्टरों को ढूंढें जो मधुमेह को उलटने के लिए एक संतुलित और अनुरूप दृष्टिकोण सुनिश्चित करता है। आधुनिक तकनीक की शक्ति को अपनाएं, जिसे कई लोग अभी तक उपयोग नहीं कर पाए हैं और चार महीने की उल्लेखनीय समय सीमा के भीतर मधुमेह को उलटने की क्षमता देख सकते हैं।",
            },

            Results: {
              header: "परिणाम आप देख और महसूस कर सकते हैं",
              subHeader: "लोगों को मधुमेह को उलटने में मदद करना",
              DiabetesResults: [
                {
                  id: 1,
                  imgUrl: diabetesPng,
                  heading: "1.9%",
                  subHeading: "औसत HbA1c गिरावट",
                },
                {
                  id: 2,
                  imgUrl: medicationPng,
                  heading: "90%",
                  subHeading: "दवा कम कर दी है या ख़त्म कर दी है",
                },
                {
                  id: 3,
                  imgUrl: insulinPng,
                  heading: "92%",
                  subHeading: "इंसुलिन कम हो गया है या ख़त्म हो गया है",
                },
                {
                  id: 4,
                  imgUrl: sugarLevelPng,
                  heading: "90%",
                  subHeading: "शुगर लेवल में कमी देखी गई है",
                },
                {
                  id: 5,
                  imgUrl: weightPng,
                  heading: "3+ Kg",
                  subHeading: "महीनों के भीतर औसत वजन-घटना जारी है",
                },
              ],
            },

            Plan: {
              header: "योजना में क्या है?",
              subHeader1: "सतत ग्लूकोज मॉनिटर",
              planSubPara1:
                "ऐसी तकनीक जो विश्लेषण के लिए वास्तविक समय में रक्त शर्करा डेटा एकत्र करती है।",
              subHeader2: "ऑन-डिमांड डॉक्टर पर्यवेक्षण",
              planSubPara2: "अब एक-से-एक बार की नियुक्तियाँ नहीं!",
              subHeader3: "समर्पित मधुमेह विशेषज्ञ",
              planSubPara3:
                "दैनिक सहायता और प्रश्नों के उत्तर बस एक व्हाट्सएप संदेश दूर हैं!",
              subHeader4: "व्यापक नैदानिक ​​परीक्षण",
              planSubPara4:
                "ऑनबोर्डिंग पर 70+ पैरामीटर रक्त और मूत्र जांच और हर तिमाही में मधुमेह स्क्रीनिंग परीक्षण।",
              subHeader5: "वैयक्तिकृत योजनाएँ",
              planSubPara5:
                "व्यक्तिगत और पालन करने में आसान भोजन, तनाव और नींद की योजनाएँ।",
            },

            enquiry: "अब पूछताछ करें",
            association: "के सहयोग से",
          },
          DyslipedimiaProgram: {
            dysTitle: "डिसलिपिडेमिया",
            dysDesc:
              "डिस्लिपिडेमिया के बारे में चिंतित हैं? आप अकेले नहीं हैं। यह सामान्य स्थिति दुनिया भर में लाखों लोगों को प्रभावित करती है। लेकिन एआई द्वारा संचालित NDHealth के साथ, डिस्लिपिडेमिया के प्रबंधन के लिए एक व्यक्तिगत दृष्टिकोण की खोज करें। हमारा अभिनव कार्यक्रम बेहतर स्वास्थ्य परिणामों के लिए अनुरूप मार्गदर्शन और समर्थन प्रदान करने के लिए अत्याधुनिक तकनीक को एकीकृत करता है।",

            dysHeader1: "NdHealth का डिजिटल समाधान",
            dysPara1:
              "डिस्लिपिडेमिया से पीड़ित व्यक्तियों के लिए एक अत्याधुनिक समाधान पेश करते हुए, होल-बॉडी डिजिटल NdHealth आपके अद्वितीय शारीरिक प्रोफ़ाइल का एक आभासी प्रतिनिधित्व है। एक सहज ज्ञान युक्त ऐप इंटरफ़ेस में सहजता से एकीकृत, यह अभिनव स्वास्थ्य देखभाल एआई व्यक्तिगत पोषण, नींद, व्यायाम और साँस लेने का मार्गदर्शन प्रदान करता है। देखभाल करने वालों की एक समर्पित टीम के साथ साझेदारी में, NdHealth का समावेशी कार्यक्रम आपके सेंसर डेटा की निगरानी करता है, अनुरूप सुझाव प्रदान करता है, और बेहतर स्वास्थ्य की दिशा में आपकी यात्रा में आपका समर्थन करता है।",
            dysHeader2: "क्रांतिकारी डिसलिपिडेमिया उपचार ",
            dysPara2:
              "इसे अभूतपूर्व सटीकता के साथ संबोधित करने के लिए डिज़ाइन किए गए एक क्रांतिकारी कार्यक्रम के साथ सर्वोत्तम डिस्लिपिडेमिया उपचार का अनुभव करें। अत्याधुनिक तकनीक का लाभ उठाकर, NdHealth एक समग्र दृष्टिकोण प्रदान करता है जो आक्रामक उपचार और दवाओं की आवश्यकता को समाप्त करता है। एक कृत्रिम बुद्धिमत्ता-संचालित प्रणाली के माध्यम से, आपके शरीर विज्ञान की जटिलताओं का व्यापक रूप से विश्लेषण करने के लिए एक व्यक्तिगत शरीर मॉडल का निर्माण किया जाता है।",
            dysHeader3:
              "हमारे उपयोगकर्ता-अनुकूल डिस्लिपिडेमिया के साथ अपनी स्वास्थ्य क्षमता को अनलॉक करें",
            dysPara3:
              "उपयोगकर्ता के अनुकूल एप्लिकेशन के माध्यम से सुलभ, यह कार्यक्रम डिस्लिपिडेमिया के प्रबंधन के लिए एक संतुलित और व्यक्तिगत दृष्टिकोण सुनिश्चित करता है। आधुनिक प्रौद्योगिकी की क्षमता को अपनाएं, जिसका अक्सर कई लोग कम उपयोग करते हैं, और इसका गवाह बनें",
            dysHeader4: "कारण एवं प्रबंधन",
            dysPara4:
              "डिस्लिपिडेमिया एक पुरानी स्थिति है जिसे ठीक नहीं किया जा सकता है। हालाँकि, जीवनशैली में संशोधन, दवाओं और लक्षित हस्तक्षेपों के माध्यम से कुछ लक्षणों में सुधार किया जा सकता है। जबकि डिस्लिपिडेमिया का सटीक कारण अज्ञात है, पारिवारिक इतिहास वाले या टाइप 2 मधुमेह से पीड़ित लोगों को अधिक खतरा होता है।",

            Causes: {
              causeHeader: "कारण क्या हैं और जोखिम में कौन है?",
              causeSubHeader:
                "कई व्यवहार डिस्लिपिडेमिया का कारण बन सकते हैं। वे सम्मिलित करते हैं:",
              DyslipedimiaCauses: [
                {
                  id: 1,
                  title: "सिगरेट पीना।",
                  ImageUrl: smokingPng,
                },
                {
                  id: 2,
                  title: "मोटापा और गतिहीन जीवनशैली",
                  ImageUrl: obesityPng,
                },
                {
                  id: 3,
                  title:
                    "संतृप्त वसा और ट्रांस वसा से भरपूर खाद्य पदार्थों का सेवन ",
                  ImageUrl: excessiveFoodPng,
                },
                {
                  id: 4,
                  title:
                    "अत्यधिक शराब का सेवन भी ट्राइग्लिसराइड के उच्च स्तर में योगदान कर सकता है।",
                  ImageUrl: alocoholPng,
                },
              ],
            },
            Treatment: {
              treatHeader: "उपचार का विकल्प",
              treatPara1:
                "डिस्लिपिडेमिया के इलाज के लिए सबसे अधिक इस्तेमाल की जाने वाली दवा स्टैटिन है। स्टैटिन लीवर में कोलेस्ट्रॉल उत्पादन में हस्तक्षेप करके एलडीएल स्तर को कम करने में मदद करते हैं। यहां इस बारे में अधिक जानकारी दी गई है कि स्टैटिन कैसे काम करते हैं।",
              treatPara2:
                "स्टैटिन कई प्रकार के होते हैं। वे सभी थोड़ा अलग तरीके से काम करते हैं, कुछ दूसरों की तुलना में अधिक मजबूत होते हैं। ",
              treatPara3:
                "आपका डॉक्टर अन्य कोलेस्ट्रॉल दवाएं भी लिख सकता है। इन्हें स्टैटिन के अतिरिक्त या स्टैटिन के स्थान पर लिया जा सकता है। कोलेस्ट्रॉल नियंत्रित करने वाली दवाओं के बीच चयन करते समय विचार करने के लिए कई फायदे और नुकसान हैं।",
            },

            non_statinDrugs: {
              drugHeader: "इन गैर-स्टेटिन दवाओं में शामिल हैं",
              drug1: "इज़ेटिमिब (ज़ेटिया)",
              drug2: "फाइब्रेट्स, जैसे फेनोफाइब्रेट (फेनो ग्लाइड) ",
              drug3: "PCSK9 अवरोधक",
            },

            dysProgram:
              "हमारे अत्यधिक अनुशंसित NdHealth रिवर्सल प्रोग्राम के साथ डिस्लिपिडेमिया से राहत का अनुभव करें। ",
          },
          Pcod: {
            pcodTitle: "PCOD रिवर्सल प्रोग्राम ",
            pcodDesc:
              "PCOD से जूझ रहे हैं? वैयक्तिकृत सहायता के लिए हमसे जुड़ें। हमारी टीम पीसीओडी को प्रभावी ढंग से प्रबंधित करने के लिए मार्गदर्शन और समाधान प्रदान करती है। ",
            Comprehensive: {
              compHeader: "NDHealth के साथ PCOD के लिए एक व्यापक मार्गदर्शिका ",
              compSubHeader: "महिला स्वास्थ्य को सशक्त बनाना ",
              pcodSectionTitle1: "अवलोकन",
              pcodSectionDesc1:
                "Polycystic ovary syndrome (PCOS) एक सामान्य हार्मोनल स्थिति है जो प्रजनन आयु की महिलाओं को प्रभावित करती है। यह आमतौर पर किशोरावस्था के दौरान शुरू होता है, लेकिन समय के साथ लक्षणों में उतार-चढ़ाव हो सकता है। पीसीओएस हार्मोनल असंतुलन, अनियमित मासिक धर्म, अतिरिक्त एण्ड्रोजन स्तर और अंडाशय में सिस्ट का कारण बन सकता है। अनियमित मासिक धर्म, आमतौर पर ओव्यूलेशन की कमी के साथ, गर्भवती होना मुश्किल हो सकता है। पीसीओएस बांझपन का एक प्रमुख कारण है। ",
              pcodSectionTitle2: "समस्या का दायरा ",
              pcodSectionDesc2:
                "PCOS एक महत्वपूर्ण सार्वजनिक स्वास्थ्य समस्या है और प्रजनन आयु की महिलाओं को प्रभावित करने वाली सामान्य हार्मोनल गड़बड़ी में से एक है। यह स्थिति अनुमानित रूप से प्रजनन आयु की 8-13% महिलाओं को प्रभावित करती है, और 70% मामलों का निदान नहीं किया जाता है। पीसीओएस का प्रचलन कुछ जातियों में अधिक है और ये समूह अक्सर अधिक जटिलताओं का अनुभव करते हैं, विशेष रूप से चयापचय समस्याओं से संबंधित।",
              pcodSectionTitle3: "लक्षण",
              pcodSectionDesc3: {
                pcodSection3Main:
                  "पॉलीसिस्टिक ओवरी सिंड्रोम के लक्षण हर व्यक्ति में अलग-अलग हो सकते हैं। लक्षण समय के साथ बदल सकते हैं और अक्सर बिना किसी स्पष्ट कारण के होते हैं। संभावित लक्षणों में शामिल हैं:",
                pcodSection3Sub1:
                  "• भारी, लंबी, रुक-रुक कर, अप्रत्याशित या अनुपस्थित अवधि",
                pcodSection3Sub2: "• बांझपन",
                pcodSection3Sub3: "• मुँहासे या तैलीय त्वचा",
                pcodSection3Sub4: "• चेहरे या शरीर पर अत्यधिक बाल होना",
                pcodSection3Sub5: "• वजन बढ़ना, खासकर पेट के आसपास। ",
              },
              pcodSectionTitle4: "निदान",
              pcodSectionDesc4: {
                pcodSection4Main:
                  "पॉलीसिस्टिक ओवरी सिंड्रोम का निदान निम्नलिखित में से कम से कम दो की उपस्थिति से किया जाता है:",
                pcodSection4Sub1:
                  "1. उच्च एण्ड्रोजन के संकेत या लक्षण (चेहरे या शरीर पर अनचाहे बाल, सिर से बालों का झड़ना, मुंहासे या रक्त में टेस्टोस्टेरोन का ऊंचा स्तर) - इसके अन्य कारणों को बाहर करने के बाद;",
                pcodSection4Sub2:
                  " 2. अनियमित या अनुपस्थित मासिक धर्म - इसके अन्य कारणों को बाहर करने के बाद; और",
                pcodSection4Sub3:
                  " 3. अल्ट्रासाउंड स्कैन पर पॉलीसिस्टिक अंडाशय।",
              },
            },
            KeyFacts: {
              keyFactsTitle: "महत्वपूर्ण तथ्यों",
              keyFactsPara1:
                "पीसीओएस एनोव्यूलेशन का सबसे आम कारण है और महिलाओं में बांझपन का प्रमुख कारण है।",
              keyFactsPara2:
                "इसकी व्यापकता के बावजूद, विश्व स्तर पर पीसीओएस से पीड़ित 70% महिलाओं का निदान नहीं हो पाता है, जो बढ़ती जागरूकता और स्क्रीनिंग की आवश्यकता पर प्रकाश डालता है। ",
              keyFactsPara3:
                "पीसीओएस प्रजनन आयु की अनुमानित 8-13% महिलाओं को प्रभावित करता है, जिससे यह एक महत्वपूर्ण स्वास्थ्य चिंता बन जाती है ",
              keyFactsPara4:
                "प्रजनन संबंधी मुद्दों से परे, पीसीओएस कई दीर्घकालिक स्वास्थ्य समस्याओं से जुड़ा है जो शारीरिक और भावनात्मक कल्याण को प्रभावित कर सकता है, जो शीघ्र निदान और प्रबंधन के महत्व को रेखांकित करता है। ",
              keyFactsPara5:
                "जबकि पीसीओएस परिवारों में चलता है, इसकी अभिव्यक्तियाँ और प्रभाव विभिन्न जातीय समूहों में भिन्न हो सकते हैं, जो आनुवंशिक और पर्यावरणीय कारकों के प्रभाव का सुझाव देते हैं।",
            },
          },
          obesity: {
            obTitle: "हमारे वज़न घटाने के कार्यक्रम में आपका स्वागत है",
            obDesc:
              "क्या आप वजन घटाने की जद्दोजहद से तंग आ चुके हैं? क्या आपको ऐसा लगता है कि आपने हर आहार और व्यायाम को न्यूनतम सफलता के साथ आज़माया है? अब एक क्रांतिकारी दृष्टिकोण पर विचार करने का समय आ गया है: artificial intelligence (AI) द्वारा संचालित मेटाबोलिक रिवर्सल। ",
            metabTitle: "मेटाबॉलिक रिवर्सल क्या है? ",
            metabDesc:
              "मेटाबॉलिक रिवर्सल का मतलब केवल पाउंड कम करना नहीं है; यह वजन बढ़ने और चयापचय संबंधी शिथिलता के मूल कारण को संबोधित करने के बारे में है। इंसुलिन प्रतिरोध, हार्मोनल असंतुलन और सूजन जैसे कारकों को लक्षित करके, मेटाबोलिक रिवर्सल का उद्देश्य आपके शरीर की वसा को कुशलता से जलाने की प्राकृतिक क्षमता को बहाल करना है।",
            aiTitle: "AI कैसे मदद कर सकता है ",
            aiDesc:
              "हमारा वजन घटाने का कार्यक्रम मेटाबॉलिक रिवर्सल की आपकी यात्रा को निजीकृत करने के लिए एआई की शक्ति का उपयोग करता है। उन्नत एल्गोरिदम का उपयोग करते हुए, हम आपके लिए विशेष रूप से काम करने वाली योजना तैयार करने के लिए आपकी अद्वितीय आनुवंशिक संरचना, जीवनशैली की आदतों, चिकित्सा इतिहास और आहार संबंधी प्राथमिकताओं का विश्लेषण करते हैं। ",
            personalTitle: "वैयक्तिकृत पोषण",
            personalDesc:
              "सभी के लिए एक जैसी भोजन योजना को अलविदा कहें। हमारा एआई-संचालित प्लेटफॉर्म आपकी व्यक्तिगत जरूरतों और लक्ष्यों के आधार पर कस्टम पोषण योजनाएं डिजाइन करता है। चाहे आप रक्त शर्करा के स्तर को नियंत्रित करना चाहते हों, सूजन को कम करना चाहते हों, या हार्मोन संतुलन को अनुकूलित करना चाहते हों, हम एक स्थायी भोजन योजना बनाएंगे जो आपके शरीर को पोषण देगी और चयापचय स्वास्थ्य को बढ़ावा देगी। ",
            realtimeTitle: "वास्तविक समय की निगरानी और समर्थन ",
            realtimeDesc:
              "हमारे एआई-संचालित प्लेटफॉर्म के साथ, आपको वास्तविक समय पर प्रतिक्रिया प्राप्त होगी और हर कदम पर समर्थन मिलेगा। अपनी प्रगति को ट्रैक करें, शरीर की संरचना और रक्त मार्करों जैसे प्रमुख मैट्रिक्स की निगरानी करें, और आपको प्रेरित रखने और अपने वजन घटाने के लक्ष्यों की ओर ट्रैक पर रखने के लिए वैयक्तिकृत अंतर्दृष्टि प्राप्त करें। ",
            intelligentTitle: "बुद्धिमान व्यायाम मार्गदर्शन ",
            intelligentDesc:
              "व्यायाम मेटाबोलिक रिवर्सल का एक महत्वपूर्ण घटक है, लेकिन सभी वर्कआउट समान नहीं बनाए जाते हैं। हमारा एआई सिस्टम वैयक्तिकृत व्यायाम दिनचर्या तैयार करता है जो आपके फिटनेस स्तर, प्राथमिकताओं और किसी भी अंतर्निहित स्वास्थ्य स्थितियों को ध्यान में रखता है। चाहे आप उच्च तीव्रता अंतराल प्रशिक्षण, शक्ति प्रशिक्षण, या योग पसंद करते हैं, हम आपको ऐसे वर्कआउट के माध्यम से मार्गदर्शन करेंगे जो वसा जलने और चयापचय दक्षता को अधिकतम करते हैं। ",
            joinTitle: "आज ही हमारे कार्यक्रम में शामिल हों ",
            joinDesc:
              "हताशा और निराशा को अब अपने ऊपर हावी न होने दें। हमारे एआई-संचालित वजन घटाने कार्यक्रम के साथ चयापचय उत्क्रमण की शक्ति को अपनाएं। क्रैश डाइट और जिम में अंतहीन घंटों को अलविदा कहें - स्थायी वजन घटाने और बेहतर चयापचय स्वास्थ्य का अनुभव करें जैसा पहले कभी नहीं हुआ। ",
            obessignupMsg:
              "अभी साइन अप करें और स्वस्थ, खुशहाल की ओर पहला कदम उठाएं! ",
          },
          psoriasis: {
            psoTitle: "सोरायसिस उपचार से साफ़ त्वचा ",
            psoDesc:
              "सोरायसिस आपकी सेहत पर भारी असर डाल सकता है। यहां एनडी हेल्थ में, हम भारत में सोरायसिस उपचार के लिए एक क्रांतिकारी दृष्टिकोण पेश करने के लिए आर्टिफिशियल इंटेलिजेंस (एआई) की शक्ति का लाभ उठाते हैं। ",

            psoIntroTitle: {
              psoIntroSubHead1: "NdHealth Technology ",
              psoIntroSubHead2: "का परिचय ",
            },
            psoIntroDesc:
              "एक आभासी स्वास्थ्य साथी की कल्पना करें जो पूरी तरह से आपकी अनूठी स्थिति को समझने के लिए समर्पित है। वह एनडी हेल्थ है। यह अत्याधुनिक प्लेटफ़ॉर्म आपके मेडिकल इतिहास और वास्तविक समय डेटा (पहनने योग्य वस्तुओं और परीक्षाओं से) का विश्लेषण करता है, और आपके स्वास्थ्य का डिजिटल ट्विन बनाने के लिए एआई एल्गोरिदम का उपयोग करता है।",

            psoChoose: {
              psoChooseTitle: {
                psoChooseTitle1: "एनडी हेल्थ",
                psoChooseTitle2: " क्यों चुनें?",
              },
              psoChooseSubHead1: "जेनेरिक समाधानों से परे ",

              psoChooseSubPara1:
                "एक आकार-सभी के लिए उपयुक्त दृष्टिकोण को त्यागें। ND Health आपकी विशिष्ट आवश्यकताओं के आधार पर आपकी सोरायसिस उपचार योजना को वैयक्तिकृत करता है।",
              psoChooseSubHead2: "सक्रिय प्रबंधन",

              psoChooseSubPara2:
                "अपने ND Health से निरंतर निगरानी और वैयक्तिकृत अनुशंसाओं के साथ प्रकोप से आगे रहें ",
              psoChooseSubHead3: "डेटा-संचालित अंतर्दृष्टि ",

              psoChooseSubPara3:
                "एआई-संचालित विश्लेषण से अपने सोरायसिस को समझें। अपने उपचार पथ के बारे में सोच-समझकर निर्णय लें ",
              psoChooseSubHead4: "ज्ञान के माध्यम से सशक्तिकरण  ",

              psoChooseSubPara4:
                "अपनी स्थिति की गहरी समझ हासिल करें और इसे प्रभावी ढंग से कैसे प्रबंधित करें।",
              psoChooseSubHead5: "अधिक आरामदायक जीवन जियें ",

              psoChooseSubPara5:
                "हमारा लक्ष्य आपको स्थायी राहत प्राप्त करने और आपके समग्र कल्याण में सुधार करने में मदद करना है। ",
            },

            psoEmbrace: {
              psoEmbraceTitle: {
                psoEmbraceTitle1: "सोरायसिस उपचार के",
                psoEmbraceTitle2: "भविष्य ",
                psoEmbraceTitle3: "को अपनाएं",
              },
              psoEmbraceDesc:
                "केवल लक्षणों को प्रबंधित करने तक ही सीमित न रहें। ND Health एआई में, हम मूल कारण खोजने और एक व्यक्तिगत उपचार योजना विकसित करने में विश्वास करते हैं। आज ही ND Health से संपर्क करें और साफ त्वचा और स्वस्थ रहने के लिए एआई की शक्ति को अनलॉक करें! ",
            },
          },

          antiAging: {
            antiTitle: "मेटाबॉलिक एज रिवर्सल के साथ जीवन में बदलाव ",
            antiDesc:
              " ND Health में, हम उम्र को मात देने और मेटाबोलिक एज रिवर्सल की शक्ति के माध्यम से आपके स्वास्थ्य को पुनर्जीवित करने में आपकी मदद करने के लिए समर्पित हैं। थकान महसूस करने को अलविदा कहें और जीवंत, ऊर्जावान आपको नमस्कार!",
            antiMetabTitle: "मेटाबॉलिक एज रिवर्सल क्या है?",
            antiMetabDesc:
              "मेटाबॉलिक उम्र में बदलाव का मतलब समय को पीछे मोड़ना नहीं है; यह आपके सर्वोत्तम महसूस करने और दिखने के लिए आपके शरीर के चयापचय को अनुकूलित करने के बारे में है। यह आपके स्वस्थ, अधिक युवा होने की दिशा में वैज्ञानिक रूप से सिद्ध यात्रा है। ",
            approach: {
              appraochMainTitle: "हमारा दृष्टिकोण",

              OurApproach: [
                {
                  id: 1,
                  title: "वैयक्तिकृत पोषण योजनाएँ",
                  description:
                    "यहां कोई कुकी-कटर आहार नहीं है। हम आपके शरीर को ऊर्जा देने और आपके चयापचय को सक्रिय करने के लिए अनुरूप पोषण योजनाएं बनाते हैं  ",
                  imageUrl: NutritonImg,
                },
                {
                  id: 2,
                  title: "कस्टम फिटनेस कार्यक्रम ",
                  description:
                    "ऐसे वर्कआउट को नमस्कार कहें जो चयापचय को बढ़ावा देते हैं और आपके शरीर को सुडौल बनाते हैं। हमारे प्रशिक्षक सिर्फ आपके लिए दिनचर्या तैयार करते हैं।",
                  imageUrl: FitnessImg,
                },
                {
                  id: 3,
                  title: "नींद अनुकूलन",
                  description:
                    "गुणवत्तापूर्ण नींद स्वस्थ चयापचय की कुंजी है। अधिकतम कायाकल्प के लिए अपने आराम को बेहतर बनाने की रणनीतियों की खोज करें।",
                  imageUrl: SleepImg,
                },
                {
                  id: 4,
                  title: "तनाव प्रबंधन",
                  description:
                    "तनाव आपके चयापचय पर कहर बरपा सकता है। तनाव को नियंत्रण में रखने और अपने चयापचय को सक्रिय रखने की तकनीकें सीखें। ",
                  imageUrl: StressImg,
                },
              ],
            },

            appBenefits: {
              appBenefitsMainTitle: "मेटाबॉलिक एज रिवर्सल के लाभ ",
              BenefitsOfAgeReversal: [
                {
                  id: 1,
                  title: "ऊर्जा का स्तर बढ़ाया",
                  description:
                    "तरोताजा महसूस करते हुए जागें और अपना दिन जीतने के लिए तैयार रहें।",
                  imageUrl: BoostedImg,
                },
                {
                  id: 2,
                  title: "प्रभावी वजन प्रबंधन",
                  description:
                    "जैसे ही आपका चयापचय वसा जलाने वाली मशीन बन जाता है, अवांछित पाउंड कम हो जाते हैं।",
                  imageUrl: WeightManagementImg,
                },
                {
                  id: 3,
                  title: "उन्नत मानसिक स्पष्टता",
                  description:
                    "स्वस्थ चयापचय का अर्थ है स्वस्थ दिमाग। फोकस और संज्ञानात्मक कार्य में सुधार करें। ",
                  imageUrl: YogaImg,
                },
                {
                  id: 4,
                  title: "दीप्तिमान जीवन शक्ति ",
                  description:
                    "अंदर और बाहर जीवन शक्ति से चमकें, चयापचय के लिए धन्यवाद जो समग्र कल्याण का समर्थन करता है।",
                  imageUrl: RadiantImg,
                },
              ],
            },

            appNdHealth: {
              appNdHealthMainTittle: "ND Health क्यों चुनें? ",
              ChooseNdHealth: [
                {
                  id: 1,
                  title: "साक्ष्य-आधारित दृष्टिकोण ",
                  description:
                    "हमारे तरीके चयापचय स्वास्थ्य और दीर्घायु पर नवीनतम वैज्ञानिक अनुसंधान में निहित हैं।",
                  imageUrl: EvidenceImg,
                },
                {
                  id: 2,
                  title: "विशेषज्ञ मार्गदर्शन ",
                  description:
                    "अनुभवी स्वास्थ्य पेशेवरों की हमारी टीम के साथ काम करें जो आपकी सफलता के लिए प्रतिबद्ध हैं।",
                  imageUrl: ExpertImg,
                },
                {
                  id: 3,
                  title: "वास्तविक परिवर",
                  description:
                    "हमारे ग्राहकों के समुदाय से सुनें, जिन्होंने मेटाबॉलिक एज रिवर्सल के माध्यम से अपने स्वास्थ्य को पुनः प्राप्त कर लिया है। ",
                  imageUrl: TransformedImg,
                },

                {
                  id: 4,
                  title: "समग्र समाधान ",
                  description:
                    "एक समग्र दृष्टिकोण अपनाएं जो आपके शरीर की उपचार करने और पनपने की प्राकृतिक क्षमता का पोषण करता है।",
                  imageUrl: HolisticImg,
                },
              ],
            },
            appEndingPara:
              "किसी भी विवरण को समायोजित करने या ND Health's की अनूठी पेशकशों और ब्रांड की आवाज के अनुरूप विवरण जोड़ने के लिए स्वतंत्र महसूस करें!",
          },
          Nafld: {
            nafldMainTittle: "गैर-अल्कोहलिक फैटी लीवर रोग का उपचार",
            nafldDesc:
              "क्या आप नॉन-अल्कोहलिक फैटी लीवर रोग (NAFLD) के बारे में चिंतित हैं? आप अकेले नहीं हैं। भारत में लाखों लोग इस मूक स्वास्थ्य चिंता का सामना करते हैं। लेकिन एक अच्छी खबर है! अत्याधुनिक आर्टिफिशियल इंटेलिजेंस (AI) द्वारा संचालित एनडी हेल्थ, व्यक्तिगत एनएएफएलडी उपचार के लिए एक क्रांतिकारी दृष्टिकोण प्रदान करता है।",

            nafldSubhead: "NAFLD क्या है",
            nafldSubPara:
              "NAFLD एक प्रचलित लीवर की स्थिति है जिसमें लीवर कोशिकाओं में वसा का संचय होता है। शराबी जिगर की बीमारी के विपरीत, एनएएफएलडी उन व्यक्तियों में होता है जो शराब का बहुत कम या बिल्कुल भी सेवन नहीं करते हैं। यह अक्सर मोटापे, इंसुलिन प्रतिरोध और मेटाबोलिक सिंड्रोम से जुड़ा होता है। ",
            nafldCauses: {
              nafldCausesTittle: "NAFLD के कारण ",
              CauseOfNafld: [
                {
                  id: 1,
                  title: "मोटापा",
                  description:
                    "शरीर का अतिरिक्त वजन, विशेषकर पेट का मोटापा, एनएएफएलडी विकसित होने का खतरा बढ़ाता है। ",
                  imageUrl: ObesityImg,
                },
                {
                  id: 2,
                  title: "इंसुलिन प्रतिरोध ",
                  description:
                    "इंसुलिन प्रतिरोध, जो अक्सर टाइप 2 मधुमेह से जुड़ा होता है, शरीर में शर्करा और वसा को संसाधित करने के तरीके में हस्तक्षेप करता है, जिससे यकृत में वसा जमा हो जाती है। ",
                  imageUrl: InsulinImg,
                },
                {
                  id: 3,
                  title: "चयापचयी लक्षण  ",
                  description:
                    "NAFLD मेटाबॉलिक सिंड्रोम से निकटता से जुड़ा हुआ है, जो उच्च रक्तचाप, उच्च रक्त शर्करा, कमर के आसपास शरीर की अतिरिक्त चर्बी और असामान्य कोलेस्ट्रॉल या ट्राइग्लिसराइड के स्तर सहित स्थितियों का एक समूह है।",
                  imageUrl: MetabolicImg,
                },
                {
                  id: 4,
                  title: "आनुवंशिकी",
                  description:
                    "कुछ आनुवांशिक कारक व्यक्तियों को NAFLD के लिए प्रेरित कर सकते हैं, हालांकि जीवनशैली कारक महत्वपूर्ण भूमिका निभाते हैं।  ",
                  imageUrl: GeneticsImg,
                },
              ],
            },

            nafldSypmtoms: {
              nafldSypmtomsTitle: "NAFLD के लक्षण ",
              nafldSypmtomsPara:
                "NAFLD अक्सर चुपचाप आगे बढ़ता है, कई व्यक्तियों को प्रारंभिक चरण में कोई ध्यान देने योग्य लक्षण अनुभव नहीं होते हैं। हालाँकि, जैसे-जैसे स्थिति बढ़ती है, लक्षणों में शामिल हो सकते हैं: ",
              nafldSypmtomsSubPara: {
                nafldSypmtomsSubPara1: "थकान",
                nafldSypmtomsSubPara2: "कमजोरी",
                nafldSypmtomsSubPara3: "पीलिया (त्वचा और आँखों का पीला पड़ना) ",
                nafldSypmtomsSubPara4: "पेट में तकलीफ या दर्द",
                nafldSypmtomsSubPara5: "पेट या पैरों में सूजन ",
                nafldSypmtomsSubPara6: "बढ़ा हुआ जिगर ",
              },
            },

            nadldLifestyle: {
              nadldLifestyleTittle: "NAFLD के लिए जीवन शैली-उन्मुख उपचार  ",
              lifeStyleOfNafld: [
                // {
                //   id: 1,
                //   title: "Weight Loss",
                //   description:
                //     "Achieving a healthy weight through diet and exercise is key in managing NAFLD.",
                //   imageUrl: WeightLossImg,
                // },
                {
                  id: 2,
                  title: "स्वस्थ आहार",
                  description:
                    "फलों, सब्जियों, साबुत अनाज और कम वसा वाले प्रोटीन से भरपूर संतुलित आहार अपनाने से लीवर के स्वास्थ्य में सुधार हो सकता है। .",
                  imageUrl: HealthyDietImg,
                },
                {
                  id: 3,
                  title: "नियमित व्यायाम",
                  description:
                    "नियमित शारीरिक गतिविधि में शामिल होने से लीवर की चर्बी कम करने में मदद मिलती है और इंसुलिन संवेदनशीलता में सुधार होता है।  ",
                  imageUrl: ExcerciseImg,
                },
                {
                  id: 4,
                  title: "दवाएं",
                  description:
                    " कुछ मामलों में, डॉक्टर मधुमेह या उच्च कोलेस्ट्रॉल जैसी अंतर्निहित स्थितियों को प्रबंधित करने के लिए दवाएं लिख सकते हैं।                    ",
                  imageUrl: MedicationImg,
                },
                {
                  id: 5,
                  title: "शराब से परहेज ",
                  description:
                    "हालांकि NAFLD शराब के सेवन के कारण नहीं होता है, फिर भी शराब को सीमित करना या उससे दूर रहना समग्र लीवर स्वास्थ्य के लिए महत्वपूर्ण है।",
                  imageUrl: AvoidAlcoholImg,
                },
              ],
            },
            nafldEndTittle: "अपने लीवर के स्वास्थ्य की जिम्मेदारी लें",
            nafldEndPara:
              "रुको मत! आज ही अपने लीवर के स्वास्थ्य की जिम्मेदारी लें। एनडी हेल्थ के साथ परामर्श का समय निर्धारित करें। और NAFLD पर विजय पाने के लिए अपने व्यक्तिगत मार्ग पर चलें। साथ मिलकर, हम आपके स्वास्थ्य को अनुकूलित कर सकते हैं और स्थायी कल्याण प्राप्त कर सकते हैं।",
          },

          shop: {
            coming: "हम जल्द आ रहे हैं ",
            tuned: "बने रहें ",
            homeBtn: "मुखपृष्ठ प्र जाएं",
          },

          WhatweDo: {
            whatweDoTitle:
              "डिजिटलीकरण और डेटा मॉडलिंग के माध्यम से स्वास्थ्य सेवा को उन्नत बनाना",

            whatweDoPrgs: {
              linkDiabetes: "मधुमेह",
              linkDyslipidemia: "डिसलिपिडेमिया",
              linkPCOD: "PCOD",
              linkObesity: "मोटापा",
              linkPsoriasis: "सोरायसिस",
              linkAntiAging: "बुढ़ापा विरोधी",
              linkNAFLD: "NAFLD",
            },
            digitalTwin: {
              digitalTwinTitle: "डिजिटल ट्विन क्या है?",
              digitalTwinHeader1: "स्वास्थ्य सेवा में एक नया युग ",
              digitalTwinPara1:
                "NdHealth की अवधारणा, विशाल डेटा बिंदुओं और एआई एल्गोरिदम से तैयार एक आभासी प्रतिनिधित्व, जिसे स्वास्थ्य सेवा के भविष्य के रूप में देखा गया है, रोगी देखभाल में अभूतपूर्व प्रगति का वादा करता है। रोगी ट्विनिंग का उपयोग करके, हमारी दृष्टि व्यक्तियों की सामान्य स्थिति में लौटने में तेजी लाने और, आदर्श रूप से, सक्रिय रूप से बीमारियों को रोकने की है। हालाँकि यह महत्वाकांक्षी लग सकता है, इस तकनीक की परिवर्तनकारी क्षमता इसके डेटा-संचालित दृष्टिकोण में निहित है। ",
              digitalTwinHeader2: "वास्तविक समय डेटा ",
              digitalTwinPara2:
                "NdHealth एक समग्र मॉडल है जो किसी व्यक्ति के व्यापक चिकित्सा इतिहास और डिजिटल स्वास्थ्य रिकॉर्ड से प्राप्त होता है, जिसे चिकित्सा परीक्षाओं और पहनने योग्य सेंसर से वास्तविक समय के डेटा के साथ सख्ती से अद्यतन किया जाता है। जानकारी के इस समामेलन का एआई एल्गोरिदम द्वारा निरंतर विश्लेषण किया जाता है, जो व्यक्ति के भविष्य के स्वास्थ्य प्रक्षेपवक्र की भविष्यवाणी करने वाले सिमुलेशन में परिणत होता है। ये अंतर्दृष्टि स्वास्थ्य सेवा प्रदाताओं और रोगियों को सूचित निर्णय लेने के लिए कार्रवाई योग्य जानकारी से लैस करती है। ",
              digitalTwinHeader3: "दक्षता",
              digitalTwinPara3:
                "हालाँकि, NdHealth की प्रभावकारिता डिजिटलीकरण के नेटवर्क के भीतर इसके एकीकरण पर निर्भर करती है, चाहे वह चिकित्सक हों या चिकित्सा उपकरण। यह परस्पर जुड़ा पारिस्थितिकी तंत्र रोगी देखभाल को अनुकूलित करने, विसंगतियों को कम करने और दक्षता को अधिकतम करने के लिए अनुरूप और स्वचालित कॉन्फ़िगरेशन सुनिश्चित करता है। रोगियों के लिए लाभ बहुत गहरे हैं - स्वास्थ्य डेटा की निरंतर निगरानी, ​​मूल्यांकन से प्राप्त अंतर्दृष्टि की समझने योग्य प्रस्तुति, और जीवनशैली समायोजन या समय पर हस्तक्षेप के माध्यम से सक्रिय रूप से स्वास्थ्य का प्रबंधन करने के लिए सशक्तिकरण। ",
              digitalTwinHeader4: "गंभीर चिकित्सा स्थितियाँ",
              digitalTwinPara4:
                "आपातकालीन स्थिति जैसी गंभीर स्थितियों में, NdHealth एक सतर्क सहयोगी के रूप में कार्य करता है, जो संबंधित पक्षों को सचेत करके, महत्वपूर्ण डेटा संचारित करके और तत्काल चिकित्सा सहायता की सुविधा प्रदान करके त्वरित प्रतिक्रिया देता है। इसके अलावा, देखभाल करने वालों और स्वास्थ्य देखभाल पेशेवरों के लिए, डिजिटल मॉडल रोगी की स्वास्थ्य स्थिति का समग्र दृष्टिकोण प्रदान करता है, सटीक निदान, उपचार योजना और विशेषज्ञ पर्यवेक्षण के तहत उन्नत प्रौद्योगिकियों द्वारा समर्थित दूरस्थ हस्तक्षेप की सुविधा प्रदान करता है।  ",
            },
          },

          contactus: {
            contactTitle: {
              contactTitleStart: "खुद को फिर से बनाने के",
              contacTitleMiddle: "अद्भुत संभावित तरीकों की खोज के लिए हमसे ",
              contacTitleLast: "संपर्क करें",
            },

            contactForm: {
              contactFormTitle: "संपर्क करें ",
              username: {
                usernameLabel: "पूरा नाम",
                usernamePlaceholder: "अपना नाम दर्ज करें*",
                usernameError1: "उपयोक्तानाम फ़ील्ड रिक्त नहीं हो सकता",
                usernameError2: "4 से 15 अक्षरों के भीतर उपयोगकर्ता नाम",
              },
              phoneNo: {
                phoneNoLabel: "फोन नंबर",
                phoneNoPlaceholder: "अपना मोबाइल डालें*",
                phoneNoError1: "फ़ोन नंबर आवश्यक है",
                phoneNoError2: "फ़ोन नंबर 10 अंकों का होना चाहिए",
                phoneNoError3: "अमान्य मोबाइल नंबर",
              },
              email: {
                emailLabel: "ईमेल",
                emailPlaceholder: "अपना ईमेल दर्ज करें*",
                emailError1: "ईमेल की जरूरत है",
                emailError2: "अमान्य ईमेल",
              },
              city: {
                cityLabel: "शहर",
                cityPlaceholder: "अपना शहर दर्ज करें*",
                cityError1: "शहर फ़ील्ड रिक्त नहीं हो सकती",
                cityError2: "शहर 2 से अधिक अक्षर होना चाहिए",
              },
              category: {
                categoryLabel: "श्रेणी चुनना",
                categoryPlaceholder: "आपके स्वास्थ्य की चिंता",
                categoryError: "आवश्यक",
                categoryPrgs: {
                  linkDiabetes: "मधुमेह",
                  linkDyslipidemia: "डिसलिपिडेमिया",
                  linkPCOD: "PCOD",
                  linkObesity: "मोटापा",
                  linkPsoriasis: "सोरायसिस",
                  linkAntiAging: "बुढ़ापा विरोधी",
                  linkNAFLD: "NAFLD",
                },
              },
              checkbox: {
                checkboxLabel:
                  "मैं  ND Health द्वारा कॉल, एसएमएस, व्हाट्सएप और ईमेल के माध्यम से संपर्क करने के लिए सहमत हूं",
                checkboxError: "आवश्यक",
              },
            },
          },
        },
      },

      ar: {
        translation: {
          common: {
            enquiryBtn: "استفسر الان",
            associates: "بمشاركة",
            contactBtn: "اتصل بنا",
            submitBtn: "يُقدِّم",
            knownBtn: " معروف أكثر,",
            viewBtn: "عرض المزيد",

            homeBtn: "انتقل إلى الصفحة الرئيسية",
            thankYou: "شكرًا لك!",
            getBack: "للاتصال بنا، سوف نعود إليك قريبا...",
          },

          headerAndFooter: {
            home: "الصفحة الرئيسية",
            programs: "البرامج",
            diabetes: "السكري",
            dyslipedimia: "دسليبيدميا",
            pcod: "PCOD",
            obesity: "بدانة",
            psoriasis: "صدفية",
            antiaging: "مكافحة الشيخوخة",
            nafld: "NAFLD",
            shop: "محل",
            whatwedo: " ما نقوم به",
            blog: "مدونة",
            contactus: "اتصل بنا",
            ndhealthDesc:
              "استكشف برامج ND Health.AI لمرض السكري، واضطراب شحوم الدم، ومتلازمة تكيس المبايض. رعاية شخصية لرحلتك الصحية. ابدأ بالتحول اليوم",
            quickLinks: "روابط سريعة",
            ourPrograms: "برامجنا",
            otherLinks: "روابط أخرى",
            privacy: "خصوصية ",
            terms: "شروط",
            shipping: "شحن",
            cancellation: "إلغاء",
            association: "بمشاركة",
            followUs: "تابعنا",
          },

          home: {
            future: "المستقبل",

            convergence: "@ تقارب علوم البيانات والرعاية الصحية",
            whatwedoBtn: "الذي نفعله",
            transformYour: {
              transform: "التكنولوجيا الرقمية المتطورة لكامل الجسم",
              twin: "Twin Health",
              technology: "قم بتحويل رفاهيتك باستخدام !",
              Programs: [
                {
                  id: 1,
                  title: "السكري",
                  desc: "التعامل مع أعراض مرض السكري؟ هل يؤثر تقلب مستويات السكر في الدم أو التعب أو التغيرات غير المبررة في الوزن على حياتك اليومية؟ في NDHealth.ai، فريقنا متخصص في إدارة مرض السكري. لا تدع مرض السكري يسيطر عليك - حدد موعدك الآن وابدأ رحلتك نحو حياة أكثر صحة وسعادة.",
                  path: "/programs/diabetes-reversal-program",
                  ImageUrl: DiabetesPng,
                },
                {
                  id: 2,
                  title: "دسليبيدميا",
                  desc: "هل تواجه تحديات دسليبيدميا؟ هل يؤثر ارتفاع مستويات الكوليسترول أو الدهون الثلاثية أو غيرها من تشوهات الدهون على صحتك؟ يقدم NDHealth.ai رعاية متخصصة لإدارة اضطراب شحوم الدم. لا تدع اضطراب شحوم الدم يعيق صحتك - حدد موعدك الآن وابدأ الرحلة نحو صحة القلب والأوعية الدموية بشكل أفضل.",
                  path: "/programs/dyslipidemia-treatment-program",
                  ImageUrl: DyslipedimiaPng,
                },
                {
                  id: 3,
                  title: "PCOD",
                  desc: "هل تعاني من أعراض PCOD؟ هل تؤثر الدورة الشهرية غير المنتظمة أو حب الشباب أو زيادة الوزن أو مشاكل الخصوبة على صحتك؟ في NDHealth.ai، فريقنا متخصص في إدارة PCOD. لا تدع PCOD يحدك - احجز موعدك الآن واتخذ الخطوة الأولى نحو حياة أكثر صحة وسعادة.",
                  path: "/programs/pcod-management-program",
                  ImageUrl: PCODPng,
                },
                {
                  id: 4,
                  title: "بدانة",
                  desc: "تعاني من السمنة؟ هل الوزن الزائد يؤثر على نوعية حياتك؟ في NDHealth.ai، فريقنا متخصص في إدارة السمنة. لا تدع السمنة تحدك - احجز موعدك الآن واتخذ الخطوة الأولى نحو حياة أكثر صحة وسعادة.",
                  path: "/programs/obesity-management-program",
                  ImageUrl: ObesityPng,
                },
                {
                  id: 5,
                  title: "صدفية",
                  desc: "التعامل مع أعراض الصدفية؟ هل يؤثر الانزعاج الناجم عن بقع الجلد الحمراء الملتهبة على حياتك اليومية؟ NDHealth.ai متخصص في إدارة الصدفية. لا تدع الصدفية تعيقك – حدد موعدك الآن وابدأ رحلتك نحو صحة أكثر وسعادة.",
                  path: "/programs/psoriasis-treatment-program",
                  ImageUrl: PsoriasisImg,
                },
                {
                  id: 6,
                  title: "مكافحة الشيخوخة",
                  desc: " تشعر بالقلق إزاء علامات الشيخوخة؟ هل تؤثر التجاعيد أو الخطوط الدقيقة أو فقدان الحيوية على ثقتك بنفسك؟ يقدم NDHealth.ai حلولًا متخصصة لمكافحة الشيخوخة. فريق الخبراء لدينا مكرس لمساعدتك على تحدي آثار الشيخوخة. لا تدع العمر يحدد شخصيتك - حدد موعدك الآن وابدأ رحلتك نحو شخص أكثر شبابًا وحيوية.",
                  path: "/programs/anti-aging-program",
                  ImageUrl: AntiAgingImg,
                },
                {
                  id: 7,
                  title: "NAFLD",
                  desc: "هل تعاني من أعراض NAFLD؟ هل تؤثر مشاكل الكبد أو التعب أو زيادة الوزن أو عدم الراحة في البطن على صحتك؟ في NDHealth.ai، متخصصونا ملتزمون بإدارة NAFLD. لا تدع NAFLD يقيدك - احجز موعدك الآن واتخذ الخطوة الأولى نحو حياة أكثر صحة وسعادة",
                  path: "/programs/nafld-program",
                  ImageUrl: NAFLDImg,
                },
              ],

              bookNowBtn: "احجز الآن",
            },

            dysfunction: {
              title: "الأيض المختل-السبب الأساسي وراء المرض الأيضي المزمن!",
              miniTitle1:
                "المشكلة الأساسية وراء أمراض التمثيل الغذائي المزمنة:",
              miniDesc1:
                "تنبع من عملية التمثيل الغذائي المختلة، الناجمة عن عوامل خارجية مثل التغذية والنوم والنشاط والإجهاد، والتي تعطل العمليات الكيميائية الأساسية الحيوية للحياة، وتشكل الأساس للحلول الصحية المدعومة بالذكاء الاصطناعي للتدخل. والتخفيف من تطور الحالات الأيضية المزمنة.",
              miniTitle2: "معالجة السبب الجذري - مفتاح التغلب على التحديات",
              miniDesc2:
                "لعكس أمراض التمثيل الغذائي المزمنة، يقدم نظام NDHealth الرقمي لكامل الجسم إرشادات مستهدفة، بما في ذلك الاستشارات الصحية عبر الإنترنت، لتعزيز العوامل الخارجية مثل التغذية والنوم والنشاط والإجهاد، واستعادة وظائف الجسم المثلى وعكس التأثيرات السلبية على الأعضاء والاضطرابات الأيضية.",
              miniTitle3: "إطلاق العنان لقوة التعرف على الفردية",
              miniDesc3:
                "يعد التمثيل الغذائي لكل فرد أمرًا بالغ الأهمية لتحقيق أفضل الحلول الصحية. تسلط الأبحاث الطبية الضوء على التباين في كيفية عمل الأيض، مما يشكل تحديات في تصميم العلاجات بشكل فعال. يقدم نظام NDHealth الرقمي لكامل الجسم إرشادات دقيقة مخصصة لعملية التمثيل الغذائي المميزة لكل عضو، مما يضمن الدعم الشخصي لتحسين النتائج الصحية.",

              knownBtn: "تعرف أكثر",
            },

            benefits: {
              benefTitle:
                "فوائد اختيار الحلول الصحية المدعومة بالذكاء الاصطناعي مثل NDHealth",
              para1: "تقليل الأدوية المكلفة بما في ذلك الأنسولين              ",

              para2: "زيادة الطاقة وتحسين المزاج وتقليل الأعراض",

              para3:
                "التمثيل الغذائي الخاص بك للمساعدة في تطبيع نسبة السكر في الدم                ",

              para4:
                "الوصول إلى البيانات في الوقت الحقيقي لمراقبة المرضى عن بعد                ",

              para5:
                "نتائج مثبتة مدعومة بتكنولوجيا الرعاية الصحية المتطورة المعتمدة على الذكاء الاصطناعي",
            },
          },

          diabetes: {
            title: "برنامج عكس مرض السكري",
            mainDesc:
              "هل أنت مستعد للسيطرة على مرض السكري لديك وتغيير حياتك؟ تقدم NDHealth برنامجًا مبتكرًا لعكس مرض السكري، وذلك من خلال تسخير قوة تقنية الذكاء الاصطناعي. تم تصميم نهجنا المبتكر لمساعدتك على عكس مرض السكري واستعادة صحتك كما لم يحدث من قبل.",
            contactBtn: "اتصل بنا",

            Transform: {
              subHeading1: "تحويل الصحة مع NDHealth",
              subPara1:
                "يتضمن الجهاز الرقمي لكامل الجسم حلول الرعاية الصحية المدعمة بالذكاء الاصطناعي لإنشاء تمثيل افتراضي لعملية التمثيل الغذائي لديك، وتقديم نصائح مخصصة حول التغذية والنوم والتمارين الرياضية والتنفس عبر واجهة تطبيق سهلة الاستخدام.",
              subHeading2: " برنامج NdHealth المتكامل",
              subPara2:
                "يدمج برنامج NdHealth الشامل خدمات الرعاية الصحية المبتكرة، ويجمع بين إمكانيات الرقمي لكامل الجسم مع فريق متخصص من مقدمي الرعاية الذين يشرفون على بيانات المستشعر الخاصة بك، ويقدمون توصيات مخصصة، ويساعدونك في رحلتك الصحية",

              subHeading3: "إدارة ثورية لمرض السكري باستخدام NdHealth AI",
              subPara3:
                "استمتع بتجربة برنامج ثوري يقدم حلاً تحويليًا للأفراد الذين يتعاملون مع مرض السكري. يقوم NdHealth، وهو نظام مبتكر يعتمد على الذكاء الاصطناعي، بإنشاء جسم شخصي مزدوج لتحليل وفهم تعقيدات جسمك، وتوفير أفضل الحلول الصحية في نهج واحد شامل.",
              subHeading4: "البحث عن أفضل الأطباء في مكان قريب",
              subPara4:
                "ابحث عن أفضل الأطباء بالقرب منك من خلال تطبيق سهل الاستخدام يضمن اتباع نهج متوازن ومصمم خصيصًا لعلاج مرض السكري. احتضن قوة التكنولوجيا الحديثة، التي لم يتم استغلالها إلى حد كبير من قبل الكثيرين، وشاهد إمكانية تحقيق عكس مرض السكري خلال إطار زمني رائع مدته أربعة أشهر.",
            },

            Results: {
              header: "النتائج التي يمكنك رؤيتها والشعور بها",
              subHeader: "مساعدة الناس على عكس مرض السكري",
              DiabetesResults: [
                {
                  id: 1,
                  imgUrl: diabetesPng,
                  heading: "1.9%",
                  subHeading: "متوسط ​​انخفاض نسبة HbA1c",
                },
                {
                  id: 2,
                  imgUrl: medicationPng,
                  heading: "90%",
                  subHeading: "قاموا بخفض الدواء أو القضاء عليه   ",
                },
                {
                  id: 3,
                  imgUrl: insulinPng,
                  heading: "92%",
                  subHeading: "قاموا بخفض الأنسولين أو القضاء عليه",
                },
                {
                  id: 4,
                  imgUrl: sugarLevelPng,
                  heading: "90%",
                  subHeading: "شهدت انخفاضا في مستويات السكر",
                },
                {
                  id: 5,
                  imgUrl: weightPng,
                  heading: "3+ Kg",
                  subHeading: "متوسط ​​فقدان الوزن المستمر خلال أشهر",
                },
              ],
            },

            Plan: {
              header: "ماذا يوجد في الخطة",
              subHeader1: "جهاز مراقبة الجلوكوز المستمر",
              planSubPara1:
                "التكنولوجيا التي تجمع بيانات السكر في الدم في الوقت الحقيقي لتحليلها.",
              subHeader2: "تحت إشراف الطبيب",
              planSubPara2:
                "لا مزيد من المواعيد التي تتم مرة واحدة في القمر الأزرق!",
              subHeader3: "خبير متخصص في مرض السكري",
              planSubPara3:
                "الدعم اليومي والإجابات على الأسئلة هي مجرد رسالة WhatsApp! ",
              subHeader4: "اختبار تشخيصي شامل",
              planSubPara4:
                "إجراء أكثر من 70 فحصًا للدم والبول أثناء اختبارات الإعداد وفحص مرض السكري كل ثلاثة أشهر.",
              subHeader5: "خطط شخصية ",
              planSubPara5: "خطط شخصية وسهلة المتابعة للوجبات والتوتر والنوم.",
            },
            enquiry: "استفسر الان",
            association: "بمشاركة",
          },

          DyslipedimiaProgram: {
            dysTitle: "عسر شحميات الدم ",
            dysDesc:
              "تشعر بالقلق إزاء دسليبيدميا؟ انت لست وحدك. تؤثر هذه الحالة الشائعة على الملايين في جميع أنحاء العالم. ولكن مع NDHealth، المدعوم بالذكاء الاصطناعي، اكتشف نهجًا مخصصًا لإدارة دسليبيدميا. يدمج برنامجنا المبتكر أحدث التقنيات لتوفير إرشادات ودعم مخصص لتحسين النتائج الصحية.",

            dysHeader1: "الحل الرقمي لشركة NdHealth ",
            dysPara1:
              "تشعر بالقلق إزاء دسليبيدميا؟ انت لست وحدك. تؤثر هذه الحالة الشائعة على الملايين في جميع أنحاء العالم. ولكن مع NDHealth، المدعوم بالذكاء الاصطناعي، اكتشف نهجًا مخصصًا لإدارة دسليبيدميا. يدمج برنامجنا المبتكر أحدث التقنيات لتوفير إرشادات ودعم مخصص لتحسين النتائج الصحية.",
            dysHeader2: "العلاج الثوري لاضطراب شحوم الدم ",
            dysPara2:
              "استمتع بتجربة أفضل علاج لخلل الدهون في الدم من خلال برنامج ثوري مصمم لمعالجته بدقة غير مسبوقة. من خلال الاستفادة من أحدث التقنيات، تقدم NdHealth نهجًا شاملاً يلغي الحاجة إلى العلاجات والأدوية الغازية. من خلال نظام يعتمد على الذكاء الاصطناعي، يتم إنشاء نموذج جسم شخصي لتحليل تعقيدات علم وظائف الأعضاء لديك بشكل شامل. ",
            dysHeader3:
              "أطلق العنان لإمكاناتك الصحية من خلال علاج اضطراب شحوم الدم سهل الاستخدام ",
            dysPara3:
              "يمكن الوصول إلى هذا البرنامج عبر تطبيق سهل الاستخدام، ويضمن اتباع نهج متوازن وفردي لإدارة دسليبيدميا. احتضن إمكانات التكنولوجيا الحديثة، التي غالبًا ما لا يستغلها الكثيرون، وشاهد              ",
            dysHeader4: "الأسباب والإدارة",
            dysPara4:
              "دسليبيدميا هي حالة مزمنة لا يمكن علاجها. ومع ذلك، يمكن تحسين بعض الأعراض من خلال تعديلات نمط الحياة والأدوية والتدخلات المستهدفة. في حين أن السبب الدقيق لخلل الدهون في الدم لا يزال غير معروف، فإن الأفراد الذين لديهم تاريخ عائلي أو أولئك الذين تم تشخيص إصابتهم بداء السكري من النوع 2 يكونون أكثر عرضة للخطر. ",

            Causes: {
              causeHeader: "ما هي الأسباب ومن هو في خطر؟ ",
              causeSubHeader:
                "العديد من السلوكيات يمكن أن تؤدي إلى دسليبيدميا. يشملوا: ",
              DyslipedimiaCauses: [
                {
                  id: 1,
                  title: "تدخين السجائر.",
                  ImageUrl: smokingPng,
                },
                {
                  id: 2,
                  title: "السمنة ونمط الحياة المستقرة ",
                  ImageUrl: obesityPng,
                },
                {
                  id: 3,
                  title:
                    "استهلاك الأطعمة التي تحتوي على نسبة عالية من الدهون المشبعة والدهون المتحولة  ",
                  ImageUrl: excessiveFoodPng,
                },
                {
                  id: 4,
                  title:
                    "قد يساهم الإفراط في استهلاك الكحول أيضًا في ارتفاع مستويات الدهون الثلاثية.",
                  ImageUrl: alocoholPng,
                },
              ],
            },
            Treatment: {
              treatHeader: "خيارات العلاج ",
              treatPara1:
                "الدواء الأكثر استخدامًا لعلاج دسليبيدميا هو الستاتين. تساعد الستاتينات على تقليل مستويات LDL عن طريق التدخل في إنتاج الكوليسترول في الكبد. إليك المزيد حول كيفية عمل الستاتينات. ",
              treatPara2:
                "هناك عدة أنواع من الستاتين. كلهم يعملون بشكل مختلف قليلاً، حيث يكون بعضهم أقوى من البعض الآخر. ",
              treatPara3:
                "قد يصف طبيبك أيضًا أدوية أخرى للكوليسترول. ويمكن تناولها بالإضافة إلى الستاتين أو بدلاً من الستاتين. هناك العديد من الإيجابيات والسلبيات التي يجب مراعاتها عند الاختيار بين أدوية التحكم في الكوليسترول. ",
            },

            non_statinDrugs: {
              drugHeader: "وتشمل هذه الأدوية غير الستاتينية ",
              drug1: "إزيتيميب (زيتيا)",
              drug2: "الفايبرات، مثل فينوفايبرات (فينو جلايد) ",
              drug3: "مثبطات PCSK9 ",
            },

            dysProgram:
              "استمتع بتجربة الراحة من اضطراب شحوم الدم من خلال برنامج NdHealth REVERSAL الذي ننصح به بشدة. ",
          },

          Pcod: {
            pcodTitle: "برنامج عكس PCOD ",
            pcodDesc:
              "تكافح مع PCOD؟ تواصل معنا للحصول على الدعم الشخصي. يقدم فريقنا التوجيه والحلول لإدارة PCOD بشكل فعال. ",
            Comprehensive: {
              compHeader: "دليل شامل لـ PCOD مع NDHealth ",
              compSubHeader: "تمكين صحة المرأة ",
              pcodSectionTitle1: "ملخص",
              pcodSectionDesc1:
                "متلازمة المبيض المتعدد الكيسات (PCOS) هي حالة هرمونية شائعة تؤثر على النساء في سن الإنجاب. وعادة ما يبدأ خلال فترة المراهقة، ولكن الأعراض قد تتقلب مع مرور الوقت. يمكن أن تسبب متلازمة تكيس المبايض اختلالات هرمونية، وعدم انتظام الدورة الشهرية، وزيادة مستويات الأندروجين، والخراجات في المبيضين. يمكن أن تؤدي الدورة الشهرية غير المنتظمة، التي عادة ما تكون مصحوبة بنقص الإباضة، إلى صعوبة الحمل. متلازمة تكيس المبايض هي السبب الرئيسي للعقم.",
              pcodSectionTitle2: "نطاق المشكلة ",
              pcodSectionDesc2:
                "تعد متلازمة تكيس المبايض مشكلة صحية عامة كبيرة وهي واحدة من الاضطرابات الهرمونية الشائعة التي تؤثر على النساء في سن الإنجاب. تؤثر هذه الحالة على ما يقدر بنحو 8-13% من النساء في سن الإنجاب، وما يصل إلى 70% من الحالات لا يتم تشخيصها. يعد انتشار متلازمة تكيس المبايض أعلى بين بعض الأعراق، وغالبًا ما تعاني هذه المجموعات من مضاعفات أكثر، خاصة فيما يتعلق بمشاكل التمثيل الغذائي. ",

              pcodSectionTitle3: "أعراض",
              pcodSectionDesc3: {
                pcodSection3Main:
                  "يمكن أن تختلف أعراض متلازمة المبيض المتعدد الكيسات من شخص لآخر. قد تتغير الأعراض بمرور الوقت، وغالبًا ما تحدث دون وجود مسبب واضح. تشمل الأعراض المحتملة ما يلي:",
                pcodSection3Sub1:
                  "• فترات غزيرة، طويلة، متقطعة، لا يمكن التنبؤ بها أو غائبة                  ",
                pcodSection3Sub2: "• العقم",
                pcodSection3Sub3: "• حب الشباب أو البشرة الدهنية ",
                pcodSection3Sub4: "• الشعر الزائد في الوجه أو الجسم",
                pcodSection3Sub5: "• زيادة الوزن وخاصة حول البطن. ",
              },
              pcodSectionTitle4: "تشخبص ",
              pcodSectionDesc4: {
                pcodSection4Main:
                  "يتم تشخيص متلازمة المبيض المتعدد الكيسات من خلال وجود اثنين على الأقل مما يلي:",
                pcodSection4Sub1:
                  "1. علامات أو أعراض ارتفاع الأندروجينات (شعر الوجه أو الجسم غير المرغوب فيه، تساقط شعر الرأس، حب الشباب أو ارتفاع مستوى هرمون التستوستيرون في الدم) – بعد استبعاد الأسباب الأخرى لذلك.                  ",
                pcodSection4Sub2:
                  " 2. عدم انتظام الدورة الشهرية أو غيابها – بعد استبعاد الأسباب الأخرى لذلك. و ",
                pcodSection4Sub3:
                  " 3. تكيس المبايض على الفحص بالموجات فوق الصوتية. ",
              },
            },

            KeyFacts: {
              keyFactsTitle: "مفتاح الحقائق ",
              keyFactsPara1:
                "متلازمة تكيس المبايض هي السبب الأكثر شيوعًا لعدم الإباضة والمساهم الرئيسي في العقم بين النساء. ",
              keyFactsPara2:
                "وعلى الرغم من انتشاره، فإن ما يصل إلى 70% من النساء المصابات بمتلازمة تكيس المبايض لا يتم تشخيصهن على مستوى العالم، مما يسلط الضوء على الحاجة إلى زيادة الوعي والفحص. ",
              keyFactsPara3:
                "تؤثر متلازمة تكيس المبايض على ما يقدر بنحو 8-13% من النساء في سن الإنجاب، مما يجعلها مصدر قلق صحي كبير  ",
              keyFactsPara4:
                "وبعيدًا عن مشكلات الخصوبة، ترتبط متلازمة تكيس المبايض بمجموعة من المشكلات الصحية طويلة المدى التي يمكن أن تؤثر على الصحة البدنية والعاطفية، مما يؤكد أهمية التشخيص المبكر والإدارة. ",
              keyFactsPara5:
                "في حين أن متلازمة تكيس المبايض تميل إلى الانتشار في العائلات، إلا أن مظاهرها وتأثيراتها يمكن أن تختلف بين المجموعات العرقية المختلفة، مما يشير إلى تأثير العوامل الوراثية والبيئية.",
            },
          },

          obesity: {
            obTitle: "مرحبًا بك في برنامج فقدان الوزن لدينا ",
            obDesc:
              "هل سئمت من المعاناة من فقدان الوزن؟ هل تشعر وكأنك جربت كل نظام غذائي وتمارين رياضية بأقل قدر من النجاح؟ لقد حان الوقت للنظر في نهج ثوري: الانعكاس الأيضي المدعوم بالذكاء الاصطناعي (AI). ",
            metabTitle: "ما هو الانعكاس الأيضي؟ ",
            metabDesc:
              "لا يقتصر الانعكاس الأيضي على خسارة الوزن فحسب؛ يتعلق الأمر بمعالجة السبب الجذري لزيادة الوزن والخلل الأيضي. من خلال استهداف عوامل مثل مقاومة الأنسولين، والاختلالات الهرمونية، والالتهابات، يهدف عكس التمثيل الغذائي إلى استعادة قدرة الجسم الطبيعية على حرق الدهون بكفاءة.",
            aiTitle: "كيف يمكن للذكاء الاصطناعي أن يساعد  ",
            aiDesc:
              "يسخر برنامج فقدان الوزن لدينا قوة الذكاء الاصطناعي لتخصيص رحلتك نحو عكس التمثيل الغذائي. باستخدام الخوارزميات المتقدمة، نقوم بتحليل التركيب الجيني الفريد الخاص بك، وعادات نمط الحياة، والتاريخ الطبي، والتفضيلات الغذائية لتصميم خطة تناسبك خصيصًا. ",
            personalTitle: "التغذية الشخصية ",
            personalDesc:
              "قل وداعًا لخطط الوجبات ذات المقاس الواحد الذي يناسب الجميع. تقوم منصتنا المدعومة بالذكاء الاصطناعي بتصميم خطط تغذية مخصصة بناءً على احتياجاتك وأهدافك الفردية. سواء كنت تتطلع إلى التحكم في مستويات السكر في الدم، أو تقليل الالتهاب، أو تحسين توازن الهرمونات، فسنضع خطة طعام مستدامة تغذي جسمك وتعزز الصحة الأيضية.",
            realtimeTitle: "المراقبة والدعم في الوقت الحقيقي",
            realtimeDesc:
              "من خلال منصتنا المدعومة بالذكاء الاصطناعي، ستتلقى تعليقات ودعمًا في الوقت الفعلي في كل خطوة على الطريق. تتبع تقدمك، وراقب المقاييس الرئيسية مثل تكوين الجسم وعلامات الدم، واحصل على رؤى مخصصة لإبقائك متحفزًا وعلى المسار الصحيح نحو تحقيق أهدافك في إنقاص الوزن.",
            intelligentTitle: "توجيه التمرين الذكي ",
            intelligentDesc:
              "تعتبر التمارين الرياضية عنصرًا حاسمًا في عملية الانعكاس الأيضي، ولكن ليست كل التمارين متساوية. يقوم نظام الذكاء الاصطناعي الخاص بنا برعاية إجراءات التمارين الشخصية التي تأخذ في الاعتبار مستوى لياقتك وتفضيلاتك وأي حالات صحية أساسية. سواء كنت تفضل التدريب المتقطع عالي الكثافة، أو تدريب القوة، أو اليوجا، فسوف نرشدك خلال التمارين التي تزيد من حرق الدهون وكفاءة التمثيل الغذائي.",
            joinTitle: "انضم إلى برنامجنا اليوم ",
            joinDesc:
              "لا تدع الإحباط وخيبة الأمل تعيقك لفترة أطول. احتضن قوة الانعكاس الأيضي من خلال برنامج فقدان الوزن المعتمد على الذكاء الاصطناعي. قل وداعًا للأنظمة الغذائية القاسية وساعات طويلة في صالة الألعاب الرياضية، واستمتع بفقدان الوزن بشكل مستدام وتحسين الصحة الأيضية بشكل لم يسبق له مثيل.",
            obessignupMsg:
              "سجل الآن واتخذ الخطوة الأولى نحو حياة أكثر صحة وسعادة! ",
          },

          psoriasis: {
            psoTitle: "بشرة أكثر نقاءً مع علاج الصدفية",
            psoDesc:
              "يمكن أن تؤثر الصدفية على صحتك. هنا في ND Health، نستفيد من قوة الذكاء الاصطناعي (AI) لتقديم نهج ثوري لعلاج الصدفية في الهند.",
            psoIntroTitle: {
              psoIntroSubHead1: "تقنية إن دي هيلث",

              psoIntroSubHead2: "إدخال",
            },
            psoIntroDesc:
              "تخيل رفيقًا صحيًا افتراضيًا مخصصًا فقط لفهم وضعك الفريد. هذا هو ND الصحة. تقوم هذه المنصة المتطورة بتحليل تاريخك الطبي وبياناتك في الوقت الفعلي (من الأجهزة القابلة للارتداء والاختبارات)، وتستخدم خوارزميات الذكاء الاصطناعي لإنشاء توأم رقمي لصحتك.",

            psoChoose: {
              psoChooseTitle: {
                psoChooseTitle1: "لماذا تختار ",
                psoChooseTitle2: "ND Health؟",
              },
              psoChooseSubHead1: "ما وراء الحلول العامة ",

              psoChooseSubPara1:
                "تخلص من نهج المقاس الواحد الذي يناسب الجميع. تقوم ND Health بتخصيص خطة علاج الصدفية الخاصة بك بناءً على احتياجاتك الخاصة.",
              psoChooseSubHead2: "الإدارة الاستباقية",

              psoChooseSubPara2:
                "ابق في صدارة التوهجات من خلال المراقبة المستمرة والتوصيات الشخصية من ND Health",
              psoChooseSubHead3: "رؤى تعتمد على البيانات ",

              psoChooseSubPara3:
                "افهم مرض الصدفية لديك من خلال التحليل المدعوم بالذكاء الاصطناعي. اتخاذ قرارات مستنيرة بشأن مسار العلاج الخاص بك ",
              psoChooseSubHead4: "التمكين من خلال المعرفة",

              psoChooseSubPara4:
                "احصل على فهم أعمق لحالتك وكيفية إدارتها بفعالية.",
              psoChooseSubHead5: "عش حياة أكثر راحة",

              psoChooseSubPara5:
                "هدفنا هو مساعدتك على تحقيق راحة دائمة وتحسين صحتك بشكل عام.",
            },

            psoEmbrace: {
              psoEmbraceTitle: {
                psoEmbraceTitle1: "   احتضن ",
                psoEmbraceTitle2: "مستقبل   ",
                psoEmbraceTitle3: "علاج الصدفية ",
              },
              psoEmbraceDesc:
                "لا تكتفي فقط بإدارة الأعراض. في ND Health Ai، نحن نؤمن بأهمية العثور على السبب الجذري ووضع خطة علاجية مخصصة. تواصل مع ND Health اليوم واطلق العنان لقوة الذكاء الاصطناعي للحصول على بشرة أكثر نقاءً وصحة لك!",
            },
          },

          antiAging: {
            antiTitle: "تحويل الحياة مع عكس العمر الأيضي ",
            antiDesc:
              " في ND Health، نحن ملتزمون بمساعدتك على تحدي الشيخوخة وتنشيط صحتك من خلال قوة عكس الشيخوخة الأيضية. قل وداعًا للشعور بالتعب ومرحبًا بشخصيتك المفعمة بالحيوية والنشاط! ",
            antiMetabTitle: "ما هو عكس العمر الأيضي؟ ",
            antiMetabDesc:
              "لا يعني عكس العمر الأيضي الرجوع بالزمن إلى الوراء؛ يتعلق الأمر بتحسين عملية التمثيل الغذائي في جسمك لتشعر وتبدو بأفضل حالاتك. إنها رحلة مثبتة علميًا نحو حياة أكثر صحة وشبابًا. ",

            approach: {
              appraochMainTitle: "نهجنا",
              OurApproach: [
                {
                  id: 1,
                  title: "خطط التغذية الشخصية",
                  description:
                    "لا توجد حمية غذائية قاطعة لملفات تعريف الارتباط هنا. نقوم بإنشاء خطط تغذية مخصصة لتغذية جسمك وتحفيز عملية التمثيل الغذائي لديك",
                  imageUrl: NutritonImg,
                },
                {
                  id: 2,
                  title: "برامج اللياقة البدنية المخصصة  ",
                  description:
                    "قل مرحباً بالتمارين التي تعزز عملية التمثيل الغذائي ونحت جسمك. يقوم المدربون لدينا بتصميم إجراءات روتينية خصيصًا لك.",
                  imageUrl: FitnessImg,
                },
                {
                  id: 3,
                  title: "تحسين النوم",
                  description:
                    "النوم الجيد هو المفتاح لعملية التمثيل الغذائي الصحي. اكتشف الاستراتيجيات لتحسين راحتك لتحقيق أقصى قدر من التجديد.",
                  imageUrl: SleepImg,
                },
                {
                  id: 4,
                  title: "ادارة الاجهاد",
                  description:
                    "الإجهاد يمكن أن يعيث فسادا في عملية التمثيل الغذائي الخاص بك. تعلم تقنيات للتحكم في التوتر وازدهار عملية التمثيل الغذائي لديك ",
                  imageUrl: StressImg,
                },
              ],
            },

            appBenefits: {
              appBenefitsMainTitle: "فوائد عكس العمر الأيضي ",
              BenefitsOfAgeReversal: [
                {
                  id: 1,
                  title: "تعزيز مستويات الطاقة ",
                  description:
                    "استيقظ وأنت تشعر بالانتعاش والاستعداد للتغلب على يومك.",
                  imageUrl: BoostedImg,
                },
                {
                  id: 2,
                  title: "الإدارة الفعالة للوزن",
                  description:
                    "تخلص من الوزن غير المرغوب فيه حيث يصبح التمثيل الغذائي الخاص بك آلة لحرق الدهون. ",
                  imageUrl: WeightManagementImg,
                },
                {
                  id: 3,
                  title: "تعزيز الوضوح العقلي ",
                  description:
                    "التمثيل الغذائي الصحي يعني عقلًا أكثر صحة. تحسين التركيز والوظيفة المعرفية.",
                  imageUrl: YogaImg,
                },
                {
                  id: 4,
                  title: "حيوية مشعة",
                  description:
                    "توهج بالحيوية من الداخل والخارج، وذلك بفضل عملية التمثيل الغذائي التي تدعم الصحة العامة.",
                  imageUrl: RadiantImg,
                },
              ],
            },

            appNdHealth: {
              appNdHealthMainTittle: "لماذا تختار ND الصحة؟",
              ChooseNdHealth: [
                {
                  id: 1,
                  title: "النهج المبني على الأدلة ",
                  description:
                    "أساليبنا متجذرة في أحدث الأبحاث العلمية حول الصحة الأيضية وطول العمر.",
                  imageUrl: EvidenceImg,
                },
                {
                  id: 2,
                  title: "إرشادات الخبراء ",
                  description:
                    "اعمل مع فريقنا من المهنيين الصحيين ذوي الخبرة والملتزمين بنجاحك.",
                  imageUrl: ExpertImg,
                },
                {
                  id: 3,
                  title: "التحولات الحقيقية",
                  description:
                    " استمع إلى مجتمع عملائنا الذين استعادوا صحتهم من خلال عكس العمر الأيضي.",
                  imageUrl: TransformedImg,
                },
                {
                  id: 4,
                  title: "الحلول الشاملة",
                  description:
                    "تبني نهجا شاملا يغذي قدرة الجسم الطبيعية على الشفاء والازدهار.",
                  imageUrl: HolisticImg,
                },
              ],
            },

            appEndingPara:
              "لا تتردد في تعديل أي تفاصيل أو إضافة تفاصيل تتوافق مع عروض ND Health الفريدة وصوت العلامة التجارية! ",
          },

          Nafld: {
            nafldMainTittle: "علاج مرض الكبد الدهني غير الكحولي ",
            nafldDesc:
              "هل تشعر بالقلق من مرض الكبد الدهني غير الكحولي (NAFLD)؟ انت لست وحدك. يواجه الملايين في الهند هذا القلق الصحي الصامت. ولكن هناك أخبار جيدة! تقدم ND Health، المدعومة بالذكاء الاصطناعي المتطور (AI)، نهجًا ثوريًا لعلاج NAFLD الشخصي. ",

            nafldSubhead: "ما هو NAFLD؟ ",
            nafldSubPara:
              "NAFLD هي حالة كبد سائدة تتميز بتراكم الدهون في خلايا الكبد. على عكس مرض الكبد الكحولي، يحدث مرض الكبد الدهني غير الكحولي (NAFLD) عند الأفراد الذين يستهلكون كميات قليلة من الكحول أو لا يتناولونها على الإطلاق. وغالبًا ما يرتبط بالسمنة ومقاومة الأنسولين ومتلازمة التمثيل الغذائي. ",
            nafldCauses: {
              nafldCausesTittle: "أسباب مرض الكبد الدهني غير الكحولي (NAFLD). ",
              CauseOfNafld: [
                {
                  id: 1,
                  title: "بدانة ",
                  description:
                    "يزيد وزن الجسم الزائد، وخاصة السمنة في منطقة البطن، من خطر الإصابة بـ NAFLD. ",
                  imageUrl: ObesityImg,
                },
                {
                  id: 2,
                  title: "مقاومة الأنسولين",
                  description:
                    "تتداخل مقاومة الأنسولين، التي ترتبط غالبًا بمرض السكري من النوع 2، مع كيفية معالجة الجسم للسكر والدهون، مما يؤدي إلى تراكم الدهون في الكبد.",
                  imageUrl: InsulinImg,
                },
                {
                  id: 3,
                  title: "متلازمة الأيض ",
                  description:
                    "يرتبط NAFLD ارتباطًا وثيقًا بمتلازمة التمثيل الغذائي، وهي مجموعة من الحالات بما في ذلك ارتفاع ضغط الدم وارتفاع نسبة السكر في الدم وزيادة الدهون في الجسم حول الخصر ومستويات الكوليسترول أو الدهون الثلاثية غير الطبيعية.  ",
                  imageUrl: MetabolicImg,
                },
                {
                  id: 4,
                  title: "علم الوراثة ",
                  description:
                    "يمكن لبعض العوامل الوراثية أن تؤهب الأفراد للإصابة بـ NAFLD، على الرغم من أن عوامل نمط الحياة تلعب دورًا مهمًا. ",
                  imageUrl: GeneticsImg,
                },
              ],
            },

            nafldSypmtoms: {
              nafldSypmtomsTitle: "أعراض مرض NAFLD ",
              nafldSypmtomsPara:
                "غالبًا ما يتطور مرض NAFLD بصمت، حيث لا يعاني العديد من الأفراد من أي أعراض ملحوظة في المراحل المبكرة. ومع ذلك، مع تقدم الحالة، قد تشمل الأعراض ما يلي: ",
              nafldSypmtomsSubPara: {
                nafldSypmtomsSubPara1: "تعب",
                nafldSypmtomsSubPara2: "ضعف",
                nafldSypmtomsSubPara3: "اليرقان (اصفرار الجلد والعينين)      ",
                nafldSypmtomsSubPara4: "انزعاج أو ألم في البطن",
                nafldSypmtomsSubPara5: "تورم في البطن أو الساقين ",
                nafldSypmtomsSubPara6: "تضخم الكبد                ",
              },
            },

            nadldLifestyle: {
              nadldLifestyleTittle: "العلاج الموجه لأسلوب الحياة لـ NAFLD   ",
              lifeStyleOfNafld: [
                // {
                //   id: 1,
                //   title: "Weight Loss",
                //   description:
                //     "Achieving a healthy weight through diet and exercise is key in managing NAFLD.",
                //   imageUrl: WeightLossImg,
                // },
                {
                  id: 2,
                  title: "حمية صحية ",
                  description:
                    "إن اتباع نظام غذائي متوازن غني بالفواكه والخضروات والحبوب الكاملة والبروتينات الخالية من الدهون يمكن أن يحسن صحة الكبد. ",
                  imageUrl: HealthyDietImg,
                },
                {
                  id: 3,
                  title: "تمرين منتظم ",
                  description:
                    "تساعد ممارسة النشاط البدني بانتظام على تقليل دهون الكبد وتحسين حساسية الأنسولين.",
                  imageUrl: ExcerciseImg,
                },
                {
                  id: 4,
                  title: "الأدوية",
                  description:
                    " في بعض الحالات، قد يصف الأطباء أدوية لإدارة الحالات الأساسية مثل مرض السكري أو ارتفاع نسبة الكوليسترول. ",
                  imageUrl: MedicationImg,
                },
                {
                  id: 5,
                  title: "تجنب الكحول ",
                  description:
                    "على الرغم من أن مرض الكبد الدهني غير الكحولي (NAFLD) لا ينجم عن استهلاك الكحول، إلا أن الحد من تناول الكحول أو الامتناع عن تناوله أمر مهم لصحة الكبد بشكل عام.  ",
                  imageUrl: AvoidAlcoholImg,
                },
              ],
            },
            nafldEndTittle: "تولي مسؤولية صحة الكبد",
            nafldEndPara:
              "لا تنتظر! تولي مسؤولية صحة الكبد اليوم. حدد موعدًا للتشاور مع ND Health. وابدأ في طريقك الشخصي لغزو NAFLD. معًا، يمكننا تحسين صحتك وتحقيق رفاهية دائمة.",
          },

          shop: {
            coming: "نحن قادمون قريبا ",
            tuned: "ابقوا متابعين",
            homeBtn: "انتقل إلى الصفحة الرئيسية",
          },

          WhatweDo: {
            whatweDoTitle:
              "الارتقاء بالرعاية الصحية من خلال الرقمنة ونمذجة البيانات",
            whatweDoPrgs: {
              linkDiabetes: "السكري",
              linkDyslipidemia: "عسر شحميات الدم",
              linkPCOD: "PCOD",
              linkObesity: " بدانة",
              linkPsoriasis: " صدفية",
              linkAntiAging: "مكافحة الشيخوخة",
              linkNAFLD: "NAFLD",
            },
            digitalTwin: {
              digitalTwinTitle: "ما هو التوأم الرقمي؟  ",
              digitalTwinHeader1: "عصر جديد في الرعاية الصحية ",
              digitalTwinPara1:
                "إن مفهوم NdHealth، وهو تمثيل افتراضي مصنوع من نقاط بيانات واسعة وخوارزميات الذكاء الاصطناعي، والذي يُنظر إليه على أنه مستقبل الرعاية الصحية، يحمل وعدًا بإحداث تقدم رائد في رعاية المرضى. ومن خلال تسخير توأمة المرضى، تتمثل رؤيتنا في التعجيل بعودة الأفراد إلى حياتهم الطبيعية، ومن الناحية المثالية، الوقاية من الأمراض بشكل استباقي. وعلى الرغم من أن الأمر قد يبدو طموحًا، إلا أن الإمكانات التحويلية لهذه التكنولوجيا تكمن في نهجها القائم على البيانات. ",
              digitalTwinHeader2: "معلومات الوقت الحقيقي ",
              digitalTwinPara2:
                "NdHealth هو نموذج مركب مشتق من التاريخ الطبي الشامل للشخص والسجلات الصحية الرقمية، ويتم تحديثه بدقة ببيانات في الوقت الفعلي من الفحوصات الطبية وأجهزة الاستشعار القابلة للارتداء. ويخضع دمج المعلومات هذا لتحليل مستمر بواسطة خوارزميات الذكاء الاصطناعي، وبلغت ذروتها في عمليات محاكاة تتنبأ بالمسار الصحي للفرد في المستقبل. تزود هذه الأفكار مقدمي الرعاية الصحية والمرضى بمعلومات قابلة للتنفيذ لاتخاذ قرارات مستنيرة.  ",
              digitalTwinHeader3: "الكفاءة",
              digitalTwinPara3:
                "ومع ذلك، فإن فعالية NdHealth تتوقف على تكاملها ضمن شبكة الرقمنة، سواء كانت أطباء أو أجهزة طبية. يضمن هذا النظام البيئي المترابط تكوينات مخصصة ومؤتمتة لتحسين رعاية المرضى وتقليل التناقضات وزيادة الكفاءة. المزايا التي تعود على المرضى عميقة - المراقبة المستمرة للبيانات الصحية، والعرض الشامل للأفكار المستمدة من التقييمات، والتمكين من إدارة الصحة بشكل استباقي من خلال تعديلات نمط الحياة أو التدخلات في الوقت المناسب.",
              digitalTwinPara4:
                "في المواقف الحرجة، مثل حالات الطوارئ، تعمل NdHealth كحليف يقظ، مما يتيح استجابات سريعة من خلال تنبيه الأطراف المعنية، ونقل البيانات الحيوية، وتسهيل الدعم الطبي الفوري. علاوة على ذلك، بالنسبة لمقدمي الرعاية والمتخصصين في الرعاية الصحية، يوفر النموذج الرقمي رؤية شاملة للحالة الصحية للمريض، مما يسهل التشخيص الدقيق وتخطيط العلاج والتدخلات عن بعد المدعومة بالتقنيات المتقدمة تحت إشراف الخبراء. ",
            },
          },

          contactus: {
            contactTitle: {
              contactTitleStart: "الطرق الممكنة",
              contacTitleMiddle: "معنا لاكتشاف ",
              contacTitleLast: "تواصل المذهلة لإعادة تكوين نفسك ",
            },

            contactForm: {
              contactFormTitle: "اتصل بنا",
              username: {
                usernameLabel: "الاسم الكامل",
                usernamePlaceholder: "أدخل أسمك*",
                usernameError1: "لا يمكن أن يكون حقل اسم المستخدم فارغًا",
                usernameError2: "اسم المستخدم يتراوح بين 4 إلى 15 حرفًا",
              },
              phoneNo: {
                phoneNoLabel: "رقم الهاتف",
                phoneNoPlaceholder: "أدخل هاتفك المحمول*",
                phoneNoError1: "رقم الهاتف مطلوب",
                phoneNoError2: "رقم الهاتف يجب أن يكون 10 أرقام",
                phoneNoError3: "رقم الجوال غير صالح",
              },
              email: {
                emailLabel: "بريد إلكتروني",
                emailPlaceholder: "أدخل بريدك الإلكتروني*",
                emailError1: "البريد الالكتروني مطلوب",
                emailError2: "بريد إلكتروني خاطئ",
              },
              city: {
                cityLabel: "مدينة",
                cityPlaceholder: "أدخل مدينتك*",
                cityError1: "لا يمكن أن يكون حقل المدينة فارغًا ",
                cityError2: "يجب أن تكون المدينة أكثر من حرفين",
              },
              category: {
                categoryLabel: "اختر الفئة",
                categoryPlaceholder: "قلقك الصحي",
                categoryError: "مطلوب",
                categoryPrgs: {
                  linkDiabetes: "السكري",
                  linkDyslipidemia: "عسر شحميات الدم",
                  linkPCOD: "PCOD",
                  linkObesity: " بدانة",
                  linkPsoriasis: " صدفية",
                  linkAntiAging: "مكافحة الشيخوخة",
                  linkNAFLD: "NAFLD",
                },
              },
              checkbox: {
                checkboxLabel:
                  "أوافق على أن يتم الاتصال بي من قبل ND Health عبر الاتصال والرسائل النصية القصيرة وWhatsApp والبريد الإلكتروني",
                checkboxError: "مطلوب",
              },
            },
          },
        },
      },
    },

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
