import Hero from "../../Images/Home/Nd health website banner 2.jpg";
// import Hero from "../../Images/Home/hero.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/constant";
import futureImg from "../../Images/Home/8966021.jpg";
import { useEffect, useState } from "react";
import MyImage from "../utils/MyImage";
import MetaInfo from "../Meta/Meta-Info";
import BannerVideo from "../../Images/Home/Banner.mp4";
// import { Programs } from "../utils/Future-common";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const Future = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const { t } = useTranslation();

  const { future, convergence, whatwedoBtn, transformYour } = t("home");

  const { transform, technology, Programs, bookNowBtn } = transformYour;

  useEffect(() => {
    const TimedId = setTimeout(() => {
      nextSlide();
    }, 4000);

    return () => {
      clearTimeout(TimedId);
    };
  }, [currentSlide]);

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 1 ? Programs.length : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === Programs.length ? 1 : prev + 1));
  };

  return (
    <section className="">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"AI Health Solutions: Transform Your Well-Being Today!"}
        pageDescription={
          "Discover how AI transforms health management. Explore cutting-edge solutions and take control of your well-being today. Learn more now!"
        }
        pageKeywords={
          "AI-Powered Health Management, AI-powered disease prediction, artificial intelligence in healthcare,Personalized medicine with AI"
        }
        ogTitle={"AI Health Solutions: Transform Your Well-Being Today!"}
        ogUrl={"https://ndhealth.ai/"}
        ogDescription={
          "Discover how AI transforms health management. Explore cutting-edge solutions and take control of your well-being today. Learn more now!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/Nd%20health%20website%20banner%202.63a7cedb15f80e546591.jpg"
        }
        canonicalUrl={"https://ndhealth.ai/"}
      />
      <div className="flex flex-col">
        {/* <MyImage src={Hero} alt="Hero" className="bg-lightBlue w-full" /> */}

        <video
          muted
          loop
          controls
          className="bg-lightBlue w-full"
          poster={Hero}
        >
          <source src={BannerVideo} type="video/mp4"></source>
        </video>
        {/*********** The Future ******************/}

        <div className="relative flex">
          <img
            src={futureImg}
            className="max-h-[25rem] w-full object-cover"
            alt="futureImg"
          />

          <div
            data-aos="fade-down"
            className="text-center xl:bottom-[10%] xl:right-[18%] lg:bottom-16 lg:right-28 md:right-14 md:bottom-14 sm:bottom-12 sm:right-14 xs:bottom-8 xs:right-4 right-2 bottom-0 rounded absolute"
          >
            {/* <div
            className="text-center rounded h-96 w-full object-cover relative"
            style={{
              background: `url(${futureImg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col absolute bottom-[5%] right-[15%]"> */}
            <h1 className="lg:text-5xl md:text-2xl sm:text-xl text-sm font-semibold text-white uppercase">
              {future}
              {/* <span className="text-primaryai"> </span> */}
            </h1>

            <p className="xl:py-2 xs:text-xs md:text-sm text-[10px] font-semibold text-white">
              {convergence}
            </p>

            <Link to="/whatwedo" onClick={scrollToTop}>
              <button className="hover:scale-105 lg:py-3 lg:px-8 md:py-1 md:px-4 xl:my-5 lg:my-2 xxs:px-2 xs:py-1 text-sm font-medium styleButton border border-primary">
                {whatwedoBtn}
              </button>
            </Link>
            {/* </div> */}
          </div>
        </div>

        <div className="flex items-center justify-center gap-3 text-xl sm:text-base text-mediumBlue font-medium py-5">
          <p className="xs:px-5 sm:text-xl text-base text-center">
            {transform}
            <span className="font-bold text-gradient">
              {" "}
              Artificial Intelligence{" "}
            </span>
            {technology}
          </p>
        </div>

        {/************* Programs Section  *********************/}

        <div
          data-aos="fade-up"
          className="flex flex-col bg-lightGray md:p-5 p-1 rounded-xl xxs:flex-row md:mx-10 overflow-hidden justify-around relative"
        >
          {Programs &&
            Programs.map((programSection) => {
              const { id, title, desc, path, ImageUrl } = programSection;

              return (
                // <div
                //   key={id}
                //   className="lg:w-64 lg:h-64 md:w-52 md:h-52 xs:w-44 xs:h-44 w-36 h-36 border border-lightBlue rounded flex flex-col items-center justify-center lg:gap-8 md:gap-3 xs:gap-5 gap-2 hover:shadow-md hover:scale-105 duration-500 hover:bg-lightGray"
                // >
                <div
                  className={`flex justify-center items-center ${
                    id === currentSlide ? "" : "hidden"
                  }`}
                  key={id}
                  style={{
                    transition: "transform 0.5s ease-in-out",
                    transform:
                      id === currentSlide
                        ? "translateX(0)"
                        : "translateX(-100%)", // Slide from left to right
                  }}
                >
                  <div className="rounded flex md:flex-row flex-col items-center w-fit bg-center">
                    <img
                      src={ImageUrl}
                      loading="lazy"
                      className="lg:h-96 lg:w-[35rem] border border-mediumGray xxs:h-80 h-64 w-full rounded-lg object-cover"
                      alt={ImageUrl}
                    />
                    <div className="flex flex-col xs:gap-3 gap-2 lg:p-10 p-5 items-center bg-lightGray rounded-b-lg sm:py-3 py-1 w-full">
                      <h5 className="md:text-lg sm:text-base font-semibold text-sm">
                        {title}
                      </h5>
                      <p className="xl:text-base text-sm">{desc}</p>

                      <Link to={path}>
                        <button
                          onClick={scrollToTop}
                          className="md:px-4 md:py-2 xxs:px-2 xxs:py-1 p-1 bg-primary rounded-lg text-white hover:bg-white hover:text-primary transform duration-500 font-medium"
                        >
                          {bookNowBtn}
                        </button>
                      </Link>
                    </div>

                    <div className="absolute sm:-left-4 left-0 top-1/2 transform -translate-y-1/2 md:block hidden">
                      <button
                        onClick={prevSlide}
                        className="bg-white hover:bg-primary hover:text-white rounded-full sm:p-3 p-2 duration-500"
                      >
                        <HiOutlineChevronLeft size={20} />
                      </button>
                    </div>
                    <div className="absolute sm:-right-4 right-0 top-1/2 transform -translate-y-1/2 md:block hidden">
                      <button
                        onClick={nextSlide}
                        className="bg-white hover:bg-primary hover:text-white rounded-full sm:p-3 p-2 duration-500"
                      >
                        <HiOutlineChevronRight size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Future;
