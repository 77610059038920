import { Link } from "react-router-dom";
import OkGif from "../../Images/LandingPage/Animation - 1710407479002.gif";

const ThankyouLand = () => {
  return (
    <div className="flex flex-col bg-white font-semibold items-center justify-center min-h-[100vh]">
      <img
        src={OkGif}
        alt="OkGif"
        className="md:w-24 w-14 transition-opacity duration-1000"
      />
      <h1 className="lg:text-6xl md:text-3xl text-2xl">Thank You</h1>
      <p className="py-5 lg:text-2xl md:text-base text-sm md:px-0 px-4">
        We have received your request and we will get back to you soon.
      </p>
      <div className="flex gap-5 my-5">
        <Link to={"/landingpage"}>
          <button className="border border-lightGray px-6 py-2 rounded-lg hover:border-mediumBlue hover:text-mediumBlue duration-500 ease-in">
            Home
          </button>
        </Link>
        <Link to={"/"}>
          <button className="border border-lightGray px-6 py-2 rounded-lg hover:bg-mediumBlue hover:text-white duration-500 ease-in">
            Go to Website
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ThankyouLand;
