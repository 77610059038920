import React from "react";
import Loader from "../Loader";

const Shimmer = () => {
  return (
    <section className="">
      <div className="w-full lg:h-96 sm:h-72 h-56 bg-lightGray"></div>
      <div className="relative md:py-20 py-10 grid grid-cols-2 items-center gap-y-10 ">
        {Array(8)
          .fill("")
          .map((_, index) => {
            return (
              <div key={index} className="mx-auto animate-pulse">
                <div className="bg-lightGray lg:w-96 lg:h-52 md:w-66 md:h-44 w-44 h-32 rounded-2xl"></div>
                <div className="md:w-72 md:h-8 w-40 h-2 bg-lightGray rounded-2xl mt-5"></div>
                <div className="md:w-60 md:h-8 w-32 h-2 bg-lightGray rounded-2xl my-4"></div>
                <div className="md:w-44 md:h-8 w-20 h-2 bg-lightGray rounded-2xl"></div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default Shimmer;
