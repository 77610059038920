import psoriasisImg from "../../Images/LandingPage/psoriasis.png";
import dyslipedimiaImg from "../../Images/LandingPage/Dyslipedimia.png";
import diabetesImg from "../../Images/LandingPage/Diabetes.png";
import AgereversalImg from "../../Images/LandingPage/Agereversal.png";
import MetabolicImg from "../../Images/LandingPage/wellness.png";
import NAFLDImg from "../../Images/LandingPage/nafld.png";

const Services = () => {
  return (
    <div className="relative bg-white">
      <div className="text-white lg:my-72 w-full bg-mediumBlue px-5 py-5 lg:py-56 text-center">
        <h2 className="md:text-3xl text-xl font-semibold">
          Which Services Does ND Health Provide?
        </h2>
        <p className="py-5 leading-7 md:text-base text-sm">
          ND Health offers services for various health conditions such as
          diabetes, dyslipidemia, PCOD (Polycystic Ovary Syndrome), metabolic
          age reversal, and Psoriasis, Non-alcoholic Fatty Liver
          Disease,pre-diabetes, Metabolic wellness for preventive care. We
          provide complimentary consultation calls, and our approach is enhanced
          through AI technology.
        </p>
      </div>

      <div className="flex flex-col md:gap-y-5 py-5">
        {/************    First 3 Services   ******************/}

        <div className="flex md:flex-row flex-col gap-y-5 md:gap-y-0 justify-around lg:absolute top-5 ">
          {/*****   Diabetes    *********/}

          <div className="bg-lightWhite hover:bg-lightGray shadow-lg p-4 md:w-[25%] w-full flex flex-col md:flex-col sm:flex-row items-center filter hover:grayscale grayscale-0 rounded duration-500 ease-in">
            <img
              src={diabetesImg}
              alt="diabetesImg"
              className="xl:w-44 xl:h-48 w-36 h-40 object-contain relative md:mx-auto"
            />

            <div>
              <h2 className="xl:text-2xl text-xl text-center pb-4">
                Diabetes{" "}
              </h2>
              <p className="text-gray-500 md:p-0 sm:px-4 px-2">
                Living with diabetes doesn't have to be a challenge.ND Health
                empowers you with knowledge and tools to effectively manage your
                condition. Learn about symptoms, treatment options, and healthy
                lifestyle choices to take control of your health.
              </p>
            </div>
          </div>

          {/*****   Dyslipidemia    *********/}

          <div className="bg-lightWhite hover:bg-lightGray shadow-lg p-4 md:w-[25%] w-full flex flex-col md:flex-col sm:flex-row items-center filter hover:grayscale grayscale-0 rounded duration-500 ease-in ">
            <img
              src={dyslipedimiaImg}
              alt="dyslipedimiaImg"
              className="xl:w-44 xl:h-48 w-36 h-40 object-contain relative mx-auto"
            />
            <div>
              <h2 className="xl:text-2xl text-xl text-center xl:py-4 lg:py-2 pb-4">
                Dyslipidemia{" "}
              </h2>
              <p className="text-gray-500 md:p-0 sm:px-4 px-2">
                Dyslipidemia means imbalanced blood fats. High LDL ("bad")
                cholesterol and triglycerides can clog arteries, raising heart
                disease risk. ND Health can help - explore solutions for a
                healthier you!
              </p>
            </div>
          </div>

          {/*****   Metabolic Age Reversal    *********/}

          <div className="bg-lightWhite hover:bg-lightGray shadow-lg p-4 md:w-[25%] w-full flex flex-col md:flex-col sm:flex-row items-center filter hover:grayscale grayscale-0 rounded duration-500 ease-in ">
            <img
              src={AgereversalImg}
              alt="AgereversalImg"
              className="xl:w-44 xl:h-48 w-36 h-40 object-contain relative mx-auto"
            />
            <div>
              <h2 className="xl:text-2xl text-xl text-center xl:py-4 lg:py-2 pb-4">
                Metabolic Age Reversal{" "}
              </h2>
              <p className="text-gray-500 md:p-0 sm:px-4 px-2">
                Feeling sluggish? ND Health can help! We offer personalized
                plans to improve your metabolic age, a key indicator of cellular
                health. Boost energy, potentially reduce disease risk, and feel
                younger. Visit ND Health to take charge of your health!
              </p>
            </div>
          </div>
        </div>

        {/************    First 3 Services   ******************/}

        <div className="flex md:flex-row flex-col gap-y-5 md:gap-y-0 justify-around lg:absolute bottom-10 ">
          {/*****   NAFLD    *********/}

          <div className="bg-lightWhite hover:bg-lightGray shadow-lg p-4 md:w-[25%] w-full flex flex-col md:flex-col sm:flex-row items-center filter hover:grayscale grayscale-0 rounded duration-500 ease-in">
            <img
              src={NAFLDImg}
              alt="NAFLDImg"
              className="xl:w-44 xl:h-48 w-36 h-40 object-contain relative mx-auto"
            />

            <div>
              <h2 className="xl:text-2xl text-xl text-center xl:py-4 lg:py-2 pb-4">
                Nonalcoholic Fatty Liver Disease{" "}
              </h2>
              <p className="text-gray-500 md:p-0 sm:px-4 px-2">
                Don't let NAFLD silently impact your health. ND Health empowers
                you with knowledge and tools to manage NAFLD and prioritize your
                liver health.
              </p>
            </div>
          </div>

          {/*****   Metabolic    *********/}

          <div className="bg-lightWhite hover:bg-lightGray shadow-lg p-4 md:w-[25%] w-full flex flex-col md:flex-col sm:flex-row items-center filter hover:grayscale grayscale-0 rounded duration-500 ease-in ">
            <img
              src={MetabolicImg}
              alt="MetabolicImg"
              className="xl:w-44 xl:h-48 w-36 h-40 object-contain relative mx-auto"
            />

            <div className="">
              <h2 className="xl:text-2xl text-xl text-center xl:py-4 lg:py-2 pb-4">
                Metabolic Wellness for Prevention{" "}
              </h2>
              <p className="text-gray-500 md:p-0 sm:px-4 px-2">
                Combat fatigue & chronic disease risk with me tabolic wellness.
                It optimizes your metabolism for energy, weight management, &
                well-being. Simple tweaks empower you to own your health.
              </p>
            </div>
          </div>

          {/*****   Psoriasis    *********/}

          <div className="bg-lightWhite hover:bg-lightGray shadow-lg p-4 md:w-[25%] w-full flex flex-col md:flex-col sm:flex-row items-center filter hover:grayscale grayscale-0 rounded duration-500 ease-in">
            <img
              src={psoriasisImg}
              alt="psoriasisImg"
              className="xl:w-44 xl:h-48 w-36 h-40 object-contain relative mx-auto"
            />
            <div>
              <h2 className="xl:text-2xl text-xl text-center xl:py-4 lg:py-2 pb-4">
                Psoriasis
              </h2>
              <p className="text-gray-500 md:p-0 sm:px-4 px-2">
                Itchy, uncomfortable psoriasis? We get it. ND Health offers
                personalized treatment plans to manage your symptoms. Find
                relief and reclaim your comfort.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
