import diabetesImg from "../../Images/diabetes/DiabetesImg.jpg";
import DiabetesBgImg from "../../Images/diabetes/26698.jpg";
import { EnquiryAndAssociatedSection } from "../utils/constant";
import glucosemeterIMG from "../../Images/diabetes/glucose meter.jpg";
import doctorIMG from "../../Images/diabetes/doctor superVisionIMG.jpg";
import diabetesExpertIMG from "../../Images/diabetes/Diabetes Expert.jpg";
import diagnosticTestingIMG from "../../Images/diabetes/diagnostic testing.jpg";
import personalisedIMG from "../../Images/diabetes/personalised.jpg";
import { useContext, useEffect } from "react";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { ContextProvider } from "../../App";
import MetaInfo from "../Meta/Meta-Info";
import Banner from "../utils/Banner";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const Diabetes = () => {
  const { shimmerEffect } = useContext(ContextProvider);

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { t } = useTranslation();

  const { title, mainDesc, Transform, Results, Plan } = t("diabetes");

  const {
    subHeading1,
    subPara1,
    subHeading2,
    subPara2,
    subHeading3,
    subPara3,
    subHeading4,
    subPara4,
  } = Transform;

  const { resultsHeader, subHeader, DiabetesResults } = Results;

  const {
    header,
    subHeader1,
    planSubPara1,
    subHeader2,
    planSubPara2,
    subHeader3,
    planSubPara3,
    subHeader4,
    planSubPara4,
    subHeader5,
    planSubPara5,
  } = Plan;

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <div className="relative">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"Diabetes Reversal Program with AI | ND Health"}
        pageDescription={
          "Manage diabetes with our AI-powered program. Reduce medication, lower blood sugar & lose weight. Get started today!"
        }
        pageKeywords={
          "Diabetes Reversal Program,Diabetes reversal plan,artificial intelligence in diabetes care, ai diabetes management "
        }
        canonicalUrl={"https://ndhealth.ai/programs/diabetes-reversal-program"}
        // Open Graph Tags

        ogTitle={"Diabetes Reversal Program with AI | ND Health"}
        ogUrl={"https://ndhealth.ai/programs/diabetes-reversal-program"}
        ogDescription={
          "Manage diabetes with our AI-powered program. Reduce medication, lower blood sugar & lose weight. Get started today!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/DiabetesImg.327e2b9491d5ce838274.jpg"
        }
        ogSecureUrl={
          "https://ndhealth.ai/static/media/DiabetesImg.327e2b9491d5ce838274.jpg"
        }
        // Twitter Tags
        TwTitle={"Diabetes Reversal Program with AI | AND Health"}
        TwDesc={
          "Manage diabetes with our AI-powered program. Reduce medication, lower blood sugar & lose weight. Get started today!!"
        }
        TwImg={
          "https://ndhealth.ai/static/media/DiabetesImg.327e2b9491d5ce838274.jpg"
        }
      />
      <div className="flex flex-col gap-10 z-0 min-h-screen bg-mediumBlue text-white">
        {/************       Hero Section      *******************/}

        <Banner title={title} description={mainDesc} ImageUrl={diabetesImg} />

        <div
          className="flex flex-col lg:gap-10 gap-5 px-2 py-10 xxs:text-sm text-xs sm:text-base items-center bg-cover bg-no-repeat"
          style={{
            background: `url(${DiabetesBgImg})`,
            backgroundPosition: "center",
          }}
        >
          <div className="flex gap-5 lg:gap-16 flex-col md:flex-row">
            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold ">{subHeading1}</h3>
              <p className=" inline-block">{subPara1}</p>
            </div>

            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold">{subHeading2}</h3>
              <p className="">{subPara2}</p>
            </div>
          </div>

          <div className="flex gap-5 lg:gap-16 flex-col md:flex-row ">
            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold">{subHeading3}</h3>
              <p className="">{subPara3}</p>
            </div>
            <div
              data-aos="fade-up"
              className="md:w-1/2 md:min-h-52 bg-black/50 p-3 rounded-xl"
            >
              <h3 className="sm:text-xl py-2 font-semibold">{subHeading4}</h3>
              <p className="">{subPara4}</p>{" "}
            </div>
          </div>
        </div>

        {/**************** Results Section **************************/}

        <div>
          <div className="text-center">
            <h2 className="md:text-4xl text-2xl font-semibold">
              {resultsHeader}
            </h2>
            <p className="text-xl py-8">{subHeader}</p>
          </div>
          <div className="flex flex-wrap gap-y-5 justify-around">
            {DiabetesResults.map((result, index) => {
              const { imgUrl, heading, subHeading } = result;
              return (
                <div
                  data-aos="fade-down"
                  key={index}
                  className="flex flex-col items-center gap-2 xl:gap-5 w-52 hover:scale-105 duration-500"
                >
                  <div className="border-2 border-Orange w-40 h-40 flex flex-col items-center justify-center rounded-full">
                    <img src={imgUrl} alt={imgUrl} className="w-22 h-24" />
                  </div>
                  <h2 dir="ltr" className="text-4xl font-bold">
                    {heading}
                  </h2>
                  <h2 className="font-medium text-center">{subHeading}</h2>
                </div>
              );
            })}
          </div>
        </div>

        {/********************* What's in the plan Section **************************/}

        <div className="bg-darkBlue md:p-10">
          <h2 className="md:text-4xl text-2xl py-5 md:py-0 font-semibold text-center">
            {header}
          </h2>
          <div className="flex items-center justify-center">
            <div className="bg-lightBlue sm:w-3/4 sm:p-5 sm:m-5 lg:m-10 rounded flex flex-col gap-5 items-center text-black">
              {/****** Continuous Glucose Monitor   ********/}

              <div data-aos="fade-up" className="lg:w-[60%] min-w-[50%] flex">
                <img
                  src={glucosemeterIMG}
                  className="w-[30%] min-h-40 object-cover rounded-l-2xl"
                  alt="glucosemeterIMG"
                />
                <div className="bg-white hover:bg-lightGray duration-500 rounded-r-2xl w-full px-2 sm:px-5 flex flex-col gap-5 justify-center ">
                  <h2 className="sm:text-xl text-md">{subHeader1}</h2>
                  <p className="text-darkBlue/55 text-sm xxs:text-base">
                    {planSubPara1}
                  </p>
                </div>
              </div>

              {/***** On-demand doctor supervision   ********/}

              <div
                data-aos="fade-up"
                className="lg:w-[60%] min-w-[50%] flex flex-row-reverse"
              >
                <img
                  src={doctorIMG}
                  className="w-[30%] min-h-40 object-cover rounded-r-2xl border border-lightGray"
                  alt="doctorIMG"
                />
                <div className="bg-white hover:bg-lightGray duration-500 rounded-l-2xl w-full px-2 sm:px-5 flex flex-col gap-5 justify-center">
                  <h2 className="sm:text-xl text-md">{subHeader2}</h2>
                  <p className="text-darkBlue/55 text-sm xxs:text-base">
                    {planSubPara2}
                  </p>
                </div>
              </div>

              {/****** Dedicated Diabetes Expert   *********/}

              <div data-aos="fade-up" className="lg:w-[60%] min-w-[50%] flex">
                <img
                  src={diabetesExpertIMG}
                  className="w-[30%] min-h-40 object-cover rounded-l-2xl border border-lightGray"
                  alt="diabetesExpertIMG"
                />
                <div className="bg-white hover:bg-lightGray duration-500 rounded-r-2xl w-full px-2 sm:px-5 flex flex-col gap-5 justify-center ">
                  <h2 className="sm:text-xl text-md">{subHeader3}</h2>
                  <p className="text-darkBlue/55 text-sm xxs:text-base">
                    {planSubPara3}
                  </p>
                </div>
              </div>

              {/***** Comprehensive diagnostic testing  *******/}

              <div
                data-aos="fade-up"
                className="lg:w-[60%] min-w-[50%] flex flex-row-reverse"
              >
                <img
                  src={diagnosticTestingIMG}
                  className="w-[30%] min-h-40 object-cover rounded-r-2xl border border-lightGray"
                  alt="diagnosticTestingIMG"
                />
                <div className="bg-white hover:bg-lightGray duration-500 rounded-l-2xl w-full px-2 sm:px-5 flex flex-col gap-5 justify-center ">
                  <h2 className="sm:text-xl text-md">{subHeader4}</h2>
                  <p className="text-darkBlue/55 text-sm xxs:text-base">
                    {planSubPara4}
                  </p>
                </div>
              </div>

              {/***** Personalised plans   ********/}

              <div data-aos="fade-up" className="lg:w-[60%] min-w-[50%] flex">
                <img
                  src={personalisedIMG}
                  className="w-[30%] min-h-40 object-cover rounded-l-2xl border border-lightGray"
                  alt="personalisedIMG"
                />
                <div className="bg-white hover:bg-lightGray duration-500 rounded-r-2xl w-full px-2 sm:px-5 flex flex-col gap-5 justify-center">
                  <h2 className="sm:text-xl text-md">{subHeader5}</h2>
                  <p className="text-darkBlue/55 text-sm xxs:text-base">
                    {planSubPara5}
                  </p>
                </div>
              </div>

              {/********    EnquiryAndAssociatedSection    ********/}

              <EnquiryAndAssociatedSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diabetes;
