import twinHealthLogo from "../../Images/LandingPage/1.png";
import ImgAI from "../../Images/LandingPage/What is.jpg";

const StallNdHealth = () => {
  return (
    <div className="flex flex-col gap-5">
      {/*************   What is ND Health AI?    ****************/}

      <div className="bg-mediumBlue flex items-center justify-center md:p-10 p-4 rounded-tr-3xl rounded-bl-3xl md:mx-5 mx-2">
        <div className="bg-white p-5 rounded-tl-3xl rounded-br-3xl flex md:flex-row flex-col justify-around items-center">
          <div className="md:w-1/2 w-full">
            <h2 className="lg:text-3xl text-xl font-semibold">
              What is ND Health ?
            </h2>
            <p className="py-5 leading-7 md:text-base text-sm inline-block text-mediumGray">
              ND Health has association with{" "}
              <img
                src={twinHealthLogo}
                className="md:w-12 w-10 mx-2 inline-block"
                alt="twinHealthLogo"
              />{" "}
              to offer revolutionary personalized healthcare solutions powered
              by Artificial Intelligence (AI). Their innovative approach aims to
              unlock your full potential by addressing chronic health concerns
              at the root cause.
            </p>
          </div>
          <img
            src={ImgAI}
            alt="ImgAI"
            className="lg:w-96 lg:h-60 md:w-72 md:h-56 object-cover rounded"
          />
        </div>
      </div>

      {/*****************    Why Do You Choose ND Health?    *******************/}

      <div className="bg-lightWhite p-5 flex flex-col gap-5">
        <div className="p-5">
          <h2 className="md:text-3xl text-xl font-semibold">
            Why Do You Choose ND Health?
          </h2>
          <p className="py-5 text-mediumGray sm:text-base text-sm">
            It is seeking a healthcare experience that revolves around you, not
            a generic diagnosis. ND Health in association with TWIN offers a
            personalized approach powered by Artificial Intelligence (AI) that
            sets them apart.
          </p>
          <p className="text-mediumGray">
            In search of a healthcare experience that revolves entirely around
            your individual needs, rather than providing a one-size-fits-all
            diagnosis? ND Health, in Association with TWIN, introduces a
            revolutionary approach empowered by Artificial Intelligence (AI)
            that distinguishes them in the industry. By harnessing the
            capabilities of AI, they offer a tailored journey that prioritizes
            your well-being and ensures that every aspect of your healthcare
            experience is uniquely crafted to suit you. Gone are the days of
            generic treatments; with ND Health and TWIN, you can expect nothing
            less than a personalized healthcare journey that places you at the
            forefront. It's time to embrace a new era of healthcare—one where
            your needs dictate the course of action, and where cutting-edge
            technology works tirelessly to enhance your overall well-being.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between md:justify-around items-center">
          {/* Personalized Plans */}

          <div className="sm:w-1/4 w-full">
            <div className="flex items-center gap-5">
              <h2 className="xl:text-7xl text-5xl font-semibold">1</h2>
              <p className="text-mediumBlue xl:text-lg md:text-base text-sm font-semibold">
                Personalized Plans for Your Unique Needs
              </p>
            </div>
            <p className="py-3 text-mediumGray xl:text-base text-sm">
              Not a One-Size-Fits-All: Ditch generic solutions. ND Health
              leverages to create customized healthcare plans tailored
              specifically to you.
            </p>
          </div>

          {/* Target the Root Cause */}

          <div className="sm:w-1/4 w-full">
            <div className="flex items-center gap-5">
              <h2 className="xl:text-7xl text-5xl font-semibold">2</h2>
              <p className="text-mediumBlue xl:text-lg md:text-base text-sm font-semibold">
                The Whole-Body Digital Twin Approach
              </p>
            </div>
            <p className="py-3 text-mediumGray xl:text-base text-sm">
              Not Just Symptoms: The Whole-Body Digital Twin technology goes
              beyond treating symptoms. It identifies the underlying issues
              causing chronic health concerns.
            </p>
          </div>

          {/* Unlock Your Potential */}

          <div className="sm:w-1/4 w-full">
            <div className="flex items-center gap-5">
              <h2 className="xl:text-7xl text-5xl font-semibold">3</h2>
              <p className="text-mediumBlue xl:text-lg md:text-base text-sm font-semibold">
                Experience the Benefits of Personalized ND Health Plans{" "}
              </p>
            </div>
            <p className="py-3 text-mediumGray xl:text-base text-sm">
              ND Health personalized plans may lead to reduced reliance on
              medication, increased energy levels, and an overall improvement in
              well-being.
            </p>
          </div>
        </div>
        <p className="text-center sm:text-base text-sm">
          <span className="text-error">*</span> Ready to take control of your
          health with a future-focused approach? Explore ND Health and see if
          AI-powered solutions can unlock your path to optimal well-being.
        </p>

        {/************   In Association With   ****************/}

        <div className="flex gap-5 items-center justify-center">
          <p className="text-sm ">In Association With</p>
          <img
            src={twinHealthLogo}
            className="md:w-16 w-12"
            alt="twinHealthLogo"
          />
        </div>
      </div>
    </div>
  );
};

export default StallNdHealth;
