import { Link } from "react-router-dom";
import MetaInfo from "../Meta/Meta-Info";

const PrivacyAndPolicy = () => {
  return (
    <section className="border-[1rem] sm:border-[3rem] border-lightBlue p-4 sm:p-8 flex flex-col gap-10  md:text-base text-sm">
      {/* Meta PageTitle and PageDescription */}

      <MetaInfo
        pageTitle={"ND Health AI Privacy Policy for Data Protection"}
        pageDescription={
          "Understand ND Health AI's privacy policy and how we protect your data. Learn about our data practices and user privacy commitments."
        }
        pageKeywords={"ND Health AI privacy policy"}
        canonicalUrl={"https://ndhealth.ai/privacy-policy"}
      />

      <h1 className="md:text-5xl text-2xl font-medium text-center sm:py-5 font-poppins">
        Privacy Policy
      </h1>
      <div>
        <p className="text-mediumGray">
          At New Directions Media, ('Ndhealth.ai,' 'We,' 'Us,' 'Our'), we
          understand that you, as a user ('You,' 'Your', 'User(s)'), value the
          privacy of your personal information. We take your privacy seriously
          and are committed to protecting it. Please read the following to
          understand how we collect, use, and share your information. By
          visiting or using our website "ndhealth.ai and any other linked pages,
          features, content, mobile applications, or services offered by us
          (collectively, the 'Mobile App'), or by using the Medical Services (as
          defined in our Terms of Service) in any manner, you acknowledge that
          you accept and are subject to the practices and policies outlined in
          this privacy policy.
        </p>
      </div>

      {/*******************    Privacy Policy     ***************************/}
      <div>
        <h3 className="md:text-2xl text-xl pb-5">
          This Privacy Policy is published in compliance with, among others:
        </h3>
        <ul className="flex flex-col gap-2 text-mediumGray ">
          <li>- Section 43A of the Information Technology Act, 2000;</li>
          <li>
            - Regulation 4 of the Information Technology (Reasonable Security
            Practices and Procedures and Sensitive Personal Information) Rules,
            2011 (“SPDI Rules”);
          </li>
          <li>
            - Regulation 3 of the Information Technology (Intermediary
            Guidelines and Digital Media Ethics Code) Rules, 2021.
          </li>
        </ul>
      </div>

      {/*******************    What does this privacy policy cover?     ***************************/}
      <div className="">
        <h3 className=" text-2xl pb-5">What does this privacy policy cover?</h3>
        <div className="text-mediumGray flex flex-col gap-5 ">
          <p>
            This privacy policy covers the treatment of personally identifiable
            information (“Personal Information”) that we gather when you access
            or use our Mobile App and Medical Services. This policy does not
            apply to the practices of companies that we do not own or control
            (including our franchisees), or to individuals that we do not employ
            or manage.
          </p>
          <p>
            By visiting our Platform, downloading the Mobile App, availing
            Medical Services via the Platform, you consent to the collection,
            use, sharing, disclosure, and processing of your personal
            information by us and each of the sellers/service providers on the
            Platform (collectively, ‘We’, ‘Us’, ‘Our’) in accordance with the
            terms of this Privacy Policy or as otherwise required by, or
            permitted under, applicable law.
          </p>
          <p>
            By using the website and/or registering yourself on the Mobile App,
            you authorize us to contact you via email, phone call, or SMS and
            offer you our services (irrespective of your registration under DND
            or DNC or NCPR service), imparting product knowledge, offer
            promotional offers (including offers by associated third parties),
            for which reasons, personally identifiable information may be
            collected from you. This Privacy Policy covers the treatment of
            personally identifiable information that we collect when you use our
            services.
          </p>
          <p>
            We do not knowingly collect or solicit personal information from
            anyone under the age of 18 or knowingly allow such persons to
            register for the Medical Services. If you are under 18, please do
            not attempt to register for the Medical Services or send any
            information about yourself to us, including your name, address,
            telephone number, email address, or provide your biometric
            information. No one under age 18 may provide any personal
            information to us or on the Medical Services. In the event that we
            learn that we have collected personal information from an individual
            under age 18 without verification of parental consent, we will
            delete that information as quickly as possible. If you believe that
            we might have any information from or about an individual under 18,
            please contact us at{" "}
            <span className="text-[#000] hover:underline">
              <Link to={"mailto:enquiries@ndhealth.ai.com"} target="_blank">
                enquiries@ndhealth.ai.com
              </Link>
            </span>{" "}
            or our Grievance Officer.
          </p>
        </div>
      </div>

      {/*******************    What information does Ndhealth.ai collect?     ***************************/}

      <div className="flex flex-col gap-5 ">
        <h3 className="md:text-2xl text-xl pb-5">
          What information does Ndhealth.ai collect?
        </h3>
        <p className="text-mediumGray ">
          We gather various types of personal information from you, as explained
          more fully below. We may use this personal information to personalize
          and improve our services, to allow you to set up a user account and
          profile, to contact users, to fulfill your requests for certain
          products and services, to analyze how users utilize the Mobile App and
          the Medical Services, and as otherwise set forth in this privacy
          policy. We may share certain types of personal information with third
          parties. We collect the following types of information:
        </p>
        <div className="text-mediumGray">
          {/******* Information You provide to us: ***********/}

          <div className="">
            <h4 className="text-black md:text-2xl text-xl pb-2">
              Information You provide to us:
            </h4>
            <h6 className="text-black md:text-xl text-base pb-3">
              We receive and store any information you knowingly provide to us,
              including:
            </h6>
            <ul className="flex flex-col gap-5 ">
              <li>
                - Personal information collected at the time of your
                registration on the Mobile App/website, including your name,
                username, email address, gender, birth date, height, location,
                and we may collect other activity or health-related information
                as our Medical Services are further developed;
              </li>
              <li>
                - Personal information and SPDI (where necessary) collected for
                the provision of the Medical Services on the Mobile App/website,
                including your nutrition data, passwords, workout-related data
                (including outputs from your device camera for “live energy”
                tracking or conducting live workout sessions), physical
                activity, photographs, biometric information, sleep habits; and
                other sleep, activity, or health-related information and
                insights for our Medical Services;
              </li>
              <li>- Bank details for payment processing;</li>
              <li>
                - Details about your health, mental wellbeing, disabilities, or
                other health-related information such as vaccination status,
                past medical history, all collected for the safety of our other
                users and premises that they access, the discharge our duties as
                an employer, or to provide certain benefits;
              </li>
              <li>
                - Data in your mobile address book to help users find other
                users who have enabled ‘squads’ and set their profiles as
                public. We do not otherwise process information from the address
                book for any other purpose.
              </li>
              <li></li>
            </ul>
            <p>
              We shall obtain consent in writing through letter, fax, email, or
              electronically from the provider of the sensitive personal data or
              information regarding the purpose of usage before collection of
              such information.
            </p>
            <p className="py-5">
              You can choose not to provide us with certain information, but
              then you may not be able to register with us or to take advantage
              of some of our features / Medical Services. We may anonymize your
              personal information so that you cannot be individually
              identified, and provide that information to our partners. For
              example, you may tell us that you are female and sleep an average
              of 6 hours per night; we may combine this information with content
              received from our other users, and disclose to our partners that
              on the whole, our male users sleep more hours per night than our
              female ones.
            </p>
          </div>
        </div>

        {/************* Information collected automatically: **********************/}

        <div>
          <h3 className="md:text-2xl text-xl pb-5">
            Information collected automatically:
          </h3>
          <div className="flex flex-col gap-4 text-mediumGray ">
            <p>
              Whenever you interact with our Mobile App, we automatically
              receive and record information on our server logs from your
              browser including your IP address, ‘cookie’ information, and the
              page you requested. ‘Cookies’ are identifiers we transfer to your
              computer or mobile device that allow us to recognize your browser
              or mobile device and tell us how and when pages in our Mobile App
              are visited and by how many people. You may be able to change the
              preferences on your browser or mobile device to prevent or limit
              your computer or device’s acceptance of cookies, but this may
              prevent you from taking advantage of our Mobile App’s best
              features.
            </p>
            <p>
              When we collect usage information (such as the numbers and
              frequency of visitors to the Mobile App), we only use this data in
              aggregate form, and not in a manner that would identify you
              personally.
            </p>
            <p>
              For example, this aggregate data tells us how often users use
              parts of the Mobile App, so that we can make the Mobile App
              appealing to as many users as possible. We may also provide this
              aggregate information to our third-party partners; our partners
              may use such information to understand how often people use our
              Mobile App, so that they, too, can provide you with an optimal
              online experience. We never disclose aggregate information to a
              partner in a manner that would identify you personally.
            </p>
            <p>
              If you are using our Medical Services, you understand and
              acknowledge that any information, data, or readings by virtue of
              your use of the Medical Services), or collected from your use
              thereof, will automatically be uploaded to your account with us.
              To the extent these automatically collected readings can be used
              to personally identify you, these readings are personal
              information for the purposes of this privacy policy.
            </p>
          </div>
        </div>

        {/************ Purpose and Use of information: *******************/}

        <div className="">
          <h3 className="md:text-2xl text-xl pb-5">
            Purpose and Use of information:
          </h3>
          <div className="text-mediumGray">
            <p>
              We, our affiliates, and/or the sellers or service providers on the
              Mobile App may access and use your Personal Information and SPDI
              for the following purposes:
            </p>
            <ul className="flex flex-col gap-3 pt-5">
              <li>
                - General business and operating purposes, such as the
                administration, management, and operation of the business,
                including client/user interaction, responding to requests,
                customizing, and improving our Services;
              </li>
              <li>- For payment, billing, and invoicing related purposes;</li>
              <li>
                - To generate aggregated data to prepare insights (not being
                SPDI) to enable us to understand patterns and trends with a view
                to learning more about your preferences or other
                characteristics;
              </li>
              <li>
                - Compliance with our obligations under applicable laws and
                other regulations, including to protect against and prevent
                fraud and other legal or information security risks; and
              </li>
              <li>
                - To serve other purposes for which we provide specific notice
                at the time of collection, and as otherwise authorized or for
                any other lawful purpose
              </li>
            </ul>
          </div>
        </div>

        {/******************  Delete targeting cookies: *****************/}

        <div className="">
          <h3 className="md:text-2xl text-xl pb-5">
            Delete targeting cookies:
          </h3>
          <p className="text-mediumGray">
            Your interest profile can be removed by deleting Your browser’s
            cookies.
          </p>
          <div>
            <h4 className="md:text-xl text-base">
              Information stored on cookies:
            </h4>
            <p className="text-mediumGray py-4">
              The cookie information stored on the User’s hard drive is: (i)
              User segment hits or information on a specific product, service,
              brand, or model in which the User has shown interest during its
              visit to a certain website and (ii) time and date stamp of the
              latest update of the User profile.
            </p>
            <p className="text-mediumGray">
              If the cookie is deleted by the User, all profile data is removed.
              For the sake of clarity, no segments relating to information which
              Ndhealth.ai considers sensitive have been or will be created, such
              as segments relating to political opinions, religious beliefs,
              physical or mental health conditions, or sexual life. Further,
              Ndhealth.ai is very sensitive to the issue of children’s privacy
              and marketing directed to children. No segments are intended to be
              established for the profiling of children.
            </p>
          </div>
        </div>

        {/************ E-mail and other communications: ****************/}

        <div>
          <h3 className="md:text-2xl text-xl pb-5">
            E-mail and other communications:
          </h3>
          <div className="text-mediumGray flex flex-col gap-4">
            <p>
              We may contact you, by email or other means; for example, we or
              persons we share Your Personal Information with may send you
              promotional offers on behalf of other businesses or communicate
              with you about your use of the Mobile App or the Product. Also, we
              may receive a confirmation when you open an email from us. This
              confirmation helps us make emails more interesting and improve our
              service. If you do not want to receive email or other mail from
              us, please indicate your preference by emailing us at{" "}
              <span className="text-[#000] hover:underline">
                <Link to={"mailto:enquiries@ndhealth.ai.com"} target="_blank">
                  enquiries@ndhealth.ai.com
                </Link>
              </span>{" "}
              or contacting our Grievance Officer.
            </p>
            <p>
              Will Ndhealth.ai share any of the personal information it
              receives?
            </p>
            <p>
              We neither rent nor sell Your personal information in personally
              identifiable form to anyone. We share Your personal information in
              personally identifiable form with third parties only as described
              below.
            </p>
            <p>
              - Affiliated businesses and third-party websites We do not
              control: In certain situations, businesses or third-party websites
              we’re affiliated with, may sell items or provide services to You
              through the Mobile App (either alone or jointly with us). You can
              recognize when an affiliated business is associated with such a
              transaction or service, and we will share Your personal
              information with that affiliated business only to the extent that
              it is related to such transaction or service.
            </p>
            <p>
              - Agents/third-party service providers: We employ other
              third-party companies and people to perform tasks on our behalf
              and need to share Your information with them to provide Medical
              Services to You. Unless we tell you differently, these third party
              appointed by us do not have any right to use the personal
              information we share with them beyond what is necessary to assist
              us.
            </p>
            <p>
              - User submissions: Any content or personal information that you
              voluntarily disclose online in a manner other users can view (on
              discussion boards, in messages and chat areas, etc.) becomes
              publicly available, and can be collected and used by others.
            </p>
            <p>
              - Business transfers: We may choose to buy or sell assets. In
              these types of transactions, customer information is typically one
              of the business assets that are transferred. Also, if we (or
              substantially all of our assets) are acquired, or if we go out of
              business or enter bankruptcy, personal information would be one of
              the assets transferred to or acquired by a third party.
            </p>
            <p>
              - Protection of Ndhealth.ai and others: We may release personal
              information when we believe in good faith that release is
              necessary to comply with laws; enforce or apply our conditions of
              use and other agreements; or protect the rights, property, or
              safety of Ndhealth.ai, our employees, our users, or others. This
              includes exchanging information with other companies and
              organizations for fraud protection and credit risk reduction.
            </p>
            <p>
              - Research and Analytical purposes: We may share Your personal
              information (though in an anonymized manner only) for certain
              research, publishing, and / or analytical purposes.
            </p>
            <p>
              - With Your consent: Except as set forth above, you will be
              notified when your personal information may be shared with third
              parties, and will be able to prevent the sharing of this
              information.
            </p>
          </div>
        </div>

        {/************ Transfer of SPDI outside India: ******************/}

        <div>
          <h3 className="md:text-2xl text-xl pb-5">
            Transfer of SPDI outside India:{" "}
          </h3>
          <div className="text-mediumGray">
            <p>
              Subject to applicable law, we may at our sole discretion, transfer
              sensitive personal data or information (as defined in SPDI Rules),
              to any other body corporate or a person in India, or located in
              any other country, that ensures the same level of data protection
              that is adhered to by us as provided for under the SPDI Rules and
              that such transfer shall be allowed only if it is necessary for
              the performance of the lawful contract between us and provider of
              information or where such person has consented to data transfer.
            </p>

            <p className="py-4">
              By using the Services and Medical Services, you accept the terms
              hereof and hereby consent to the storage and processing of
              personal information and SPDI by third parties and in any of
              location within or outside India. We will make best efforts to
              ensure that the third party or the location to which the SPDI is
              transferred affords same level of data protection as would be
              afforded under Indian law.
            </p>

            <p>
              By continuing to use the Services and the Medical Services, you
              provide your consent for transfer, sharing, and disclosure of
              personal information or SPDI by us in accordance with this Privacy
              Policy.
            </p>
          </div>
        </div>

        {/********** Is personal information about me secure? *************/}

        <div>
          <h3 className="md:text-2xl text-xl pb-5">
            Is personal information about me secure?
          </h3>
          <div className="text-mediumGray flex flex-col gap-4">
            <p>
              Your account is protected by a password for your privacy and
              security. You need to prevent unauthorized access to your account
              and personal information by selecting and protecting your password
              appropriately and limiting access to your computer or device and
              browser by signing off after you have finished accessing your
              account.
            </p>

            <p>
              We will put in place measures to ensure that your information in
              our possession or under our control is destroyed and/or anonymized
              reasonable after: (a) the purposes for which your information was
              collected is no longer being served by the retention of such
              information; and (b) retention is no longer necessary under any
              applicable law. Your data may be anonymized, de-identified, and/or
              aggregated, and the resulting data may be held by us, our
              affiliates, service providers, and sellers on the Mobile App,
              associates, agents, representatives, and other authorized third
              parties, for as long as necessary for us to provide the services
              effectively or for other purposes.
            </p>

            <p>
              We adopt reasonable security measures internally to ensure that
              any information collected from you shall be used for the purposes
              for which it has been collected. We will not publish the sensitive
              personal data or information. Further, we adopt reasonable
              measures to ensure that any third party receiving the sensitive
              personal data or information from us shall not disclose it
              further.
            </p>

            <p>
              We endeavor to protect the privacy of your account and other
              personal information we hold in our records, but we cannot
              guarantee complete security. Unauthorized entry or use, hardware
              or software failure, and other factors, may compromise the
              security of user information at any time.
            </p>

            <p>
              Mobile App may contain links to other sites. We are not
              responsible for the privacy policies and/or practices on other
              sites. When following a link to another site you should read that
              site’s privacy policy.
            </p>

            <p>
              Note: However, as effective as encryption technology is, no
              security system is impenetrable. We cannot guarantee the security
              of our database, nor can we guarantee that information you supply
              won’t be intercepted while being transmitted to us over the
              Internet. Any transmission of information by you to our Mobile App
              or otherwise is at your own risk.
            </p>
          </div>
        </div>

        {/***************  Legally Mandated Disclosures: ******************/}

        <div>
          <h3 className="md:text-2xl text-xl pb-5">
            Legally Mandated Disclosures:
          </h3>
          <div className="text-mediumGray">
            <p>
              Disclosure of sensitive personal data or information may be
              necessary for complying with legal obligations in some cases. In
              such events where we may be compelled by law to disclose the
              sensitive personal data or information to a Government agency, we
              shall comply with such demand, provided that the information shall
              be shared, without obtaining prior consent from the provider of
              information, with Government agencies mandated under the law to
              obtain information including sensitive personal data or
              information for the purpose of verification of identity, or for
              prevention, detection, investigation including cyber incidents,
              prosecution, and punishment of offenses. The Government agency
              shall send a request in writing to us stating clearly the purpose
              of seeking such information.
            </p>
            <p className="py-3">What personal information can I access?</p>
            <p>
              Through Your account settings, you may access, and, in some cases,
              edit or delete the following information You’ve provided to us:
            </p>
            <ul className="py-4">
              <li>- name and password</li>
              <li>- email address</li>
              <li>
                - user profile information, including your height, weight, and
                location
              </li>
              <li>- any readings, data uploaded with us.</li>
            </ul>
            <p>
              The information you can view and update may change as the Mobile
              App changes. Please note that any information that is
              automatically uploaded from a Product to the Mobile App, or
              collected otherwise from you by us, cannot be later changed or
              updated. We store Your personal information (including SPDI) until
              You require and inform us to delete the same.
            </p>
            <p className="py-4">
              We will redress your grievances and concerns surrounding the usage
              of Services or Medical Services through Mobile App within
              specified timelines as per applicable law. If You have any
              questions about viewing or updating information we have on file
              about You, please contact us at{" "}
              <span className="text-[#000] hover:underline">
                <Link to={"mailto:enquiries@ndhealth.ai.com"} target="_blank">
                  enquiries@ndhealth.ai.com
                </Link>
              </span>{" "}
              or reach out to our Grievance Officer / Nodal Officer, appointed
              in accordance with the Information Technology Act, 2000, Consumer
              Protection (E-Commerce) Rules, 2020, and the SPDI Rules, whose
              contact information is detailed below:
            </p>
            <p className="text-[#000]">
              Name: Jyothi N<br />
              E-mail:{" "}
              <span className="text-[#000] hover:underline">
                <Link to={"mailto:enquiries@ndhealth.ai.com"} target="_blank">
                  enquiries@ndhealth.ai.com
                </Link>
              </span>{" "}
              <br />
              Postal address: Sagar society, kamalapuri colony, banjara hills,
              Hyderabad.
            </p>{" "}
            <br />
            <br />
            <p>
              Please provide us with a detailed description of the
              complaint/grievance, and we will endeavour to resolve Your
              grievances within 15 (fifteen) days from the date of receipt of
              such grievance or within such timeframe stipulated under
              applicable laws.
            </p>
          </div>
        </div>

        {/******************* What choices do I have? *********************/}

        <div>
          <h3 className="md:text-xl text-base pb-4">What choices do I have?</h3>
          <div className="text-mediumGray">
            <p>
              You can always opt not to disclose information to us, but keep in
              mind some information may be needed to register with us or to take
              advantage of some of our special features / Medical Services. We
              will retain your personal information for a reasonable period or
              as long as the law requires. If You’d like Us to delete/modify
              Information that You have provided via the Mobile App or otherwise
              to Us, please contact Us at{" "}
              <span className="text-[#000] hover:underline">
                <Link to={"mailto:enquiries@ndhealth.ai.com"} target="_blank">
                  enquiries@ndhealth.ai.com
                </Link>
              </span>{" "}
              or contact our Grievance Officer, and we will respond in a
              reasonable time. Please note that some or all of the Information
              provided by You to Us may be required in order for the Mobile App
              / Medical Services to function properly.
            </p>

            <p className="py-4">
              You may be able to add, update, or delete information as explained
              in section above. When You update information, however, we may
              maintain a copy of the unrevised information in our records.
              Please note that some information may remain in our private
              records after Your deletion of such information from Your account.
              We may use (or transfer to our affiliates, group entities or
              authorized third parties) any aggregated data derived from or
              incorporating Your personal information after You update or delete
              it, but not in a manner that would identify You personally. If you
              do not wish to receive email or other mail from us, please email
              us at{" "}
              <span className="text-[#000] hover:underline">
                <Link to={"mailto:enquiries@ndhealth.ai.com"} target="_blank">
                  enquiries@ndhealth.ai.com
                </Link>
              </span>{" "}
              or contact our Grievance Officer. Please note that if you do not
              want to receive legal notices from us, such as this privacy
              policy, those legal notices will still govern your use of the
              Mobile App and the Medical Services, and You are responsible for
              reviewing such legal notices for changes.
            </p>

            <p>
              If you wish to opt-out of any services, offers, benefits, etc.
              offered by us or sellers on the Platform or their group entities
              or third party business alliance partners, you may do so in
              accordance with the terms specified to You by such affiliate/group
              entity. You are requested to carefully track notices sent to you,
              from time to time. To the extent that you do not expressly choose
              to opt-out of services, they may be provided to you on the basis
              that you have opted in to them.
            </p>
          </div>
        </div>

        {/**************** Changes to this privacy policy:  ************/}

        <div>
          <h3 className="md:text-xl text-base pb-4">
            Changes to this privacy policy:
          </h3>
          <p className="text-mediumGray">
            We may amend this privacy policy from time to time. Use of
            information We collect now is subject to the privacy policy in
            effect at the time such information is used. If we make changes in
            the way We use personal information, We will notify You by posting
            an announcement on our Mobile App or sending You an email. You are
            bound by any changes to the privacy policy when you use the Mobile
            App or the Product after such changes have been first posted.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyAndPolicy;
