import { useEffect, useRef } from "react";
import animationData from "../../Images/Animation - heart.json";
import lottie from "lottie-web";

const Loader = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    // Resize the animation when the component is resized
    const resizeHandler = () => {
      anim.resize();
    };
    window.addEventListener("resize", resizeHandler);

    // Cleanup
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <div className="flex items-center justify-center">
      <div ref={animationContainer} style={{ width: "20%" }}></div>
    </div>
  );
};

export default Loader;
