import { useState } from "react";
import LandingImg from "../../Images/LandingPage/1.jpg";

import { useNavigate } from "react-router-dom";

const StallHeroSection = () => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    username: "",
    tel: "",
    email: "",
    city: "",
    category: "",
    agreetoterms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validation logic
    if (!formData.username.trim()) {
      newErrors.username = "Name is required";
    }
    if (!formData.tel.trim()) {
      newErrors.tel = "Mobile number is required";
    } else if (formData.tel.startsWith("0")) {
      formData.tel.slice(1);
    } else if (!/^[6-9]\d{9}$/.test(formData.tel.trim())) {
      newErrors.tel = "Invalid mobile number";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      newErrors.email = "Invalid email";
    }
    if (!formData.city.trim()) {
      newErrors.city = "City is required";
    }
    if (!formData.category) {
      newErrors.category = "Category is required";
    }
    if (!formData.agreetoterms) {
      newErrors.agreetoterms = "Please accept terms and conditions";
    }

    // If there are errors, set errors and prevent form submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await fetch("https://ndhealth.ai/StallPageForm.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      console.log(response);

      if (response.ok) {
        console.log("Form submitted successfully");
        // Handle success, e.g., show a success message or redirect

        // Reset errors
        setErrors({});

        setFormData({
          username: "",
          tel: "",
          email: "",
          city: "",
          category: "",
          agreetoterms: false,
        });
        navigate("/thankyoustall");
      } else {
        console.error("Form submission failed");
        // Handle failure, e.g., show an error message
      }
    } catch (error) {
      console.error("Error during form submission", error);
      // Handle error, e.g., show an error message
    }

    // console.log(formData);

    // Validation successful, perform submission logic here
    console.log("Form submitted successfully");
  };

  return (
    <div className="relative bg-lightBlue flex flex-col md:flex-row md:justify-around gap-5 items-center mt-12 z-0">
      <img
        src={LandingImg}
        alt="LandingImg"
        className="relative md:rounded md:w-96 w-full"
      />
      <div className="relative items-center lg:w-[26rem] h-fit border border-lightGray lg:right-[10%] md:right-0 md:top-0 xl:top-[5%] bg-white rounded-lg p-5 shadow-lg">
        <h1 className="pb-5 text-center text-xl">
          Join One - One Consultation Today
        </h1>

        {/**********   FORM    *****************/}
        <form
          onSubmit={handleSubmit}
          className="flex flex-col md:w-96 w-full gap-7 relative"
        >
          {/**********   UserName    *****************/}
          <div className="">
            <input
              type="text"
              name="username"
              placeholder="Name*"
              onChange={handleChange}
              className="border-lightGray border-b-2 w-full py-2 px-4 focus:outline-none"
            />
            {errors.username && (
              <p className="text-error text-sm py-0">{errors.username}</p>
            )}
          </div>
          {/**********   Mobile no    *****************/}
          <div>
            <input
              className="border-lightGray border-b-2 w-full py-2 px-4 focus:outline-none"
              type="tel"
              id="tel"
              name="tel"
              placeholder="Enter your Mobile Number*"
              onChange={handleChange}
            />
            {errors.tel && (
              <p className="text-error text-sm py-0">{errors.tel}</p>
            )}
          </div>
          {/**********   E-mail    *****************/}
          <div>
            <input
              className="border-lightGray border-b-2 w-full py-2 px-4 focus:outline-none"
              type="email"
              id="email"
              name="email"
              placeholder="Enter your Email*"
              onChange={handleChange}
            />
            {errors.email && (
              <p className="text-error text-sm py-0">{errors.email}</p>
            )}
          </div>

          {/**********   City   *****************/}
          <div>
            <input
              className="border-lightGray border-b-2 w-full py-2 px-4 focus:outline-none"
              type="text"
              id="city"
              name="city"
              placeholder="Enter your city*"
              onChange={handleChange}
            />
            {errors.city && (
              <p className="text-error text-sm py-0">{errors.city}</p>
            )}
          </div>

          {/**********   Category    *****************/}
          <div>
            <select
              id="category"
              name="category"
              className="border-lightGray border-b-2 w-full py-2 px-4 focus:outline-none"
              onChange={handleChange}
            >
              <option value="">Select Category*</option>
              <option value="diabetes">Diabetes</option>
              <option value="dyslipedimia">Dyslipedimia</option>
              <option value="pcod">PCOD</option>
              <option value="obesity">Obesity</option>
              <option value="psoriasis">Psoriasis</option>
              <option value="agereversal">Metabolic Age Reversal</option>
              <option value="nafld">Non-alcoholic Fatty Liver Disease</option>
            </select>
            {errors.category && (
              <p className="text-error text-sm py-0">{errors.category}</p>
            )}
          </div>

          {/**********   Terms & Conditions    *****************/}

          <div className="xxs:px-5 ">
            <input
              className="xxs:text-sm"
              type="checkbox"
              id="agreetoterms"
              name="agreetoterms"
              checked={formData.agreetoterms}
              onChange={handleChange}
            />
            <label htmlFor="agreetoterms" className="pl-3 text-sm xs:text-base">
              I agree to be contacted by ND Health via Call, SMS, WhatsApp &
              Email
            </label>
            {errors.agreetoterms && (
              <p className="text-error text-sm py-0">{errors.agreetoterms}</p>
            )}
          </div>

          <button
            type="submit"
            className="border py-3 bg-mediumBlue text-white rounded-xl font-semibold bg-gradient-to-r from-sky-700 via-gray-400 to-red-400 hover:border-black duration-500 ease-out"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default StallHeroSection;
