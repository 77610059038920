import React, { useContext } from "react";
import Future from "./Future";
import Dysfunctional from "./Dysfunctional ";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { ContextProvider } from "../../App";

const Home = () => {
  const { shimmerEffect } = useContext(ContextProvider);

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <div>
      <Future />
      <Dysfunctional />
    </div>
  );
};

export default Home;
