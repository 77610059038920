import MetaInfo from "../Meta/Meta-Info";
import { EnquiryAndAssociatedSection } from "../utils/constant";
import NafldImg from "../../Images/NAFLD/NAFLD.jpg";
import NafsldImg from "../../Images/NAFLD/dl.beatsnoop.com-high-466974b4a7533c01e7.jpg";
// import { CauseOfNafld, lifeStyleOfNafld } from "../utils/NAFLD-Common";
import SymptomsOfNafld from "../../Images/NAFLD/9854.jpg";
import Banner from "../utils/Banner";
import { useTranslation } from "react-i18next";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { useContext, useEffect, useState } from "react";
import { ContextProvider } from "../../App";
import Aos from "aos";
import "aos/dist/aos.css";

const NAFLD = () => {
  const { loader } = useContext(ContextProvider);

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { t } = useTranslation();

  const {
    nafldMainTittle,
    nafldDesc,
    nafldSubhead,
    nafldSubPara,
    nafldCauses,
    nafldSypmtoms,
    nadldLifestyle,
    nafldEndTittle,
    nafldEndPara,
  } = t("Nafld");

  const { nafldCausesTittle, CauseOfNafld } = nafldCauses;
  const { nafldSypmtomsTitle, nafldSypmtomsPara, nafldSypmtomsSubPara } =
    nafldSypmtoms;
  const {
    nafldSypmtomsSubPara1,
    nafldSypmtomsSubPara2,
    nafldSypmtomsSubPara3,
    nafldSypmtomsSubPara4,
    nafldSypmtomsSubPara5,
    nafldSypmtomsSubPara6,
  } = nafldSypmtomsSubPara;
  const { nadldLifestyleTittle, lifeStyleOfNafld } = nadldLifestyle;

  return loader ? (
    <Shimmer />
  ) : (
    <section className="min-h-screen bg-mediumBlue text-white">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"NAFLD Program | Comprehensive Liver Health Solutions"}
        pageDescription={
          "Discover our NAFLD program for effective liver health management. Join now for personalized care and improved well-being. Start your journey today! "
        }
        pageKeywords={
          "NAFLD program,nafld management, artificial intelligence nafld, Artificial intelligence in liver disease"
        }
        ogUrl={"https://ndhealth.ai/programs/nafld-program"}
        ogTitle={"NAFLD Program | Comprehensive Liver Health Solutions"}
        ogDescription={
          "Discover our NAFLD program for effective liver health management. Join now for personalized care and improved well-being. Start your journey today!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/NAFLD.6b4f22be707653bc39b3.jp"
        }
        canonicalUrl={"https://ndhealth.ai/programs/nafld-program"}
        TwTitle={"NAFLD Program | Comprehensive Liver Health Solutions"}
        TwDesc={
          "Discover our NAFLD program for effective liver health management. Join now for personalized care and improved well-being. Start your journey today!"
        }
        TwImg={"https://ndhealth.ai/static/media/NAFLD.6b4f22be707653bc39b3.jp"}
      />
      <div className="flex flex-col gap-10">
        {/******    Hero Section    **********/}

        <Banner
          title={nafldMainTittle}
          description={nafldDesc}
          ImageUrl={NafldImg}
        />

        {/****************     What is NAFLD?      **********************/}

        <div
          className="flex justify-center gap-5 bg-center bg-no-repeat bg-cover w-full h-96 relative"
          style={{
            backgroundImage: `url(${NafsldImg})`,
            backgroundAttachment: "fixed",
          }}
        >
          <div className="absolute flex flex-col items-start bottom-0 sm:w-3/4 bg-darkBlue/50 sm:p-5 p-3 rounded">
            <h2 className="font-medium lg:text-4xl sm:text-2xl xs:text-lg text-xl mb-5">
              {nafldSubhead}
            </h2>
            <p className="sm:text-base text-sm">{nafldSubPara}</p>
          </div>
        </div>

        {/**************       Causes of NAFLD       *******************/}

        <div className="flex flex-col">
          <h2 className="font-medium sm:text-4xl xs:text-lg text-xl text-center mb-5">
            {nafldCausesTittle}
          </h2>
          <div className="flex flex-col justify-center items-center">
            {CauseOfNafld &&
              CauseOfNafld.map((nafld) => {
                const { id, title, description, imageUrl } = nafld;
                return (
                  <div
                    data-aos="fade-up"
                    key={id}
                    className="flex sm:flex-row flex-col items-center w-3/4 my-5 border rounded-xl"
                  >
                    <img
                      src={imageUrl}
                      alt={imageUrl}
                      className="sm:w-52 sm:h-40 object-cover rounded-l-xl"
                    />
                    <div className=" sm:text-start text-center p-3">
                      <h2 className="sm:text-xl text-base font-medium mb-5">
                        {title}
                      </h2>
                      <p className="text-sm sm:text-base text-lightGray">
                        {description}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/************       Symptoms of NAFLD:       ******************/}

        <div
          className="flex justify-center gap-5 bg-center bg-no-repeat bg-cover w-full h-[34rem] relative"
          style={{
            backgroundImage: `url(${SymptomsOfNafld})`,
            backgroundAttachment: "fixed",
          }}
        >
          <div className="flex sm:flex-row flex-col absolute bottom-0 sm:w-3/4 bg-darkBlue/50 p-3 sm:gap-5 gap-3 rounded">
            <div className="sm:w-1/2">
              <h2 className="font-medium lg:text-4xl xs:text-lg text-xl mb-5 text-center sm:text-left">
                {nafldSypmtomsTitle}
              </h2>
              <p className="lg:text-base text-sm">{nafldSypmtomsPara}</p>
            </div>
            <ul className="list-decimal sm:w-1/2 lg:text-base text-sm pl-4">
              <li>{nafldSypmtomsSubPara1}</li>
              <li>{nafldSypmtomsSubPara2}</li>
              <li>{nafldSypmtomsSubPara3}</li>
              <li>{nafldSypmtomsSubPara4}</li>
              <li>{nafldSypmtomsSubPara5}</li>
              <li>{nafldSypmtomsSubPara6}</li>
            </ul>
          </div>
        </div>

        {/*************    Lifestyle-Oriented Treatment For NAFLD    **************/}

        <div className="bg-white text-black">
          <h2 className="font-medium lg:text-4xl xs:text-lg text-xl my-5 text-center">
            {nadldLifestyleTittle}
          </h2>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 place-content-center place-items-center gap-5 ">
            {lifeStyleOfNafld &&
              lifeStyleOfNafld.map((style) => {
                const { id, title, description, imageUrl } = style;
                return (
                  <div
                    data-aos="fade-down"
                    key={id}
                    className="flex lg:flex-col items-center flex-col xs:flex-row lg:w-[19rem] rounded-xl transform duration-500 hover:scale-95"
                  >
                    <img
                      src={imageUrl}
                      alt={imageUrl}
                      className="lg:w-full xs:w-1/2 w-full h-56 object-cover rounded-xl"
                    />
                    <div className="p-3 xs:h-40">
                      <h2 className="font-semibold lg:text-xl">{title}</h2>
                      <p className="lg:text-base text-sm text-mediumGray">
                        {description}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/*********      Take Charge of Your Liver Health     **************/}

        <div className="flex flex-col items-center justify-center gap-5">
          <h2 className="font-medium lg:text-4xl xs:text-lg text-xl ">
            {nafldEndTittle}
          </h2>
          <p className="w-3/4">{nafldEndPara}</p>
          <EnquiryAndAssociatedSection />
        </div>
      </div>
    </section>
  );
};

export default NAFLD;
