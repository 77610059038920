import HeroSection from "./HeroSection";
import NdHealth from "./NdHealth";
import Services from "./Services";
import AboutUs from "./AboutUs";
import LandHeader from "./LandHeader";
import MetaInfo from "../Meta/Meta-Info";
import { useContext } from "react";
import { ContextProvider } from "../../App";
import Shimmer from "../utils/Shimmer Comp/Shimmer";

const LandingPage = () => {
  const { shimmerEffect } = useContext(ContextProvider);

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section>
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"AI-Driven Healthcare Solutions for Modern Health Needs "}
        pageDescription={
          "Discover innovative AI-driven healthcare solutions to enhance patient care and operational efficiency. Get started today for better health outcomes."
        }
        pageKeywords={
          "AI-driven healthcare solutions, AI technologies for healthcare, Advanced healthcare analytics,  Data-driven healthcare innovation"
        }
        canonicalUrl={"https://ndhealth.ai/landingpage"}
      />
      <div className="flex flex-col gap-5 z-10 bg-gray-100">
        <LandHeader />
        <HeroSection />
        <NdHealth />
        <Services />
        <AboutUs />
      </div>
    </section>
  );
};

export default LandingPage;
